import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';

import {
  Container,
  StyledModal,
  Photo,
  PhotoHeader,
  PhotoBody,
  Content,
  ModalHeader,
  Info,
} from './styles';

interface ExpandedPhotoProps {
  title: string;
  photo_url: string;
  from: Date;
}

const ExpandedPhotoModal: React.FC<ExpandedPhotoProps> = ({
  title,
  photo_url,
  from,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Container>
      <Photo onClick={handleToggle}>
        <PhotoHeader>
          <span>{title}</span>
        </PhotoHeader>
        <PhotoBody>
          <img src={photo_url} alt={title} />
        </PhotoBody>
      </Photo>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <ModalHeader>
            <Info>
              <span>{title}</span>
              <span>{format(new Date(from), 'dd/MM/yyyy')}</span>
            </Info>
            <FiX size={30} onClick={handleToggle} />
          </ModalHeader>
          <img src={photo_url} alt="front" />
        </Content>
      </StyledModal>
    </Container>
  );
};

export default ExpandedPhotoModal;
