import styled from 'styled-components';
import Modal from 'styled-react-modal';
import Button from '../../../../../components/Button';

export const Container = styled.div``;

export const StyledModal = Modal.styled`
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 8px;
  overflow: hidden;
`;

export const UpdateCalendar = styled(Button)`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  font-size: 14px;

  svg {
    margin-left: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 10px;

  img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
  }
  h1 {
    font-size: 18px;
  }

  strong {
    font-weight: bold;
    font-size: 18px;
    color: var(--error);
  }
  span {
    font-size: 12px;
  }
`;

export const Remind = styled.div`
  padding: 10px 16px;
  h3 {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 12px;
  }
  width: 100%;
  margin-bottom: 10px;
`;
export const RemindOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 12px;
    margin-top: 10px;
  }
  strong {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary);
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  margin-top: 10px;

  overflow: hidden;
`;
export const SaveButton = styled(Button)`
  background: var(--primary);
  margin-top: 0;
  height: 40px;
  width: 100%;

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(61deg, var(--primary), var(--white) 249%);
  }
`;

export const Cancel = styled(Button)`
  margin-top: 0;
  height: 40px;
  width: 100%;
  margin-right: 12px;
  background: var(--light-background);
  color: var(--primary);

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(
      61deg,
      var(--light-background),
      var(--white) 249%
    );
  }
`;
