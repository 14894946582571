import React from 'react';

import { FiArrowDownCircle, FiArrowRightCircle } from 'react-icons/fi';
import { Container, Content, List, RecipeCard } from './styles';
import { Section } from '../../../../entities/SharedResources';

interface MainRowProps {
  section: Section;
}
const MainRow: React.FC<MainRowProps> = ({ section }) => {
  return (
    <Container>
      <header>{section.title}</header>
      <Content>
        <List>
          {section.resources.map((item) => (
            <a
              key={item.id}
              href={item.document_url ? item.document_url : item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RecipeCard>
                <img src={item.thumbnail_url} alt="" />
                <footer>
                  <strong>{item.title}</strong>
                  {item.document_url ? (
                    <FiArrowDownCircle />
                  ) : (
                    <FiArrowRightCircle />
                  )}
                </footer>
              </RecipeCard>
            </a>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default MainRow;
