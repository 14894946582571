import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;

interface ClientDetailsProps {
  $isActive: boolean;
}
export const ClientDetails = styled.div<ClientDetailsProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  .nutrition_info {
    display: flex;
    flex-direction: column;

    strong {
      font-size: 14px;
      margin-bottom: 10px;
      span {
        color: var(--primary);
        font-weight: bold;
      }
    }

    ${(props) =>
      !props.$isActive &&
      css`
        .status {
          color: var(--error);
        }
      `}
  }
`;

export const ActionButtons = styled.div`
  display: flex;

  &:first-child {
    margin-right: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  justify-content: center;
`;
