import styled from 'styled-components';
import Button from '../../../../components/Button';
import FileInput from '../../../../components/FileInput';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;

  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .input-files {
      display: flex;
      align-items: center;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  strong {
    color: var(--primary);
    font-weight: bold;
  }
`;

export const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  margin-bottom: 12px;
`;

export const Card = styled.div`
  background: var(--light-background);
  padding: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  strong {
    color: var(--gray);
    margin-right: 10px;
  }
  svg {
    color: var(--gray);
    cursor: pointer;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 0;
  height: 44px;
  width: 50px;
  margin-left: 10px;
  svg {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;

export const AttachmentInput = styled(FileInput)`
  width: 60%;
`;
