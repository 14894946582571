import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface IconProps {
  $isSelected: boolean;
}
export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: var(--white);
  width: 100%;
  border-top: 1px solid var(--gray);
  display: flex;
  justify-content: space-between;

  align-items: center;

  margin-top: 20px;

  @media (min-width: 850px) {
    display: none;
  }
`;

export const CustomLink = styled(Link)<IconProps>`
  ${(props) =>
    props.$isSelected &&
    css`
      background-image: linear-gradient(
        71deg,
        var(--primary),
        var(--white) 249%
      );
      color: var(--white);
    `}

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--gray);
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
`;

const iconCSS = css`
  img {
    height: 41px;
    padding: 8px;
  }
`;

export const Goals = styled.div`
  ${iconCSS};
`;
export const Recipe = styled.div`
  ${iconCSS}
`;
export const Checkin = styled.div`
  ${iconCSS}
`;
export const Chat = styled.div`
  ${iconCSS}
`;
export const SharedResources = styled.div`
  ${iconCSS}
`;
