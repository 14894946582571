import { Resource } from '../entities/SharedResources';

interface Dictionary {
  [index: string]: Resource[];
}
export default function getItemsForListFromDictionary(
  keyName: string,
  data: Dictionary,
): Resource[] {
  const result = Object.entries(data)
    .flatMap(([key, value]) => {
      if (key === keyName) {
        return value;
      }
      return null;
    })
    .filter((item) => {
      return item !== null && item !== undefined;
    });

  if (!result) {
    return [];
  }
  return result as Resource[];
}
