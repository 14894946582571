import styled from 'styled-components';

export const Container = styled.button`
  background: var(--primary);
  border-radius: 5px;
  border: 0;
  height: 48px;
  width: 100%;
  padding: 0 16px;
  color: var(--white);
  font-weight: bold;
  font-size: 20px;
  margin-top: 24px;
  transition: background-color 0.2s;

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(61deg, var(--primary), var(--white) 249%);
  }
`;
