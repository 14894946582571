import React, { useState, useCallback, useRef, ChangeEvent } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiFilePlus, FiX } from 'react-icons/fi';
import Attachment from '../../../../entities/Attachment';

import {
  Container,
  Content,
  Header,
  Files,
  SubmitButton,
  AttachmentInput,
  Card,
} from './styles';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getTypeFromExtension from '../../../../utils/getTypeFromExtension';
import getValidationErrors from '../../../../utils/getValidationErrors';
import AppError from '../../../../entities/AppError';

interface AttachmentsComponentProps {
  attachments: Attachment[];
  onAdd: (attachment: Attachment) => void;
  onRemove: (name: string) => void;
}

interface DocumentUploadProps {
  name: string;
  folder: string;
  type: string;
}

const AttachmentsComponent: React.FC<AttachmentsComponentProps> = ({
  attachments,
  onAdd,
  onRemove,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [documentData, setDocumentData] = useState<FormData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [documentTitle, setDocumentTitle] = useState('Attachment');
  const { addToast } = useToast();

  const handleDocumentUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();
        const file = e.target.files[0];
        data.append('file', file);
        setDocumentData(data);
        setDocumentTitle(`Attachment: ${file.name}`);
      }
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      formRef.current?.setErrors({});

      const attachment: Attachment = {
        name: '',
        folder: '',
        placeholder: '',
        type: '',
      };

      // UPLOAD CONTENT
      let documentName = '';
      if (documentData) {
        const response = await api.post<Attachment>(
          '/documents/attachments',
          documentData,
        );
        documentName = response.data.name;
        const ext = documentName.substr(documentName.lastIndexOf('.') + 1);

        Object.assign(attachment, {
          name: documentName,
          type: getTypeFromExtension(ext),
          folder: 'attachments',
        });
        addToast({
          type: 'success',
          title: 'Success',
          description: `Attachment uploaded `,
        });
      }

      if (attachment.name === '') {
        throw new AppError('Attachment cannot be empty');
      }
      onAdd(attachment);
      setIsLoading(false);
      setDocumentData(null);
      setDocumentTitle('Attachment');
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        setIsLoading(false);
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      if (err instanceof AppError) {
        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Creation Failed',
        description: err.response
          ? err.response.data.message
          : 'Could not connect to server, please try again later',
      });
    }
  }, [addToast, documentData, onAdd]);

  const handleDelete = useCallback(
    (name: string) => {
      onRemove(name);
    },
    [onRemove],
  );

  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Header>
            <strong>Add Attachment</strong>
          </Header>

          <div className="input-files">
            <AttachmentInput
              id="document"
              name="document"
              placeholder={documentTitle}
              onChange={(e) => handleDocumentUpload(e)}
            />
            <SubmitButton type="submit" disabled={isLoading}>
              <FiFilePlus />
            </SubmitButton>
          </div>
          <Files>
            {attachments.map((att) => (
              <Card key={att.name}>
                <strong>{att.name}</strong>
                <FiX onClick={() => handleDelete(att.name)} />
              </Card>
            ))}
          </Files>
        </Form>
      </Content>
    </Container>
  );
};

export default AttachmentsComponent;
