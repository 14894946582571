import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  min-height: 300px;
  max-height: 350px;

  @media (min-height: 710px) {
    min-height: 400px;
    max-height: 550px;
  }

  justify-content: space-between;
`;

export const MessagesBody = styled.div`
  flex: 1;
  overflow-y: auto;
  /* height: 330px; */
  padding: 20px 30px;
`;
