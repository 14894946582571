import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { FiMail } from 'react-icons/fi';
import api from '../../../services/api';
import {
  Container,
  Content,
  Profile,
  Groups,
  Settings,
  MetricsArea,
  Goals,
  KeyPrinciples,
  Recipes,
  MealPlans,
  NutritionArea,
} from './styles';
import NavigationHeader from '../../../components/NavigationHeader';
import Client from '../../../entities/Client';
import ProfileCard from './ProfileCard';
import GroupsSection from './GroupsSection';
import Group from '../../../entities/Groups';
import GoalsSection from '../../../components/GoalsSection';
import Goal from '../../../entities/Goal';
import Metrics from '../../../entities/Metrics';

import { useAuth } from '../../../hooks/Auth';
import SettingsSection from './SettingsSection';
import MetricsSection from './MetricsSection';
import { useToast } from '../../../hooks/Toast';
import KeyPrincipleSection from '../../../components/KeyPrincipleSection';
import KeyPrinciple from '../../../entities/KeyPrinciple';
import NutritionSection from './NutritionSection';
import RecipesSection from './RecipesSection';
import MealPlanSection from './MealPlanSection';
import Recipe from '../../../entities/Recipe';
import MealPlan from '../../../entities/MealPlan';
import Nutrition from '../../../entities/Nutrition';
import Photo from '../../../entities/Photo';
import PhotoSection from '../../../components/PhotoSection';

interface ClientProfileResponse {
  client: Client;
  goals: Goal[];
  photo: Photo;
  principles: KeyPrinciple[];
  recipes: Recipe[];
  meal_plans: MealPlan[];
  nutrition: Nutrition;
  future_nutrition: Nutrition;
}
interface ClientProfileParams {
  id: string;
}
const ClientProfile: React.FC = () => {
  const { id } = useParams<ClientProfileParams>();

  const { user } = useAuth();
  const { addToast } = useToast();
  const isAdmin = useMemo(() => user.role !== '', [user.role]);
  const history = useHistory();
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [groups, setGroups] = useState<Group[]>([] as Group[]);
  const [goals, setGoals] = useState<Goal[]>([] as Goal[]);
  const [photo, setPhoto] = useState<Photo | undefined>(undefined);
  const [mealPlans, setMealPlans] = useState<MealPlan[]>([] as MealPlan[]);
  const [recipes, setRecipes] = useState<Recipe[]>([] as Recipe[]);
  const [keyPrinciples, setKeyPrinciples] = useState<KeyPrinciple[]>(
    [] as KeyPrinciple[],
  );
  const [metrics, setMetrics] = useState<Metrics>({ options: [] });
  const [nutrition, setNutrition] = useState<Nutrition | undefined>();
  const [futureNutrition, setFutureNutrition] = useState<
    Nutrition | undefined
  >();

  const fetchClientInfo = useCallback(async () => {
    try {
      const response = await api.get<ClientProfileResponse>(
        `admin-clients/${id}`,
      );
      const tempClient: Client = response.data.client;
      tempClient.end_of_contract = new Date(tempClient.next_reminder);
      setClient(response.data.client);
      setGroups(response.data.client.groups);
      setGoals(response.data.goals);
      setPhoto(response.data.photo);
      setMealPlans(response.data.meal_plans);
      setRecipes(response.data.recipes);
      setKeyPrinciples(response.data.principles);
      setNutrition(response.data.nutrition);
      setFutureNutrition(response.data.future_nutrition);

      const options = [];
      if (tempClient) {
        if (tempClient.is_weight_enabled) {
          options.push('weight');
        }
        if (tempClient.is_waist_enabled) {
          options.push('waist');
        }
        if (tempClient.is_chest_enabled) {
          options.push('chest');
        }
        if (tempClient.is_hips_enabled) {
          options.push('hips');
        }
        if (tempClient.is_thigh_enabled) {
          options.push('thigh');
        }
        if (tempClient.is_biceps_enabled) {
          options.push('biceps');
        }
      }
      const clientMetrics: Metrics = {
        options,
        wildcard1: tempClient.metric_op1_title ?? '',
        wildcard2: tempClient.metric_op2_title ?? '',
      };

      setMetrics(clientMetrics);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not load profile',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    }
  }, [id, addToast]);

  useEffect(() => {
    fetchClientInfo();
  }, [fetchClientInfo]);

  const handleRefreshGroups = useCallback(
    (addedGroups?: Group[]) => {
      if (addedGroups) {
        setGroups(addedGroups);
      }
      if (client) {
        api.get<Group[]>(`groups-clients/${client.id}`).then((response) => {
          setGroups(response.data);
        });
      }
    },
    [client],
  );

  const handleRefreshGoals = useCallback(
    (addedGoal?: Goal) => {
      if (addedGoal) {
        setGoals([...goals, addedGoal]);
      }
      if (client) {
        api.get<Goal[]>(`goals/clients/${client.id}`).then((response) => {
          setGoals(response.data);
        });
      }
    },
    [client, goals],
  );

  const handleRefreshRecipes = useCallback(
    (addedRecipe?: Recipe) => {
      if (addedRecipe) {
        setRecipes([...recipes, addedRecipe]);
      }
      if (client) {
        api.get(`admin-client-recipes/${client.id}`).then((response) => {
          setRecipes(response.data.recipes);
        });
      }
    },
    [client, recipes],
  );

  const handleRefreshMealPlan = useCallback(
    (addedMealPlan?: MealPlan) => {
      if (addedMealPlan) {
        setMealPlans([...mealPlans, addedMealPlan]);
      }
      if (client) {
        api.get(`admin-client-meal-planners/${client.id}`).then((response) => {
          setMealPlans(response.data.meal_planners);
        });
      }
    },
    [client, mealPlans],
  );

  const handleRefreshPrinciples = useCallback(
    (key_principles?: KeyPrinciple) => {
      if (key_principles) {
        setKeyPrinciples([...keyPrinciples, key_principles]);
      }
      if (client) {
        api
          .get<KeyPrinciple[]>(`/key-principles/clients/${client.id}`)
          .then((response) => {
            setKeyPrinciples(response.data);
          });
      }
    },
    [keyPrinciples, client],
  );

  const handleRefreshClientInfo = useCallback((info: Client) => {
    const tempClient: Client = info;
    tempClient.end_of_contract = new Date(tempClient.next_reminder);
    setClient(tempClient);
  }, []);

  const handleDeleteNutrition = useCallback(
    async (nutrition_id: string, isFuture: boolean) => {
      try {
        await api.delete(`nutritions/${nutrition_id}`);
        if (isFuture) {
          setFutureNutrition(undefined);
        } else {
          setNutrition(undefined);
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Could not load profile',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast],
  );

  const handleEditNutrition = useCallback(
    (new_nutrition: Nutrition, isFuture) => {
      if (isFuture) {
        setFutureNutrition(new_nutrition);
      } else {
        setNutrition(new_nutrition);
      }
    },
    [],
  );

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button type="button" onClick={() => history.push('/admin-clients')}>
            Clients /
          </button>
          <span>Profile</span>
        </strong>
        <Link
          className="button"
          to={{
            pathname: `../mail/${id}`,
            state: {
              mail_type: 'client',
              client,
            },
          }}
        >
          Send email
          <FiMail size={20} />
        </Link>
      </NavigationHeader>
      {client && (
        <Content>
          <Profile>
            <ProfileCard client={client} />
            <Groups>
              <GroupsSection
                groups={groups}
                client={client}
                handleRefreshGroups={handleRefreshGroups}
              />
            </Groups>
          </Profile>
          <Settings>
            <SettingsSection
              client={client}
              refreshClientWithInfo={handleRefreshClientInfo}
            />
          </Settings>
          <MetricsArea>
            <MetricsSection
              clientId={client.id}
              metrics={metrics}
              handleUpdate={fetchClientInfo}
            />
          </MetricsArea>
          {photo && (
            <PhotoSection
              title="Current Photos"
              show_past_photos
              show_delete_photo={false}
              id={photo.id}
              client_id={client.id}
              photo={photo}
            />
          )}

          <Goals>
            <GoalsSection
              client={client}
              goals={goals}
              isAdmin={isAdmin}
              handleRefreshGoals={handleRefreshGoals}
            />
          </Goals>
          <KeyPrinciples>
            <KeyPrincipleSection
              key_principles={keyPrinciples}
              handleRefreshKeyPrinciples={handleRefreshPrinciples}
              isAdmin={isAdmin}
              client={client}
            />
          </KeyPrinciples>
          <Recipes>
            <RecipesSection
              client_id={client.id}
              recipes={recipes}
              handleRefreshRecipes={handleRefreshRecipes}
            />
          </Recipes>
          <MealPlans>
            <MealPlanSection
              client_id={client.id}
              meal_plans={mealPlans}
              handleRefreshMealPlans={handleRefreshMealPlan}
            />
          </MealPlans>

          <NutritionArea>
            <NutritionSection
              client_id={client.id}
              nutrition={nutrition}
              future_nutrition={futureNutrition}
              client_name={client.name}
              handleDeleteNutrition={handleDeleteNutrition}
              handleEditNutrition={handleEditNutrition}
            />
          </NutritionArea>
        </Content>
      )}
    </Container>
  );
};

export default ClientProfile;
