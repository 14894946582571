import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import KeyPrincipleSection from '../../../components/KeyPrincipleSection';
import Loading from '../../../components/Loading';
import NavigationHeader from '../../../components/NavigationHeader';
import KeyPrinciple from '../../../entities/KeyPrinciple';
import Nutrition from '../../../entities/Nutrition';
import Recipe from '../../../entities/Recipe';
import { useAuth } from '../../../hooks/Auth';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import DetailedNutritionComponent from './DetailedNutritionComponent';
import MealPlannerComponent from './MealPlannerComponent';
import RecipeList from './RecipeList';

import {
  Container,
  Content,
  NutritionInfo,
  Details,
  Header,
  Status,
  Info,
  Top,
  Data,
  InfoList,
} from './styles';

interface NutritionResponse {
  nutrition?: Nutrition;
  key_principles?: KeyPrinciple[];
  recipes?: Recipe[];
}

interface NutritionSectionParams {
  id: string;
}

const NutritionSection: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { client } = useAuth();
  const { id } = useParams<NutritionSectionParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [nutrition, setNutrition] = useState<Nutrition | undefined>(undefined);
  const [keyPrinciples, setKeyPrinciples] = useState<
    KeyPrinciple[] | undefined
  >([]);
  const [recipes, setRecipes] = useState<Recipe[] | undefined>(undefined);

  const fetchCurrentNutritionPlan = useCallback(async () => {
    try {
      const response = await api.get<NutritionResponse>(
        `nutritions-client/${client.id}/current`,
      );
      setNutrition(response.data.nutrition);
      setKeyPrinciples(response.data.key_principles);
      setRecipes(response.data.recipes);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not fetch Nutrition Plan',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast, client.id]);

  const fetchPastNutritionPlan = useCallback(
    async (nutrition_id) => {
      try {
        const response = await api.get<NutritionResponse>(
          `nutritions/${nutrition_id}`,
        );
        setNutrition(response.data.nutrition);
        setKeyPrinciples(response.data.key_principles);
        setRecipes(response.data.recipes);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Could not fetch Nutrition Plan',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (!id) {
      fetchCurrentNutritionPlan();
    } else {
      fetchPastNutritionPlan(id);
    }
  }, [fetchCurrentNutritionPlan, fetchPastNutritionPlan, id]);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Nutrition & Recipes</span>
        </strong>
      </NavigationHeader>
      <Content>
        <NutritionInfo>
          <Details>
            <Header>
              <h3>Details</h3>
            </Header>

            {nutrition && (
              <Info>
                <Top>
                  <strong>
                    Title: <span>{nutrition.title}</span>
                  </strong>
                  <Status $isActive={nutrition.active}>
                    <strong>
                      {nutrition.active ? <>Available:</> : <>Not Active:</>}
                      <span className="status-label">
                        {format(new Date(nutrition.start_date), 'dd/MM/yyyy')}
                      </span>
                    </strong>
                  </Status>
                </Top>
                <Link to="/past-nutritions">
                  <span>View Past recommendation</span>
                </Link>
              </Info>
            )}
          </Details>
          {nutrition?.detailed_nutrition && (
            <DetailedNutritionComponent
              detailed_nutrition={nutrition?.detailed_nutrition}
            />
          )}
        </NutritionInfo>
        {isLoading || !nutrition ? (
          <Loading />
        ) : (
          <Data>
            <InfoList>
              <MealPlannerComponent dailyInfos={nutrition.daily_plans} />
              {keyPrinciples && (
                <KeyPrincipleSection
                  key_principles={keyPrinciples}
                  client={client}
                  isAdmin={false}
                />
              )}
            </InfoList>
            {recipes && <RecipeList recipes={recipes} />}
          </Data>
        )}
      </Content>
    </Container>
  );
};

export default NutritionSection;
