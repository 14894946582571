import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import NavigationHeader from '../../../components/NavigationHeader';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Container, Content, Header, SubmitButton } from './styles';
import Attachments from './Attachments';
import Attachment from '../../../entities/Attachment';
import Input from '../../../components/Input';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/Toast';
import Client from '../../../entities/Client';
import Group from '../../../entities/Groups';

interface MailRequestProps {
  group_id?: string;
  client_id?: string;
  subject: string;
  message: string;
  attachments?: string[];
}

interface CreateEmailFormData {
  subject: string;
}

interface MailParams {
  id: string;
}

interface MailStateProps {
  mail_type: string;
  client?: Client;
  group?: Group;
}

const Mail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<MailParams>();
  const { state } = useLocation<MailStateProps>();
  const formRef = useRef<FormHandles>(null);
  const [editor, setEditor] = useState<EditorState>(EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();
  const [attachments, setAttachments] = useState<Attachment[]>(() => {
    const localAtt = localStorage.getItem('@FFP:attachments');
    if (localAtt) {
      return JSON.parse(localAtt);
    }
    return [] as Attachment[];
  });

  const email_to = useMemo(() => {
    if (state.client !== undefined) {
      return state.client.name;
    }
    if (state.group !== undefined) {
      return state.group.name;
    }
    return '';
  }, [state]);

  const handleSend = useCallback(
    async (data: CreateEmailFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          subject: Yup.string().required('Subject is Required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const body: MailRequestProps = {
          subject: data.subject,
          message: draftToHtml(convertToRaw(editor.getCurrentContent())),
        };

        if (attachments.length > 0) {
          Object.assign(body, {
            attachments: attachments.map((att) => att.name),
          });
        }

        if (state.mail_type === 'client') {
          Object.assign(body, {
            client_id: id,
          });
          await api.post('/mails/client', body);
        } else if (state.mail_type === 'group') {
          Object.assign(body, {
            group_id: id,
          });
          await api.post('/mails/group', body);
        }

        addToast({
          type: 'success',
          title: 'Success',
          description: `Email sent`,
        });

        setIsLoading(false);
        localStorage.removeItem('@FFP:attachments');
      } catch (error) {
        setIsLoading(false);
        if (error instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [editor, addToast, attachments, state.mail_type, id],
  );

  const handleOnChange = useCallback((data: EditorState) => {
    setEditor(data);
  }, []);

  const handleAddAttachment = useCallback(
    (newAttachment: Attachment) => {
      const newAttList = [...attachments, newAttachment];
      setAttachments(newAttList);
      localStorage.setItem('@FFP:attachments', JSON.stringify(newAttList));
    },
    [attachments],
  );

  const handleRemoveAttachment = useCallback(
    (name: string) => {
      const newList = attachments.filter((att) => att.name !== name);
      setAttachments(newList);
      localStorage.setItem('@FFP:attachments', JSON.stringify(newList));
    },
    [attachments],
  );

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button type="button" onClick={() => history.push('/admin-clients')}>
            Clients /
          </button>
          <span>Mail Client</span>
        </strong>

        <SubmitButton form="email-form" type="submit" disabled={isLoading}>
          Send
        </SubmitButton>
      </NavigationHeader>
      <Content>
        <Header>
          <span>
            Sending email to: <strong>{email_to}</strong>
          </span>
        </Header>
        <Form id="email-form" ref={formRef} onSubmit={handleSend}>
          <Input title="Subject" name="subject" placeholder="Enter text" />
        </Form>
        <Attachments
          attachments={attachments}
          onAdd={handleAddAttachment}
          onRemove={handleRemoveAttachment}
        />
        <Editor
          editorState={editor}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={handleOnChange}
          placeholder="Enter Message"
        />
      </Content>
    </Container>
  );
};

export default Mail;
