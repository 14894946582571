import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;

export const Content = styled.div``;

interface StatusProps {
  $isActive: boolean;
}

export const NutritionInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: flex-start;

  @media (min-width: 700px) {
    flex-direction: row;
  }
  margin-bottom: 10px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background: var(--list-background);
  width: 100%;
  align-items: flex-start;

  @media (min-width: 700px) {
    margin-right: 10px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  @media (min-width: 700px) {
    min-height: 156px;
    height: 100%;
  }

  a {
    width: 100%;
    display: flex;
    align-self: flex-end;
    justify-content: center;
    padding: 10px 0;
    span {
      font-size: 12px;
    }
  }
`;
export const Top = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  strong {
    padding: 0 10px;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 5px;

    span {
      font-weight: bold;
      font-size: 14px;
      color: var(--primary);
    }
  }
`;

export const Status = styled.div<StatusProps>`
  strong {
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 5px;

    span {
      font-weight: bold;
      font-size: 14px;
      color: var(--primary);
      margin-left: 5px;
    }
  }
  ${(props) =>
    !props.$isActive &&
    css`
      color: var(--error);
      .status-label {
        color: var(--error);
      }
    `};
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: var(--gray);
  background: var(--light-background);
  width: 100%;
  h3 {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0;
  }
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: flex-start;

  @media (min-width: 1025px) {
    flex-direction: row;
  }
  margin-bottom: 10px;
`;

export const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media (min-width: 1025px) {
    margin-right: 10px;
  }
`;
