import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import NavigationHeader from '../../../components/NavigationHeader';
import Loading from '../../../components/Loading';
import Metric from '../../../entities/Metric';
import api from '../../../services/api';

import {
  Container,
  Content,
  Row,
  RowHeader,
  RowBody,
  Weights,
  Waists,
  Chests,
  Hips,
  Thighs,
  Biceps,
  Extras1,
  Extras2,
} from './styles';
import { useToast } from '../../../hooks/Toast';
import MetricCard from './MetricCard';
import CreateModal from '../../../components/Metrics/CreateModal';
import { useAuth } from '../../../hooks/Auth';

interface MetricsModel {
  weights?: Metric[];
  waists?: Metric[];
  chests?: Metric[];
  hips?: Metric[];
  thighs?: Metric[];
  biceps?: Metric[];
  metrics_op1?: Metric[];
  metrics_op1_title?: string;
  metrics_op2?: Metric[];
  metrics_op2_title?: string;
}

const MetricsList: React.FC = () => {
  const { client } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const [metrics, setMetrics] = useState<MetricsModel | undefined>(undefined);
  const [showDetails, setShowDetails] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMetrics = useCallback(async () => {
    try {
      const response = await api.get<MetricsModel>(`metrics/${client.id}`);
      setMetrics(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not load metrics',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [client.id, addToast]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const handleOnDelete = useCallback(
    async (metric: string, metric_id: string) => {
      try {
        await api.delete(`metrics/${metric}/${metric_id}`);
        fetchMetrics();
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Could not delete this metric',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [fetchMetrics, addToast],
  );

  const handleOnCreate = useCallback(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const handleToggleDescription = useCallback(
    (metric: string) => {
      if (showDetails.indexOf(metric) !== -1) {
        setShowDetails(showDetails.filter((goal) => goal !== metric));
      } else {
        setShowDetails([...showDetails, metric]);
      }
    },
    [showDetails],
  );

  function shouldDisplayDetails(for_metric: string): boolean {
    return showDetails.find((metric) => metric === for_metric) !== undefined;
  }

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button type="button" onClick={() => history.push('/client-goals')}>
            Goals & Metrics /
          </button>
          <span>Metrics List</span>
        </strong>
      </NavigationHeader>
      {isLoading || !metrics ? (
        <Content>
          <Loading />
        </Content>
      ) : (
        <Content>
          {metrics.weights && (
            <Row onClick={() => handleToggleDescription('weights')}>
              <RowHeader $shouldShowDetails={shouldDisplayDetails('weights')}>
                <div className="row-header-info">
                  <span>Weights</span>
                </div>
                <div className="actions">
                  <CreateModal
                    client_id={client.id}
                    onCreate={handleOnCreate}
                    metric="weights"
                  />
                  <IoIosArrowForward size={26} />
                </div>
              </RowHeader>
              <RowBody $shouldShowDetails={shouldDisplayDetails('weights')}>
                <div className="column">
                  <Weights>
                    {metrics.weights.map((weight) => (
                      <MetricCard
                        key={weight.id}
                        metric={weight}
                        handleOnDelete={
                          () => handleOnDelete('weights', weight.id)
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    ))}
                  </Weights>
                </div>
              </RowBody>
            </Row>
          )}
          {metrics.waists && (
            <Row onClick={() => handleToggleDescription('waists')}>
              <RowHeader $shouldShowDetails={shouldDisplayDetails('waists')}>
                <div className="row-header-info">
                  <span>Waist</span>
                </div>
                <div className="actions">
                  <CreateModal
                    client_id={client.id}
                    onCreate={handleOnCreate}
                    metric="waists"
                  />
                  <IoIosArrowForward size={26} />
                </div>
              </RowHeader>
              <RowBody $shouldShowDetails={shouldDisplayDetails('waists')}>
                <div className="column">
                  <Waists>
                    {metrics.waists.map((waist) => (
                      <MetricCard
                        key={waist.id}
                        metric={waist}
                        handleOnDelete={
                          () => handleOnDelete('waists', waist.id)
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    ))}
                  </Waists>
                </div>
              </RowBody>
            </Row>
          )}
          {metrics.chests && (
            <Row onClick={() => handleToggleDescription('chests')}>
              <RowHeader $shouldShowDetails={shouldDisplayDetails('chests')}>
                <div className="row-header-info">
                  <span>Chest</span>
                </div>
                <div className="actions">
                  <CreateModal
                    client_id={client.id}
                    onCreate={handleOnCreate}
                    metric="chests"
                  />
                  <IoIosArrowForward size={26} />
                </div>
              </RowHeader>
              <RowBody $shouldShowDetails={shouldDisplayDetails('chests')}>
                <div className="column">
                  <Chests>
                    {metrics.chests.map((chest) => (
                      <MetricCard
                        key={chest.id}
                        metric={chest}
                        handleOnDelete={
                          () => handleOnDelete('chests', chest.id)
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    ))}
                  </Chests>
                </div>
              </RowBody>
            </Row>
          )}
          {metrics.hips && (
            <Row onClick={() => handleToggleDescription('hips')}>
              <RowHeader $shouldShowDetails={shouldDisplayDetails('hips')}>
                <div className="row-header-info">
                  <span>Hips</span>
                </div>
                <div className="actions">
                  <CreateModal
                    client_id={client.id}
                    onCreate={handleOnCreate}
                    metric="hips"
                  />
                  <IoIosArrowForward size={26} />
                </div>
              </RowHeader>
              <RowBody $shouldShowDetails={shouldDisplayDetails('hips')}>
                <div className="column">
                  <Hips>
                    {metrics.hips.map((hip) => (
                      <MetricCard
                        key={hip.id}
                        metric={hip}
                        handleOnDelete={() => handleOnDelete('hips', hip.id)}
                      />
                    ))}
                  </Hips>
                </div>
              </RowBody>
            </Row>
          )}
          {metrics.thighs && (
            <Row onClick={() => handleToggleDescription('thighs')}>
              <RowHeader $shouldShowDetails={shouldDisplayDetails('thighs')}>
                <div className="row-header-info">
                  <span>Thigh</span>
                </div>
                <div className="actions">
                  <CreateModal
                    client_id={client.id}
                    onCreate={handleOnCreate}
                    metric="thighs"
                  />
                  <IoIosArrowForward size={26} />
                </div>
              </RowHeader>
              <RowBody $shouldShowDetails={shouldDisplayDetails('thighs')}>
                <div className="column">
                  <Thighs>
                    {metrics.thighs.map((thigh) => (
                      <MetricCard
                        key={thigh.id}
                        metric={thigh}
                        handleOnDelete={
                          () => handleOnDelete('thighs', thigh.id)
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    ))}
                  </Thighs>
                </div>
              </RowBody>
            </Row>
          )}
          {metrics.biceps && (
            <Row onClick={() => handleToggleDescription('biceps')}>
              <RowHeader $shouldShowDetails={shouldDisplayDetails('biceps')}>
                <div className="row-header-info">
                  <span>Biceps</span>
                </div>
                <div className="actions">
                  <CreateModal
                    client_id={client.id}
                    onCreate={handleOnCreate}
                    metric="biceps"
                  />
                  <IoIosArrowForward size={26} />
                </div>
              </RowHeader>
              <RowBody $shouldShowDetails={shouldDisplayDetails('biceps')}>
                <div className="column">
                  <Biceps>
                    {metrics.biceps.map((bicep) => (
                      <MetricCard
                        key={bicep.id}
                        metric={bicep}
                        handleOnDelete={
                          () => handleOnDelete('biceps', bicep.id)
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    ))}
                  </Biceps>
                </div>
              </RowBody>
            </Row>
          )}

          {metrics.metrics_op1_title && (
            <Row onClick={() => handleToggleDescription('op1s')}>
              <RowHeader $shouldShowDetails={shouldDisplayDetails('op1s')}>
                <div className="row-header-info">
                  <span>{metrics.metrics_op1_title}</span>
                </div>
                <div className="actions">
                  <CreateModal
                    client_id={client.id}
                    onCreate={handleOnCreate}
                    metric="op1s"
                    title={metrics.metrics_op1_title}
                  />
                  <IoIosArrowForward size={26} />
                </div>
              </RowHeader>
              <RowBody $shouldShowDetails={shouldDisplayDetails('op1s')}>
                <div className="column">
                  <Extras1>
                    {metrics.metrics_op1 &&
                      metrics.metrics_op1.map((extra) => (
                        <MetricCard
                          key={extra.id}
                          metric={extra}
                          handleOnDelete={
                            () => handleOnDelete('op1s', extra.id)
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        />
                      ))}
                  </Extras1>
                </div>
              </RowBody>
            </Row>
          )}
          {metrics.metrics_op2_title && (
            <Row onClick={() => handleToggleDescription('op2s')}>
              <RowHeader $shouldShowDetails={shouldDisplayDetails('op2s')}>
                <div className="row-header-info">
                  <span>{metrics.metrics_op2_title}</span>
                </div>
                <div className="actions">
                  <CreateModal
                    client_id={client.id}
                    onCreate={handleOnCreate}
                    metric="op2s"
                    title={metrics.metrics_op2_title}
                  />
                  <IoIosArrowForward size={26} />
                </div>
              </RowHeader>
              <RowBody $shouldShowDetails={shouldDisplayDetails('op2s')}>
                <div className="column">
                  <Extras2>
                    {metrics.metrics_op2 &&
                      metrics.metrics_op2.map((extra) => (
                        <MetricCard
                          key={extra.id}
                          metric={extra}
                          handleOnDelete={
                            () => handleOnDelete('op2s', extra.id)
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        />
                      ))}
                  </Extras2>
                </div>
              </RowBody>
            </Row>
          )}
        </Content>
      )}
    </Container>
  );
};

export default MetricsList;
