import styled, { css } from 'styled-components';
import Input from '../../../../components/Input';

interface ContainerProps {
  $shouldDisplay: boolean;
}

const displayCss = css`
  display: flex;
  flex-direction: column;
`;
const hideCss = css`
  display: none;
`;
export const Container = styled.div<ContainerProps>`
  border-right: 1px solid #ddd;
  width: 100%;

  ${(props) => (props.$shouldDisplay ? displayCss : hideCss)}

  height: 100vh;
  max-height: 530px;
  @media (min-width: 600px) {
    width: 35%;
    max-width: 300px;
    ${displayCss}
  }

  @media (max-width: 400px) {
    height: 100vh;
    max-height: 380px;
  }
`;

export const Search = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;
export const SearchInput = styled(Input)`
  height: 10px;
`;

export const ChatList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--super-light-gray);
  }
`;
