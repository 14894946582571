import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ChatMessage from '../../../entities/ChatMessage';
import ChatRoom from '../../../entities/ChatRoom';
import { useChat } from '../../../hooks/Chat';
import api from '../../../services/api';
import ChatWindow from './ChatWindow';
import Sidebar from './Sidebar';

import {
  NavigationBar,
  Container,
  Content,
  AppWindow,
  ContentArea,
  BackButton,
} from './styles';

interface NewMessageResponse {
  chat_room: ChatRoom;
  chat_message: ChatMessage;
}

const Chats: React.FC = () => {
  const { socket } = useChat();
  const history = useHistory();
  const [rooms, setRooms] = useState<ChatRoom[]>([]);
  const [notificationRooms, setNotificationRooms] = useState<string[]>([]);
  const [connectedRoom, setConnectedRoom] = useState<ChatRoom | undefined>(
    undefined,
  );
  const [alreadyConnected, setAlreadyConnected] = useState<boolean>(false);

  const handleNewMessage = useCallback(
    (newMessage: NewMessageResponse) => {
      const index = rooms.findIndex(
        (room) => room.token === newMessage.chat_room.token,
      );

      rooms[index].last_message = newMessage.chat_message.sent_at;

      if (
        !notificationRooms.find(
          (token) => token === newMessage.chat_room.token,
        ) &&
        (!connectedRoom || connectedRoom?.token !== newMessage.chat_room.token)
      ) {
        setNotificationRooms([
          ...notificationRooms,
          newMessage.chat_room.token,
        ]);
      }
      setRooms(
        [...rooms].sort((a, b) => (b.last_message < a.last_message ? -1 : 1)),
      );
    },
    [rooms, connectedRoom, notificationRooms],
  );

  useEffect(() => {
    if (rooms.length > 0 && !alreadyConnected) {
      rooms.forEach((room) => {
        setAlreadyConnected(true);
        socket?.emit('connect-room', { token: room.token });
        if (
          !notificationRooms.find((token) => token === room.token) &&
          room.has_unread
        ) {
          setNotificationRooms([...notificationRooms, room.token]);
        }
      });
    }
  }, [rooms, handleNewMessage, socket, notificationRooms, alreadyConnected]);

  const fetchRooms = useCallback(async () => {
    const response = await api.get<ChatRoom[]>(`/chat-room-admin`);
    setRooms(response.data);
  }, []);

  const openChat = useCallback(
    (id: string) => {
      const room = rooms.find((chat_room) => chat_room.id === id);
      setConnectedRoom(room);
      if (room) {
        setNotificationRooms(
          [...notificationRooms].filter((token) => token !== room.token),
        );
      }
    },
    [rooms, notificationRooms],
  );

  useEffect(() => {
    socket?.on('new-message', (data: NewMessageResponse) => {
      handleNewMessage(data);
    });
    return () => {
      socket?.off('new-message');
    };
  });
  const handleBackToList = useCallback(() => {
    setConnectedRoom(undefined);
  }, []);

  useEffect(() => {
    fetchRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <NavigationBar>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Chat</span>
        </strong>
        {connectedRoom && (
          <BackButton onClick={handleBackToList}>Back to list</BackButton>
        )}
      </NavigationBar>
      <Content>
        <AppWindow>
          {rooms.length > 0 && (
            <Sidebar
              rooms={rooms}
              selected_room={connectedRoom}
              onChatSelection={openChat}
              notification_rooms={notificationRooms}
            />
          )}
          <ContentArea>
            {connectedRoom && <ChatWindow room={connectedRoom} />}
          </ContentArea>
        </AppWindow>
      </Content>
    </Container>
  );
};

export default Chats;
