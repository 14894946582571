import React, { useState, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiUser, FiUsers } from 'react-icons/fi';
import { RiUserSettingsLine } from 'react-icons/ri';

import {
  chat,
  sharedResources,
  white_chat,
  white_sharedResources,
} from '../../styles/icons';
import { Container, MenuItem } from './styles';

const AdminSideMenu: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleMenuSelectedAt = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);
  const { pathname } = useLocation();
  useEffect(() => {
    switch (pathname) {
      case '/admin-clients':
        setSelectedIndex(1);
        break;
      case '/admin-groups':
        setSelectedIndex(2);
        break;
      case '/admin-chats':
        setSelectedIndex(3);
        break;
      case '/admin-resources':
        setSelectedIndex(4);
        break;
      case '/staff':
        setSelectedIndex(5);
        break;
      default:
        setSelectedIndex(0);
    }
  }, [pathname]);

  return (
    <Container>
      <ul>
        <MenuItem $isSelected={selectedIndex === 1}>
          <Link to="/admin-clients" onClick={() => handleMenuSelectedAt(1)}>
            <FiUser
              size={47}
              color={selectedIndex === 1 ? '#ffffff' : '#717171'}
            />
            <strong>Clients</strong>
          </Link>
        </MenuItem>
        <MenuItem
          $isSelected={selectedIndex === 2}
          onClick={() => handleMenuSelectedAt(2)}
        >
          <Link to="/admin-groups">
            <FiUsers
              size={47}
              color={selectedIndex === 2 ? '#ffffff' : '#717171'}
            />
            <strong>Groups</strong>
          </Link>
        </MenuItem>
        <MenuItem
          $isSelected={selectedIndex === 3}
          onClick={() => handleMenuSelectedAt(3)}
        >
          <Link to="/admin-chats">
            <img src={selectedIndex === 3 ? white_chat : chat} alt="" />
            <strong>Chats</strong>
          </Link>
        </MenuItem>
        <MenuItem
          $isSelected={selectedIndex === 4}
          onClick={() => handleMenuSelectedAt(4)}
        >
          <Link to="/admin-resources">
            <img
              src={
                selectedIndex === 4 ? white_sharedResources : sharedResources
              }
              alt=""
            />
            <strong>Resources Library</strong>
          </Link>
        </MenuItem>
        <MenuItem
          $isSelected={selectedIndex === 5}
          onClick={() => handleMenuSelectedAt(5)}
        >
          <Link to="/staff">
            <RiUserSettingsLine
              size={47}
              color={selectedIndex === 5 ? '#ffffff' : '#717171'}
            />
            <strong>Staff</strong>
          </Link>
        </MenuItem>
      </ul>
    </Container>
  );
};

export default AdminSideMenu;
