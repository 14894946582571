import styled from 'styled-components';
import Button from '../../../components/Button';

export const Container = styled.div`
  width: 100%;
  padding: 20px 30px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    height: 100%;
    max-width: 500px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SubmitButton = styled(Button)`
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 8px 13px 9px 13px;
  font-size: 10px;
  font-weight: normal;
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
  @media (min-width: 400px) {
    font-size: 16px;
  }
`;

export const Avatar = styled.div`
  position: relative;
  .camera {
    position: absolute;
    bottom: 2px;
    right: 2px;
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    object-fit: cover;
  }
`;

export const Info = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
