import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import NavigationHeader from '../../../components/NavigationHeader';
import CaseNote from '../../../entities/CaseNote';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import CreateModal from './CreateModal';
import DeleteConfirmation from './DeleteConfirmation';
import EditModal from './EditModal';

import {
  Container,
  Content,
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from './styles';

interface CaseNotesParams {
  id: string;
}

const CaseNotes: React.FC = () => {
  const { id } = useParams<CaseNotesParams>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [caseNotes, setCaseNotes] = useState<CaseNote[]>([] as CaseNote[]);
  const { addToast } = useToast();

  const fetchCaseNotes = useCallback(async () => {
    try {
      const response = await api.get<CaseNote[]>(`client-case-notes/${id}`);
      setCaseNotes(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not load Case Notes',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast, id]);
  useEffect(() => {
    fetchCaseNotes();
  }, [fetchCaseNotes]);

  const handleRefreshCaseNotes = useCallback(
    (case_note?: CaseNote) => {
      if (case_note) {
        setCaseNotes([...caseNotes, case_note]);
      } else {
        fetchCaseNotes();
      }
    },
    [caseNotes, fetchCaseNotes],
  );

  const handleDelete = useCallback(
    async (case_note_id: string) => {
      try {
        await api.delete(`case-notes/${case_note_id}`);

        setCaseNotes(
          caseNotes.filter((caseNote) => caseNote.id !== case_note_id),
        );
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Could not delete case note',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, caseNotes],
  );

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button type="button" onClick={() => history.push('/admin-clients')}>
            Clients /
          </button>
          <button type="button" onClick={() => history.goBack()}>
            Profile /
          </button>
          <span>Case Notes</span>
        </strong>
        <CreateModal onCreate={handleRefreshCaseNotes} client_id={id} />
      </NavigationHeader>
      {isLoading ? (
        <Content>
          <Loading />
        </Content>
      ) : (
        <Content>
          {caseNotes.map((case_note) => (
            <Card key={case_note.id}>
              <CardHeader>
                <EditModal
                  case_note={case_note}
                  onEdit={handleRefreshCaseNotes}
                />
                <DeleteConfirmation
                  id={case_note.id}
                  title={case_note.title}
                  onDelete={handleDelete}
                />
              </CardHeader>
              <CardContent
                href={case_note.document_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={case_note.thumbnail_url} alt={case_note.title} />
              </CardContent>
              <CardFooter>
                <strong>{case_note.title}</strong>
              </CardFooter>
            </Card>
          ))}
        </Content>
      )}
    </Container>
  );
};

export default CaseNotes;
