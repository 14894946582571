import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { IoIosArrowForward } from 'react-icons/io';
import {
  Container,
  Header,
  Content,
  Row,
  RowHeader,
  RowBody,
  ActionButtons,
} from './styles';
import api from '../../services/api';
import Client from '../../entities/Client';
import CreateModal from './CreateModal';
import Goal from '../../entities/Goal';
import DeleteConfirmation from './DeleteConfirmation';
import EditModal from './EditModal';

interface GoalsSectionProps {
  goals: Goal[];
  client: Client;
  isAdmin: boolean;
  handleRefreshGoals: (goal?: Goal) => void;
}
const GoalsSection: React.FC<GoalsSectionProps> = ({
  goals,
  client,
  isAdmin,
  handleRefreshGoals,
}) => {
  const [showDetails, setShowDetails] = useState<string[]>([]);

  const handleRemoveGoal = useCallback(
    (id) => {
      api.delete(`goals/${id}`).then(() => {
        handleRefreshGoals();
      });
    },
    [handleRefreshGoals],
  );

  const handleOnCreate = useCallback(
    (addedGoal: Goal) => {
      handleRefreshGoals(addedGoal);
    },
    [handleRefreshGoals],
  );

  const handleOnEdit = useCallback(() => {
    handleRefreshGoals();
  }, [handleRefreshGoals]);

  const handleToggleDescription = useCallback(
    (forId: string) => {
      if (showDetails.indexOf(forId) !== -1) {
        setShowDetails(showDetails.filter((goal) => goal !== forId));
      } else {
        setShowDetails([...showDetails, forId]);
      }
    },
    [showDetails],
  );

  function shouldDisplayDetails(for_id: string): boolean {
    return showDetails.find((goal) => goal === for_id) !== undefined;
  }

  return (
    <Container>
      <Header>
        <h3>Goals</h3>
        {isAdmin && (
          <CreateModal
            client_id={client.id}
            onCreate={(addedGoal: Goal) => handleOnCreate(addedGoal)}
          />
        )}
      </Header>

      <Content>
        {goals.map((goal) => (
          <Row key={goal.id} onClick={() => handleToggleDescription(goal.id)}>
            <RowHeader $shouldShowDetails={shouldDisplayDetails(goal.id)}>
              <div className="row-header-info">
                <span>Goal</span>
                <strong>{goal.title}</strong>
              </div>
              <div />
              <IoIosArrowForward size={26} />
            </RowHeader>
            <RowBody $shouldShowDetails={shouldDisplayDetails(goal.id)}>
              <div className="column top">
                <strong className="content-title">Timeline</strong>
                <span>{format(new Date(goal.timeline), 'dd/MM/yyyy')}</span>
              </div>
              <div className="column">
                <strong className="content-title">Monitoring</strong>
                <span>{goal.monitoring}</span>
              </div>
              {isAdmin && (
                <ActionButtons>
                  <EditModal goal={goal} onEdit={handleOnEdit} />

                  <DeleteConfirmation
                    id={goal.id}
                    title={goal.title}
                    onDelete={(id: string) => handleRemoveGoal(id)}
                  />
                </ActionButtons>
              )}
            </RowBody>
          </Row>
        ))}
      </Content>
    </Container>
  );
};

export default GoalsSection;
