import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { addDays, format, subDays } from 'date-fns';
import { uuid } from 'uuidv4';
import NavigationHeader from '../../../components/NavigationHeader';

import {
  Container,
  Content,
  ClientName,
  Header,
  SubmitButton,
  Separator,
} from './styles';
import api from '../../../services/api';
import Client from '../../../entities/Client';
import { useToast } from '../../../hooks/Toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Input';
import { Checkin, CheckinItem } from '../../../entities/Checkin';
import CheckinList from './CheckinList';
import Dictionary from '../../../entities/Dictionary';

interface CheckinParams {
  id: string;
}

interface UpdateQuestionsFormData {
  checkin_q1: string;
  checkin_q2: string;
}

interface CheckinStateProps {
  checkin_q1: string;
  checkin_q2: string;
  client_name: string;
}

const CheckinComponent: React.FC = () => {
  const { id } = useParams<CheckinParams>();
  const { state } = useLocation<CheckinStateProps>();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { addToast } = useToast();
  const [extraQuestions, setExtraQuestions] = useState<UpdateQuestionsFormData>(
    () => {
      return {
        checkin_q1: state.checkin_q1,
        checkin_q2: state.checkin_q2,
      };
    },
  );
  const [checkins, setCheckins] = useState<Dictionary<CheckinItem>>(
    {} as Dictionary<CheckinItem>,
  );

  const [checkin, setCheckin] = useState<Checkin | undefined>(undefined);

  const handleRefresh = useCallback(
    async (start_date: string) => {
      try {
        const response = await api.get<Checkin>(`/checkins/client/${id}`, {
          params: {
            start_date,
          },
        });
        const info = response.data;
        const dictionary: Dictionary<CheckinItem> = {
          question1: {
            id: uuid(),
            question: info.question_1,
            answer: info.answer_1,
          },
          question2: {
            id: uuid(),
            question: info.question_2,
            answer: info.answer_2,
          },
          question3: {
            id: uuid(),
            question: info.question_3,
            answer: info.answer_3,
          },
          question4: {
            id: uuid(),
            question: info.question_4,
            answer: info.answer_4,
          },
          question5: {
            id: uuid(),
            question: info.question_5,
            answer: info.answer_5,
          },
          question6: {
            id: uuid(),
            question: info.question_6,
            answer: info.answer_6,
          },
          question7: {
            id: uuid(),
            question: info.question_7,
            answer: info.answer_7,
          },
          question8: {
            id: uuid(),
            question: info.question_8 ?? extraQuestions.checkin_q1,
            answer: info.answer_8 ?? '',
          },
          question9: {
            id: uuid(),
            question: info.question_9 ?? extraQuestions.checkin_q2,
            answer: info.answer_9 ?? '',
          },
        };
        setCheckins(dictionary);
        setCheckin(info);

        setIsLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Load Failed',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [id, extraQuestions, addToast],
  );

  useEffect(() => {
    handleRefresh(format(new Date(), 'yyyy-MM-dd'));
  }, [handleRefresh]);

  const handleSubmit = useCallback(
    async (data: UpdateQuestionsFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          checkin_q1: Yup.string(),
          checkin_q2: Yup.string(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const resource = {
          checkin_q1: data.checkin_q1,
          checkin_q2: data.checkin_q2,
        };

        // CREATE CONTENT
        const response = await api.put<Client>(
          `/clients-checkins/${id}`,
          resource,
        );

        setExtraQuestions({
          checkin_q1: response.data.checkin_q1,
          checkin_q2: response.data.checkin_q2,
        });

        addToast({
          type: 'success',
          title: 'Update Success',
        });

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Update Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, id],
  );

  const getPreviousWeek = useCallback(
    (start_date: string) => {
      handleRefresh(format(subDays(new Date(start_date), 3), 'yyyy-MM-dd'));
    },
    [handleRefresh],
  );

  const getNextWeek = useCallback(
    (end_date: string) => {
      handleRefresh(format(addDays(new Date(end_date), 3), 'yyyy-MM-dd'));
    },
    [handleRefresh],
  );

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button type="button" onClick={() => history.push('/admin-clients')}>
            Clients /
          </button>
          <button type="button" onClick={() => history.goBack()}>
            Profile /
          </button>
          <span>Check in list</span>
        </strong>
      </NavigationHeader>
      <Content>
        <ClientName>
          <strong>
            Check in from: <span>{state.client_name}</span>
          </strong>
        </ClientName>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Header>
            <strong>Extra Questions</strong>
            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Updating...' : 'Save'}
            </SubmitButton>
          </Header>
          <div>
            <Input
              name="checkin_q1"
              placeholder="Extra Question 1"
              defaultValue={extraQuestions.checkin_q1}
            />
            <Input
              name="checkin_q2"
              placeholder="Extra Question 2"
              defaultValue={extraQuestions.checkin_q2}
            />
          </div>
        </Form>
        <Separator />
        {checkin && (
          <CheckinList
            checkins={checkins}
            start_date={checkin.start_date}
            end_date={checkin.end_date}
            handleGetPreviousWeek={getPreviousWeek}
            handleGetNextWeek={getNextWeek}
          />
        )}
      </Content>
    </Container>
  );
};

export default CheckinComponent;
