import React from 'react';
import { useHistory } from 'react-router-dom';
import ClientPhotoList from '../../../components/ClientPhotoList';
import NavigationHeader from '../../../components/NavigationHeader';

import { Container } from './styles';

const Photos: React.FC = () => {
  const history = useHistory();
  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button type="button" onClick={() => history.goBack()}>
            Goals & Metrics /
          </button>
          <span>Past Photos</span>
        </strong>
      </NavigationHeader>
      <ClientPhotoList />
    </Container>
  );
};

export default Photos;
