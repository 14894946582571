import styled from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled.div``;

export const CreateNew = styled(Button)`
  height: 40px;
  width: 50px;
  margin-top: 0px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  strong {
    color: var(--primary);
    font-weight: bold;
  }
`;

export const CloseButton = styled(Button)`
  margin-top: 0;
  width: 40px;
  height: 40px;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 8px;

  &:hover {
    background-image: none;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 0;
  height: 40px;
  min-height: 40px;

  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;

export const Remind = styled.div`
  border: 2px solid var(--light-background);
  border-radius: 5px;
  padding: 10px 16px;
  h3 {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 12px;
  }

  margin-bottom: 10px;
`;
export const RemindOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 12px;
    margin-top: 10px;
  }
  strong {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary);
  }
`;
