import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 50px;
  border-bottom: 1px solid var(--light-background);
  width: 100%;
`;
interface ClientInfoProps {
  $isOnline: boolean;
}
export const ClientInfo = styled.div<ClientInfoProps>`
  padding: 0 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
  }
  strong {
    font-size: 14px;
  }

  span {
    color: var(--error);
  }
  ${(props) =>
    props.$isOnline &&
    css`
      span {
        color: var(--primary);
      }
    `}
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 10px;
  }
`;
