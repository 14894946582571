import React from 'react';
import Recipe from '../../../../entities/Recipe';

import {
  Container,
  Header,
  Content,
  Card,
  CardBody,
  CardFooter,
} from './styles';

interface RecipeListProps {
  recipes: Recipe[];
}

const RecipeList: React.FC<RecipeListProps> = ({ recipes }) => {
  return (
    <Container>
      <Header>
        <strong>Recipes</strong>
      </Header>
      <Content>
        {recipes.map((recipe) => (
          <Card key={recipe.id}>
            <CardBody
              href={recipe.recipe_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={recipe.thumbnail_url} alt={recipe.title} />
            </CardBody>
            <CardFooter>
              <strong>{recipe.title}</strong>
            </CardFooter>
          </Card>
        ))}
      </Content>
    </Container>
  );
};

export default RecipeList;
