import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import NavigationHeader from '../../../../components/NavigationHeader';
import EditNutrition from '../../../../components/Nutrition/EditNutrition';
import DeleteConfirmation from '../../../../components/Nutrition/DeleteConfirmation';
import SeparatorComponent from '../../../../components/SeparatorComponent';
import Nutrition from '../../../../entities/Nutrition';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';

import DailyMealComponent from '../DailyMealComponent';
import DetailedNutritionComponent from '../DetailedNutritionComponent';

import { Container, ClientDetails, Content, ActionButtons } from './styles';
import KeyPrinciple from '../../../../entities/KeyPrinciple';
import Recipe from '../../../../entities/Recipe';

interface NutritionDetailsStateProps {
  returnToProfile: boolean;
  nutrition: Nutrition;
  client_name: string;
  available_at: Date;
}

interface NutritionResponse {
  nutrition?: Nutrition;
  key_principles?: KeyPrinciple[];
  recipes?: Recipe[];
}

interface NutritionDetailsPrams {
  id: string;
}

const NutritionDetail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<NutritionDetailsPrams>();
  const { state } = useLocation<NutritionDetailsStateProps>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [nutrition, setNutrition] = useState<Nutrition>(state.nutrition);
  const [status, setStatus] = useState(state.nutrition.active);

  const fetchNutrition = useCallback(async () => {
    try {
      const response = await api.get<NutritionResponse>(`nutritions/${id}`);
      if (response.data.nutrition) {
        setNutrition(response.data.nutrition);
      }
      setIsLoading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not load Nutrition',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    }
  }, [id, addToast]);

  useEffect(() => {
    fetchNutrition();
  }, [fetchNutrition]);

  const handleDeleteDetailedNutrition = useCallback(
    async (detailed_nutrition_id: string) => {
      try {
        await api.delete(`detailed-nutritions/${detailed_nutrition_id}`);
        fetchNutrition();
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Could not delete Detailed Nutrition',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, fetchNutrition],
  );

  const handleDailyPlanCreated = useCallback(() => {
    fetchNutrition();
  }, [fetchNutrition]);

  const handleDailyPlanEdited = useCallback(() => {
    fetchNutrition();
  }, [fetchNutrition]);

  const handleDailyPlanDeleted = useCallback(
    async (daily_plan_id: string) => {
      try {
        await api.delete(`daily-plans/${daily_plan_id}`);
        fetchNutrition();
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Could not delete Daily Meal Plan',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, fetchNutrition],
  );

  const handleEditNutrition = useCallback((new_nutrition: Nutrition) => {
    setNutrition(new_nutrition);
  }, []);

  const handleDeleteNutrition = useCallback(async () => {
    try {
      await api.delete(`nutritions/${id}`);
      setStatus(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not load profile',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    }
  }, [addToast, id]);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>

          <button type="button" onClick={() => history.push('/admin-clients')}>
            Clients /
          </button>

          {state.returnToProfile ? (
            <button
              type="button"
              onClick={
                () =>
                  history.push(`/admin-client-profile/${nutrition.client_id}`)
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              Profile /
            </button>
          ) : (
            <>
              <button type="button" onClick={() => history.goBack()}>
                Past Nutrition /
              </button>
            </>
          )}
          <span>Nutrition Details</span>
        </strong>
      </NavigationHeader>
      <ClientDetails $isActive={status}>
        <div className="nutrition_info">
          <strong>
            For Client: <span>{state.client_name}</span>
          </strong>
          <strong>
            Title: <span>{nutrition.title}</span>
          </strong>
          <strong>
            Available on:{' '}
            <span>{format(new Date(nutrition.start_date), 'dd/MM/yyyy')}</span>
          </strong>
          <strong>
            Status:{' '}
            <span className="status">{status ? 'Active' : 'Disabled'}</span>
          </strong>
        </div>
        <ActionButtons>
          <EditNutrition nutrition={nutrition} onEdit={handleEditNutrition} />
          <DeleteConfirmation
            id={nutrition.id}
            title={nutrition.title}
            onDelete={handleDeleteNutrition}
          />
        </ActionButtons>
      </ClientDetails>
      <Content>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <DetailedNutritionComponent
              detailed_nutrition={nutrition.detailed_nutrition}
              nutrition_id={nutrition.id}
              client_id={nutrition.client_id}
              handleDelete={handleDeleteDetailedNutrition}
              handleRefreshDetailedNutrition={fetchNutrition}
            />
            <SeparatorComponent />
            <DailyMealComponent
              nutrition_id={nutrition.id}
              daily_meal_planner={nutrition.daily_plans}
              handleOnCreate={handleDailyPlanCreated}
              handleOnEdit={handleDailyPlanEdited}
              handleDelete={handleDailyPlanDeleted}
            />
          </>
        )}
      </Content>
    </Container>
  );
};

export default NutritionDetail;
