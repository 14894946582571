import React, { useState, useCallback } from 'react';
import { FiUser, FiUsers } from 'react-icons/fi';
import { RiUserSettingsLine } from 'react-icons/ri';
import {
  Container,
  Clients,
  Chat,
  SharedResources,
  CustomLink,
} from './styles';
import {
  white_chat,
  chat,
  sharedResources,
  white_sharedResources,
} from '../../styles/icons';

const AdminBottomMenu: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleMenuSelectedAt = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);
  return (
    <Container>
      <CustomLink
        $isSelected={selectedIndex === 1}
        to="/admin-clients"
        onClick={() => handleMenuSelectedAt(1)}
      >
        <Clients>
          <FiUser
            size={41}
            color={selectedIndex === 1 ? '#ffffff' : '#717171'}
          />
        </Clients>
      </CustomLink>
      <CustomLink
        $isSelected={selectedIndex === 2}
        to="/admin-groups"
        onClick={() => handleMenuSelectedAt(2)}
      >
        <Clients>
          <FiUsers
            size={41}
            color={selectedIndex === 2 ? '#ffffff' : '#717171'}
          />
        </Clients>
      </CustomLink>
      <CustomLink
        $isSelected={selectedIndex === 3}
        to="/admin-chats"
        onClick={() => handleMenuSelectedAt(3)}
      >
        <Chat>
          <img src={selectedIndex === 3 ? white_chat : chat} alt="your chat" />
        </Chat>
      </CustomLink>
      <CustomLink
        $isSelected={selectedIndex === 4}
        to="/admin-resources"
        onClick={() => handleMenuSelectedAt(4)}
      >
        <SharedResources>
          <img
            src={selectedIndex === 4 ? white_sharedResources : sharedResources}
            alt="Shared Resources"
          />
        </SharedResources>
      </CustomLink>
      <CustomLink
        $isSelected={selectedIndex === 5}
        to="/staff"
        onClick={() => handleMenuSelectedAt(5)}
      >
        <SharedResources>
          <RiUserSettingsLine
            size={47}
            color={selectedIndex === 5 ? '#ffffff' : '#717171'}
          />
        </SharedResources>
      </CustomLink>
    </Container>
  );
};

export default AdminBottomMenu;
