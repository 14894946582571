import styled from 'styled-components';
import Modal from 'styled-react-modal';
import Button from '../../Button';

export const Container = styled.div``;

export const StyledModal = Modal.styled`
  width: 90%;
  max-width: 800px;
  height: 80%;
  max-height: 656px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 15px;
  overflow-y: auto;

  @media(min-width: 760px){
    height: 60%;
  }
  @media(max-height: 800px){
    height: 78vh;
  }
`;

export const CreateNew = styled(Button)`
  height: auto;
  width: 30px;
  padding: 5px;
  margin-top: 0px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  strong {
    color: var(--primary);
    font-weight: bold;
  }
`;

export const CloseButton = styled(Button)`
  margin-top: 0;
  width: 40px;
  height: 40px;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 8px;

  &:hover {
    background-image: none;
  }
`;

export const Timeline = styled.div`
  border: 2px solid var(--light-background);
  border-radius: 5px;
  padding: 10px 16px;
  h3 {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 12px;
  }
  width: 100%;
  margin-bottom: 10px;
`;
export const TimelineOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const SubmitButton = styled(Button)`
  margin-top: 0;
  height: 40px;
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;
