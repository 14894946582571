import React, { useState, useCallback, useRef } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import {
  Container,
  CreateNew,
  Header,
  Content,
  CloseButton,
  SubmitButton,
} from './styles';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import Modal from '../../../../components/Modal';
import Group from '../../../../entities/Groups';

interface CreateGroupFormData {
  name: string;
}

interface CreateModalProps {
  onCreate: (group: Group) => void;
}

const CreateModal: React.FC<CreateModalProps> = ({ onCreate }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: CreateGroupFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().min(4, 'Name is Required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const groupData = {
          name: data.name,
        };

        // CREATE CONTENT
        const response = await api.post<Group>('/groups', groupData);

        addToast({
          type: 'success',
          title: 'Creation Success',
          description: `Group was added to your list`,
        });
        onCreate(response.data);
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, handleToggle, onCreate],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={30} color="#ffffff" />
      </CreateNew>
      <Modal isOpen={isOpen} handleToggle={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Create Group</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <div>
              <Input name="name" placeholder="Name" />
            </div>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Creating...' : 'Create'}
            </SubmitButton>
          </Form>
        </Content>
      </Modal>
    </Container>
  );
};

export default CreateModal;
