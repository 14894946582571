import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiSearch } from 'react-icons/fi';
import ChatRoom from '../../../../entities/ChatRoom';

import { Container, Search, SearchInput, ChatList } from './styles';
import ChatItem from './ChatItem';

interface SidebarProps {
  rooms: ChatRoom[];
  selected_room?: ChatRoom;
  notification_rooms: string[];
  onChatSelection: (id: string) => void;
}

interface SearchTermProps {
  search: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  rooms,
  selected_room,
  notification_rooms,
  onChatSelection,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const result = useMemo(() => {
    return !searchTerm
      ? rooms
      : rooms.filter((room) =>
          room.client_name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
  }, [rooms, searchTerm]);

  const handleSubmit = useCallback((data: SearchTermProps) => {
    setSearchTerm(data.search);
  }, []);
  const handleSearchChanged = useCallback((data) => {
    setSearchTerm(data.target.value);
  }, []);

  return (
    <Container $shouldDisplay={selected_room === undefined}>
      <Search>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <SearchInput
            type="search"
            name="search"
            placeholder="Enter text"
            icon={FiSearch}
            onChange={handleSearchChanged}
          />
        </Form>
      </Search>
      <ChatList>
        {result.map((room) => (
          <ChatItem
            key={room.id}
            room={room}
            is_selected={selected_room ? selected_room.id === room.id : false}
            handleChatSelection={onChatSelection}
            has_unread={
              notification_rooms.find((token) => token === room.token) !==
              undefined
            }
          />
        ))}
      </ChatList>
    </Container>
  );
};

export default Sidebar;
