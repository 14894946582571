import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NavigationHeader from '../../../components/NavigationHeader';

import { Container } from './styles';

interface AdminRecipesParams {
  id: string;
}
const Recipes: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<AdminRecipesParams>();
  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard
          </button>
          <button type="button" onClick={() => history.push('/admin-clients')}>
            Clients
          </button>
          <button
            type="button"
            onClick={() => history.push(`/admin-client-profile/${id}`)}
          >
            Profile
          </button>
          <span>Recipes</span>
        </strong>
      </NavigationHeader>
    </Container>
  );
};

export default Recipes;
