import styled from 'styled-components';
import Modal from 'styled-react-modal';

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0px 5px 0px 5px;
`;

export const StyledModal = Modal.styled`

  max-width: 800px;
  height: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 15px;
  overflow: auto;
  @media(max-height: 800px){
    height: 98%;
  }
`;
export const Content = styled.div`
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
  }

  position: relative;
`;

export const ModalHeader = styled.div`
  position: absolute;
  padding: 10px 10px 5px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
  z-index: 999;
  background: rgba(188, 188, 188, 0.3);
`;

export const Info = styled.div`
  span {
    margin-right: 10px;
  }
`;

export const Photo = styled.div`
  overflow: hidden;
  border-radius: 5px;
  background: var(--list-background);
`;

export const PhotoHeader = styled.div``;

export const PhotoBody = styled.button`
  img {
    max-width: 70px;
  }

  @media (min-width: 375px) {
    img {
      max-width: 90px;
    }
  }

  @media (min-width: 414px) {
    img {
      max-width: 105px;
    }
  }

  @media (min-width: 500px) {
    img {
      max-width: 200px;
    }
  }
`;
