import React, { HTMLAttributes } from 'react';

import { StyledModal } from './styles';

interface ModalProps extends HTMLAttributes<HTMLLinkElement> {
  isOpen: boolean;
  handleToggle: () => void;
}
const Modal: React.FC<ModalProps> = ({ isOpen, handleToggle, children }) => {
  return (
    <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
      {children}
    </StyledModal>
  );
};

export default Modal;
