import React, { useState, useCallback, useRef, ChangeEvent } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import {
  Container,
  CreateNew,
  Header,
  Content,
  Files,
  CloseButton,
  SubmitButton,
} from './styles';
import { useToast } from '../../../../../hooks/Toast';
import api from '../../../../../services/api';
import getTypeFromExtension from '../../../../../utils/getTypeFromExtension';
import Modal from '../../../../../components/Modal';
import FileInput from '../../../../../components/FileInput';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import DetailedNutrition from '../../../../../entities/DetailedNutrition';

interface DocumentUploadProps {
  name: string;
  folder: string;
  type: string;
}

interface CreateDetailedNutritionProps {
  onCreate: (detailed_nutrition: DetailedNutrition) => void;
  client_id: string;
  nutrition_id: string;
}

const CreateModal: React.FC<CreateDetailedNutritionProps> = ({
  onCreate,
  client_id,
  nutrition_id,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentData, setDocumentData] = useState<FormData | null>(null);
  const [documentTitle, setDocumentTitle] = useState('Detailed Nutrition');

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDocumentUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();
        const file = e.target.files[0];
        data.append('file', file);
        setDocumentData(data);
        setDocumentTitle(`Detailed Nutrition: ${file.name}`);
      }
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      formRef.current?.setErrors({});

      const resource = {
        client_id,
        nutrition_id,
      };

      // UPLOAD CONTENT
      let documentName = '';
      if (documentData) {
        const response = await api.post<DocumentUploadProps>(
          '/documents/detailed-nutritions',
          documentData,
        );
        documentName = response.data.name;
        const ext = documentName.substr(documentName.lastIndexOf('.') + 1);
        Object.assign(resource, {
          document: documentName,
          type: getTypeFromExtension(ext),
        });
      }

      // CREATE CONTENT
      const response = await api.post<DetailedNutrition>(
        '/detailed-nutritions',
        resource,
      );

      addToast({
        type: 'success',
        title: 'Creation Success',
        description: `Detailed Nutrition will be available at the same date as the nutrition`,
      });

      onCreate(response.data);
      handleToggle();
      setIsLoading(false);
      setDocumentData(null);
      setDocumentTitle('Detailed Nutrition');
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        setIsLoading(false);
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: 'Creation Failed',
        description: err.response
          ? err.response.data.message
          : 'Could not connect to server, please try again later',
      });
    }
  }, [addToast, client_id, nutrition_id, documentData, handleToggle, onCreate]);

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={30} color="#ffffff" />
      </CreateNew>
      <Modal isOpen={isOpen} handleToggle={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Create Detailed Nutrition</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <div>
              <Files>
                <FileInput
                  id="document"
                  name="document"
                  placeholder={documentTitle}
                  onChange={(e) => handleDocumentUpload(e)}
                />
              </Files>
            </div>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Uploading...' : 'Create'}
            </SubmitButton>
          </Form>
        </Content>
      </Modal>
    </Container>
  );
};

export default CreateModal;
