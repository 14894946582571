import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FiMail } from 'react-icons/fi';
import { Container, Content, Card, Info } from './styles';
import NavigationHeader from '../../../components/NavigationHeader';
import CreateModal from './CreateModal';
import Group from '../../../entities/Groups';
import api from '../../../services/api';
import EditForm from './EditForm';

const Groups: React.FC = () => {
  const history = useHistory();
  const [groups, setGroups] = useState<Group[]>([] as Group[]);
  const [currentOpenForEdit, setCurrentOpenForEdit] = useState<
    Group | undefined
  >();
  useEffect(() => {
    api.get<Group[]>('/groups').then((response) => {
      setGroups(
        response.data.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
        ),
      );
    });
  }, []);

  const handleOnCreate = useCallback(
    (group: Group) => {
      setGroups(
        [...groups, group].sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
        ),
      );
    },
    [groups],
  );

  const handleDeleteGroup = useCallback(
    (group_id: string) => {
      api.delete(`/groups/${group_id}`).then(() => {
        setGroups(groups.filter((group) => group.id !== group_id));
        setCurrentOpenForEdit(undefined);
      });
    },
    [groups],
  );
  const handleToggleEdit = useCallback(
    (group: Group) => {
      if (!currentOpenForEdit) {
        setCurrentOpenForEdit(group);
      } else {
        setCurrentOpenForEdit(undefined);
      }
    },
    [currentOpenForEdit],
  );

  const handleOnEdit = useCallback(
    (group: Group) => {
      const newGroups = groups;
      const index = newGroups.findIndex((g) => g.id === group.id);
      newGroups[index] = group;
      setGroups(newGroups);

      setCurrentOpenForEdit(undefined);
    },
    [groups],
  );

  const handleOnEditClosed = useCallback(() => {
    setCurrentOpenForEdit(undefined);
  }, []);
  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard
          </button>
          <span>Groups</span>
        </strong>
        <CreateModal onCreate={(group: Group) => handleOnCreate(group)} />
      </NavigationHeader>
      {currentOpenForEdit && (
        <EditForm
          group={currentOpenForEdit}
          onEdit={(group: Group) => handleOnEdit(group)}
          onDelete={(id: string) => handleDeleteGroup(id)}
          onClose={handleOnEditClosed}
        />
      )}
      <Content>
        {groups.map((group) => (
          <Card key={group.id}>
            <Info onClick={() => handleToggleEdit(group)}>
              <strong>{group.name}</strong>
            </Info>
            <Link
              to={{
                pathname: `mail/${group.id}`,
                state: {
                  mail_type: 'group',
                  group,
                },
              }}
            >
              <FiMail size={20} />
            </Link>
          </Card>
        ))}
      </Content>
    </Container>
  );
};

export default Groups;
