import React, { useState, useCallback } from 'react';
import { FiTrash } from 'react-icons/fi';
import {
  Container,
  StyledModal,
  Delete,
  Content,
  Actions,
  DeleteButton,
  Cancel,
} from './styles';

interface DeleteConfirmationProps {
  id: string;
  principle: string;
  onDelete: (id: string) => void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  id,
  principle,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDelete = useCallback(() => {
    onDelete(id);
    handleToggle();
  }, [id, handleToggle, onDelete]);

  return (
    <Container>
      <Delete onClick={handleToggle}>
        <FiTrash size={14} />
      </Delete>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <h1>Are you sure you want to delete:</h1>
          <strong>{principle}</strong>
          <span>This will remove the key principle from this client.</span>
          <Actions>
            <Cancel onClick={handleToggle}>Cancel</Cancel>
            <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
          </Actions>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default DeleteConfirmation;
