import styled from 'styled-components';
import Button from '../../../components/Button';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  height: 60%;
  flex-direction: column;

  .rdw-editor-main {
    overflow: visible;
  }

  form {
    margin-bottom: 10px;
  }
`;

export const Header = styled.div`
  margin-bottom: 10px;
  span {
    font-size: 14px;
    strong {
      font-size: 18px;
      font-weight: bold;
      color: var(--primary);
    }
  }
`;

export const SubmitButton = styled(Button)`
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 8px 13px 9px 13px;
  font-size: 10px;
  font-weight: normal;
  color: var(--white);
  @media (min-width: 400px) {
    font-size: 16px;
  }
`;
