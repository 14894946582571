import styled from 'styled-components';

export const Container = styled.div``;
export const Header = styled.div`
  strong {
    font-size: 16px;
  }

  padding: 10px;
`;
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  padding: 0 10px;
`;

export const Card = styled.div`
  width: 100%;
  min-height: 100px;
  max-width: 130px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  margin-right: 10px;

  @media (max-width: 350px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

export const CardBody = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 70px;
  background: var(--light-background);
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
`;
export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 8px;
  align-items: center;

  strong {
    font-size: 10px;
    font-weight: bold;
  }
`;
