import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { CheckinItem } from '../../../../entities/Checkin';
import Dictionary from '../../../../entities/Dictionary';

import {
  Content,
  Header,
  Week,
  BackButton,
  ForwardButton,
  Questions,
  Question,
  Answer,
} from './styles';

interface CheckinListProps {
  checkins: Dictionary<CheckinItem>;
  start_date: string;
  end_date: string;
  handleGetPreviousWeek: (start_date: string) => void;
  handleGetNextWeek: (end_date: string) => void;
}

const CheckinList: React.FC<CheckinListProps> = ({
  checkins,
  start_date,
  end_date,
  handleGetPreviousWeek,
  handleGetNextWeek,
}) => {
  const handleBackClick = useCallback(() => {
    handleGetPreviousWeek(start_date);
  }, [handleGetPreviousWeek, start_date]);

  const handleForwardClick = useCallback(() => {
    handleGetNextWeek(end_date);
  }, [handleGetNextWeek, end_date]);

  return (
    <Content>
      <Header>
        <h2>Check in for week</h2>
        <Week>
          <BackButton onClick={handleBackClick}>
            <FiArrowLeft />
          </BackButton>
          <strong>
            {format(new Date(start_date), 'dd/MM/yyyy')} -{' '}
            {format(new Date(end_date), 'dd/MM/yyyy')}
          </strong>
          <ForwardButton onClick={handleForwardClick}>
            <FiArrowRight />
          </ForwardButton>
        </Week>
      </Header>
      <Questions>
        {Object.entries(checkins).map(([, value]) => (
          <Question key={value.id}>
            <strong>{value.question}</strong>
            <Answer>
              <p>{value.answer}</p>
            </Answer>
          </Question>
        ))}
      </Questions>
    </Content>
  );
};

export default CheckinList;
