import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  loading?: boolean;
}
const Button: React.FC<ButtonProps> = ({
  children,
  className,
  loading,
  ...rest
}) => (
  <Container className={className} type="button" {...rest}>
    {loading ? 'Loading...' : children}
  </Container>
);

export default Button;
