import React, { useState, useCallback } from 'react';

import DayPicker from 'react-day-picker';
import { Container } from './styles';
import 'react-day-picker/lib/style.css';

interface CalendarProps {
  onDayClick: (date: Date) => void;
  fromMonth?: Date;
  toMonth?: Date;
  startDate?: Date;
}

const Calendar: React.FC<CalendarProps> = ({
  onDayClick,
  fromMonth,
  toMonth,
  startDate,
}) => {
  const [selectedDate, setSelectedDate] = useState(startDate || new Date());

  const handleDateChanged = useCallback(
    (day: Date) => {
      setSelectedDate(day);
      onDayClick(day);
    },
    [onDayClick],
  );
  return (
    <Container>
      <DayPicker
        fromMonth={fromMonth}
        toMonth={toMonth}
        modifiers={{
          available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
        }}
        selectedDays={selectedDate}
        month={selectedDate}
        onDayClick={handleDateChanged}
      />
    </Container>
  );
};

export default Calendar;
