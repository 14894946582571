import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  strong {
    font-weight: bold;
    font-size: 16px;
    color: var(--gray);
  }
  margin-bottom: 10px;
`;

export const Plan = styled.div`
  background: var(--light-background);
  padding: 8px 10px;
  border-radius: 5px;
  margin-bottom: 10px;

  .first_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const itemCss = css`
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
    font-weight: bold;
  }

  strong {
    color: var(--primary);
    font-size: 16px;
  }
  margin-bottom: 10px;
`;

export const ActionButtons = styled.div`
  display: flex;
`;

export const Meal = styled.div`
  ${itemCss}
`;

export const Timing = styled.div`
  ${itemCss}
`;

export const FoodData = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
`;

export const Card = styled.div`
  min-height: 70px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
  margin-top: 10px;
  min-width: 80px;

  @media (min-width: 500px) {
    margin-top: 0;
  }
`;

export const CardHeader = styled.div`
  background: var(--gray);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: var(--white);
    font-size: 14px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: ${lighten(0.2, '#bcbcbc')};
  strong {
    color: var(--dark-primary);
    font-weight: bold;
  }
`;

export const Explanation = styled.div`
  ${itemCss}
  margin-top: 10px;
`;
