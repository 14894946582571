import React from 'react';

import { Container } from './styles';

interface NavigationHeaderProps {
  className?: string;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  className,
  children,
}) => {
  return <Container className={className}>{children}</Container>;
};

export default NavigationHeader;
