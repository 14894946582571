import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  ChangeEvent,
} from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import FileInput from '../../../../components/FileInput';
import Modal from '../../../../components/Modal';
import {
  Container,
  CreateNew,
  Header,
  Content,
  Files,
  CloseButton,
  SubmitButton,
} from './styles';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import { useAuth } from '../../../../hooks/Auth';
import getTypeFromExtension from '../../../../utils/getTypeFromExtension';

interface CreateResourceFormData {
  group: string;
  title: string;
  link?: string;
  document?: string;
  thumbnail?: string;
}

interface DocumentUploadProps {
  name: string;
  folder: string;
  type: string;
}

interface CreateModalProps {
  onCreate: () => void;
}

const CreateModal: React.FC<CreateModalProps> = ({ onCreate }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentData, setDocumentData] = useState<FormData | null>(null);
  const [thumbnailData, setThumbnailData] = useState<FormData | null>(null);
  const [documentTitle, setDocumentTitle] = useState('Document');
  const [thumbnailTitle, setThumbnailTitle] = useState('Thumbnail');
  const isAdmin = useMemo(() => user.role === 'admin', [user.role]);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDocumentUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>, inputName: string) => {
      if (e.target.files) {
        const data = new FormData();
        const file = e.target.files[0];
        data.append('file', file);
        if (inputName === 'document') {
          setDocumentData(data);
          setDocumentTitle(`Document: ${file.name}`);
        } else {
          setThumbnailData(data);
          setThumbnailTitle(`Thumbnail: ${file.name}`);
        }
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    async (data: CreateResourceFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          group: Yup.string().required('Group is Required'),
          title: Yup.string().min(3, 'Title must be longer than 3 characters'),
          link: Yup.string(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const resource = {
          group: data.group,
          title: data.title,
        };

        // UPLOAD CONTENT
        let documentName = '';
        if (documentData) {
          const response = await api.post<DocumentUploadProps>(
            '/documents/shared-resources',
            documentData,
          );
          documentName = response.data.name;
          const ext = documentName.substr(documentName.lastIndexOf('.') + 1);
          Object.assign(resource, {
            document: documentName,
            type: getTypeFromExtension(ext),
          });
        }

        // UPLOAD THUMBNAIL
        let thumbName = '';
        if (thumbnailData) {
          const response = await api.post<DocumentUploadProps>(
            '/documents/thumbnails',
            thumbnailData,
          );
          thumbName = response.data.name;
          Object.assign(resource, { thumbnail: thumbName });
        }

        if (data.link) {
          Object.assign(resource, {
            link: data.link,
            type: 'html',
          });
        }

        // CREATE CONTENT
        await api.post('/shared-resources', resource);

        if (isAdmin) {
          addToast({
            type: 'success',
            title: 'Creation Success',
            description: `Resource is available to all clients`, // 'Your content is waiting for Admin approval.',
          });
        } else {
          addToast({
            type: 'success',
            title: 'Creation Success',
            description: 'Your content is waiting for Admin approval.',
          });
        }
        onCreate();
        handleToggle();
        setIsLoading(false);
        setDocumentData(null);
        setThumbnailData(null);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, isAdmin, documentData, thumbnailData, handleToggle, onCreate],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={30} color="#ffffff" />
      </CreateNew>
      <Modal isOpen={isOpen} handleToggle={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Create Resource</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <div>
              <Input name="group" placeholder="Group" />
              <Input name="title" placeholder="Title" />
              <Files>
                <FileInput
                  id="document"
                  name="document"
                  placeholder={documentTitle}
                  onChange={(e) => handleDocumentUpload(e, 'document')}
                />
                <FileInput
                  id="thumbnail"
                  name="thumbnail"
                  placeholder={thumbnailTitle}
                  onChange={(e) => handleDocumentUpload(e, 'thumbnail')}
                />
              </Files>
              <Input name="link" placeholder="Link" />
            </div>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Uploading...' : 'Create'}
            </SubmitButton>
          </Form>
        </Content>
      </Modal>
    </Container>
  );
};

export default CreateModal;
