import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--light-background);
  border-radius: 5px;

  margin-right: 10px;
  margin-top: 10px;
  align-items: center;

  span {
    font-size: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    padding-top: 5px !important;
  }

  strong {
    font-size: 14px;
    font-weight: bold;
    color: var(--blue);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px 8px 0px;
  }
`;
