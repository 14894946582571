import styled from 'styled-components';
import Tooltip from '../Tooltip';

export const Container = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    background: var(--primary);
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      background-image: linear-gradient(
        61deg,
        var(--primary),
        var(--white) 249%
      );
    }

    @media (min-width: 1000px) {
      width: 30px;
      height: 30px;
    }

    strong {
      font-family: SofiaProLight;
      font-size: 14px;
      color: var(--light-gray);
    }
    svg {
      width: 13px;
      height: 13px;
      color: var(--white);
      @media (min-width: 1000px) {
        width: 18px;
        height: 18px;
      }
    }
    input {
      display: none;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin-right: 0;
  }
  span {
    background: var(--error);
    color: var(--white);
    &::before {
      border-color: var(--error) transparent;
    }
  }
`;
