import styled from 'styled-components';

export const Container = styled.div``;

export const CardList = styled.div`
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px 10px;
`;

export const ResourceCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  header {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
  }

  footer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    padding: 0 8px;
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;

    strong {
      font-size: 12px;
      color: var(--gray);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    svg {
      color: var(--primary);
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      margin-left: 8px;
    }
  }
`;

export const ApprovalFlag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  svg {
    color: var(--primary-background);
    margin-right: 5px;
  }
  strong {
    font-size: 10px;
    color: var(--primary-background);
  }
  @media (min-width: 600px) {
    strong {
      font-size: 10px;
    }
  }
`;
export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;
