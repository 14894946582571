import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Content, Active, InActive } from './styles';
import ClientCard from '../../../components/ClientCard';
import api from '../../../services/api';
import NavigationHeader from '../../../components/NavigationHeader';
import CreateModal from './CreateModal';
import Client from '../../../entities/Client';
import { useAuth } from '../../../hooks/Auth';

const Clients: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const { user } = useAuth();
  const history = useHistory();
  const fetchClients = useCallback(async () => {
    const response = await api.get<Client[]>('/admin-clients');
    setClients(
      response.data.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
      ),
    );
  }, []);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const handleOnCreate = useCallback(
    (client: Client) => {
      Object.assign(client, {
        user,
      });
      setClients([...clients, client]);
    },
    [clients, user],
  );

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Clients</span>
        </strong>
        <CreateModal onCreate={(client: Client) => handleOnCreate(client)} />
      </NavigationHeader>

      <Active>
        <strong>Active clients</strong>
        <Content>
          {clients
            .filter((client) => client.active)
            .map((client) => (
              <ClientCard
                key={client.id}
                user={client}
                owner={client.user !== undefined ? client.user.name : undefined}
                redirect_to={`/admin-client-profile/${client.id}`}
              />
            ))}
        </Content>
      </Active>
      <InActive>
        <strong>Inactive clients</strong>
        <Content>
          {clients
            .filter((client) => !client.active)
            .map((client) => (
              <ClientCard
                key={client.id}
                user={client}
                owner={client.user !== undefined ? client.user.name : undefined}
                redirect_to={`/admin-client-profile/${client.id}`}
              />
            ))}
        </Content>
      </InActive>
    </Container>
  );
};

export default Clients;
