import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import NavigationHeader from '../../../components/NavigationHeader';
import DetailedNutrition from '../../../entities/DetailedNutrition';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';

import {
  Container,
  Content,
  Header,
  Body,
  Current,
  List,
  Card,
  CardBody,
  CardFooter,
} from './styles';

const PastDetailedNutritions: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [detailedNutritions, setDetailedNutritions] = useState<
    DetailedNutrition[]
  >([]);
  const currentDetailedNutrition = useMemo(() => {
    return detailedNutritions.find(
      (detailedNutrition) => detailedNutrition.active === true,
    );
  }, [detailedNutritions]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPastDetailedNutritions = useCallback(async () => {
    try {
      const response = await api.get<DetailedNutrition[]>(
        `detailed-nutritions/`,
      );
      setDetailedNutritions(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not fetch Nutrition Plan',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    fetchPastDetailedNutritions();
  }, [fetchPastDetailedNutritions]);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button
            type="button"
            onClick={() => history.push('/client-nutrition')}
          >
            Nutrition /
          </button>
          <span>Past Detailed Nutrition</span>
        </strong>
      </NavigationHeader>
      {isLoading ? (
        <Loading />
      ) : (
        <Content>
          <Header>
            <h3>Detailed Nutrition</h3>
          </Header>
          <Body>
            <h3>Current</h3>
            {currentDetailedNutrition && (
              <Current>
                <Card key={currentDetailedNutrition.id}>
                  <CardBody
                    href={currentDetailedNutrition.document_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://feed-fuel-perform.s3.eu-west-2.amazonaws.com/shared/e16bd1f13421c3305e7c-header_img.png"
                      alt={currentDetailedNutrition.type}
                    />
                  </CardBody>
                  <CardFooter $isActive>
                    <strong>{currentDetailedNutrition.title}</strong>
                  </CardFooter>
                </Card>
              </Current>
            )}

            <h3>Past</h3>
            <List>
              {detailedNutritions.map((detailedNutrition) => (
                <Card key={detailedNutrition.id}>
                  <CardBody
                    href={detailedNutrition.document_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://feed-fuel-perform.s3.eu-west-2.amazonaws.com/shared/e16bd1f13421c3305e7c-header_img.png"
                      alt={detailedNutrition.type}
                    />
                  </CardBody>
                  <CardFooter $isActive={false}>
                    <strong>{detailedNutrition.title}</strong>
                  </CardFooter>
                </Card>
              ))}
            </List>
          </Body>
        </Content>
      )}
    </Container>
  );
};

export default PastDetailedNutritions;
