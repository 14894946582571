import styled from 'styled-components';
import Button from '../Button';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  span {
    font-size: 12px;
    color: var(--gray);
    margin-right: 20px;
  }
`;

export const LoadMore = styled(Button)`
  width: auto;
  height: auto;
  font-size: 14px;
  padding: 5px 8px 5px 8px;
  margin: 0;

  :disabled {
    background: var(--light-gray);
  }
`;
