import { format } from 'date-fns';
import React from 'react';

import ChatRoom from '../../../../../entities/ChatRoom';

import {
  Container,
  ChatData,
  Avatar,
  Info,
  Separator,
  NotificationInfo,
  NotificationBadge,
} from './styles';

interface ChatItemProps {
  room: ChatRoom;
  has_unread: boolean;
  is_selected: boolean;
  handleChatSelection: (id: string) => void;
}
const ChatItem: React.FC<ChatItemProps> = ({
  room,
  is_selected,
  has_unread,
  handleChatSelection,
}) => {
  return (
    <>
      <Container
        $isActive={is_selected}
        onClick={() => handleChatSelection(room.id)}
      >
        <ChatData>
          <Avatar>
            <img src={room.client_avatar} alt={room.client_name} />
          </Avatar>
          <Info>
            <strong>{room.client_name}</strong>
            <NotificationInfo>
              <span>{format(new Date(room.last_message), 'HH:mm')}</span>
              <NotificationBadge $hasNotification={has_unread} />
            </NotificationInfo>
          </Info>
        </ChatData>
      </Container>
      <Separator />
    </>
  );
};

export default ChatItem;
