import React, { useCallback, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import Client from '../../entities/Client';
import KeyPrinciple from '../../entities/KeyPrinciple';
import CreateModal from './CreateModal';
import DeleteConfirmation from './DeleteConfirmation';
import EditModal from './EditModal';

import {
  Container,
  Header,
  Content,
  Row,
  RowHeader,
  RowBody,
  ActionButtons,
} from './styles';
import api from '../../services/api';

interface KeyPrincipleProps {
  key_principles: KeyPrinciple[];
  isAdmin: boolean;
  client: Client;
  handleRefreshKeyPrinciples?: (key_principle?: KeyPrinciple) => void;
}

const KeyPrincipleSection: React.FC<KeyPrincipleProps> = ({
  key_principles,
  client,
  isAdmin,
  handleRefreshKeyPrinciples,
}) => {
  const handleRemoveKeyPrinciple = useCallback(
    (id) => {
      api.delete(`key-principles/${id}`).then(() => {
        if (handleRefreshKeyPrinciples) {
          handleRefreshKeyPrinciples();
        }
      });
    },
    [handleRefreshKeyPrinciples],
  );
  const handleOnCreate = useCallback(
    (key_principle: KeyPrinciple) => {
      if (handleRefreshKeyPrinciples) {
        handleRefreshKeyPrinciples(key_principle);
      }
    },
    [handleRefreshKeyPrinciples],
  );

  const handleOnEdit = useCallback(() => {
    if (handleRefreshKeyPrinciples) {
      handleRefreshKeyPrinciples();
    }
  }, [handleRefreshKeyPrinciples]);

  const [showDetails, setShowDetails] = useState<string[]>([]);
  const handleToggleDescription = useCallback(
    (forId: string) => {
      if (showDetails.indexOf(forId) !== -1) {
        setShowDetails(showDetails.filter((principle) => principle !== forId));
      } else {
        setShowDetails([...showDetails, forId]);
      }
    },
    [showDetails],
  );

  function shouldDisplayDetails(for_id: string): boolean {
    return showDetails.find((mailPlan) => mailPlan === for_id) !== undefined;
  }
  return (
    <Container>
      <Header>
        <h3>Key Principles</h3>
        {isAdmin && (
          <CreateModal client_id={client.id} onCreate={handleOnCreate} />
        )}
      </Header>
      <Content>
        {key_principles.map((principle) => (
          <Row
            key={principle.id}
            onClick={() => handleToggleDescription(principle.id)}
          >
            <RowHeader $shouldShowDetails={shouldDisplayDetails(principle.id)}>
              <div className="row-header-info">
                <span>Principle</span>
                <strong>{principle.principle}</strong>
              </div>
              <div />
              <IoIosArrowForward size={26} />
            </RowHeader>
            <RowBody $shouldShowDetails={shouldDisplayDetails(principle.id)}>
              <div className="column top">
                <strong className="content-title">Why</strong>
                <span>{principle.why}</span>
              </div>
              {isAdmin && (
                <ActionButtons>
                  <EditModal principle={principle} onEdit={handleOnEdit} />

                  <DeleteConfirmation
                    id={principle.id}
                    principle={principle.principle}
                    onDelete={(id: string) => handleRemoveKeyPrinciple(id)}
                  />
                </ActionButtons>
              )}
            </RowBody>
          </Row>
        ))}
      </Content>
    </Container>
  );
};

export default KeyPrincipleSection;
