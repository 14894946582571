import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px 30px;

  .wrapper-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`;

export const UserPath = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  strong {
    color: var(--super-light-gray);
    font-family: SofiaProLight;
    span {
      color: var(--primary);
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 15px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px;

  @media (min-width: 600px) {
    width: 60%;
  }
`;

const contentCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  height: 100%;
  padding: 30px;
  strong {
    color: var(--white);
    font-size: 16px;
    margin-top: 17px;
  }

  img {
    width: 40px;
  }

  @media (min-width: 850px) {
    strong {
      font-size: 15px;
    }
    img {
      width: 40px;
    }
  }
`;
export const Goals = styled.div`
  background-image: linear-gradient(62deg, #5aa6ff, #ffffff 290%);
  ${contentCSS}
`;

export const Nutrition = styled.div`
  background-image: linear-gradient(64deg, #6d3795, #ffffff 209%);
  ${contentCSS}
`;

export const Chat = styled.div`
  background-image: linear-gradient(67deg, #ffc46f, #ffffff 344%);
  ${contentCSS}
`;

export const Resources = styled.div`
  background-image: linear-gradient(64deg, #ff778e, #ffffff 209%);
  ${contentCSS}
`;
