import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Nutrition from '../../entities/Nutrition';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import Loading from '../Loading';
import NavigationHeader from '../NavigationHeader';

import {
  Container,
  Content,
  ClientName,
  Card,
  CardContent,
  CardFooter,
} from './styles';

interface PastNutritionParams {
  id: string;
}

interface PastNutritionStateProps {
  client_name: string;
}

const PastNutritions: React.FC = () => {
  const { id } = useParams<PastNutritionParams>();
  const { state } = useLocation<PastNutritionStateProps>();
  const { addToast } = useToast();
  const history = useHistory();
  const [nutritions, setNutritions] = useState<Nutrition[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchPastNutrition = useCallback(async () => {
    try {
      const response = await api.get<Nutrition[]>(`/nutritions-client/${id}`);
      setNutritions(response.data);
      setIsLoading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not load past Nutrition',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    }
  }, [addToast, id]);

  useEffect(() => {
    fetchPastNutrition();
  }, [fetchPastNutrition]);
  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button type="button" onClick={() => history.push('/admin-clients')}>
            Clients /
          </button>
          <button type="button" onClick={() => history.goBack()}>
            Profile /
          </button>
          <span>Past Nutrition</span>
        </strong>
      </NavigationHeader>
      <ClientName>
        <strong>
          Past nutrition for: <span>{state.client_name}</span>
        </strong>
      </ClientName>
      {isLoading ? (
        <Content>
          <Loading />
        </Content>
      ) : (
        <Content>
          {nutritions.map((nutrition) => (
            <Card key={nutrition.id}>
              <CardContent>
                <h5>{nutrition.title}</h5>
                {nutrition.active && (
                  <>
                    <strong>Available on:</strong>
                    <span>
                      {format(new Date(nutrition.start_date), 'dd/MM/yyyy')}
                    </span>
                  </>
                )}
              </CardContent>
              <CardFooter $isActive={nutrition.active}>
                <Link
                  to={{
                    pathname: `/admin-client/nutrition/${nutrition.id}`,
                    state: {
                      returnToProfile: false,
                      client_name: state.client_name,
                      nutrition,
                      available_at: new Date(nutrition.start_date),
                    },
                  }}
                >
                  Details
                </Link>
              </CardFooter>
            </Card>
          ))}
        </Content>
      )}
    </Container>
  );
};

export default PastNutritions;
