import React from 'react';
import { Link } from 'react-router-dom';
import DetailedNutrition from '../../../../entities/DetailedNutrition';

import {
  Container,
  Header,
  Content,
  Card,
  CardBody,
  CardFooter,
  Footer,
} from './styles';

interface DetailedNutritionProps {
  detailed_nutrition: DetailedNutrition;
}

const DetailedNutritionComponent: React.FC<DetailedNutritionProps> = ({
  detailed_nutrition,
}) => {
  return (
    <Container>
      <Header>
        <h3>Detailed Nutrition</h3>
      </Header>
      <Content>
        <Card key={detailed_nutrition.id}>
          <CardBody
            href={detailed_nutrition.document_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://feed-fuel-perform.s3.eu-west-2.amazonaws.com/shared/e16bd1f13421c3305e7c-header_img.png"
              alt={detailed_nutrition.type}
            />
          </CardBody>
          <CardFooter>
            <strong>Download</strong>
          </CardFooter>
        </Card>
      </Content>
      <Footer>
        <Link to="/past-detailed-nutritions">
          <span>View Past</span>
        </Link>
      </Footer>
    </Container>
  );
};

export default DetailedNutritionComponent;
