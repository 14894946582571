import React from 'react';

import { Container, ClientInfo, Data } from './styles';

interface HeaderProps {
  avatar: string;
  name: string;
  status: boolean;
}

const Header: React.FC<HeaderProps> = ({ avatar, name, status }) => {
  return (
    <Container>
      <ClientInfo $isOnline={status}>
        <img src={avatar} alt={name} />
        <Data>
          <strong>{name}</strong>
          <span>{status ? 'Online' : 'Offline'}</span>
        </Data>
      </ClientInfo>
    </Container>
  );
};

export default Header;
