import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { Container, Header, Content } from './styles';

import ActiveClients from './ActiveClients';
import DueRenew from './DueRenew';
import User from '../../../entities/User';

interface Dashboard {
  clientsDueRenewal: User[];
  totalActiveClients: number;
}

const Dashboard: React.FC = () => {
  const [dashboard, setDashboard] = useState<Dashboard>({} as Dashboard);

  useEffect(() => {
    api.get('/admin-dashboard').then((response) => {
      setDashboard(response.data);
    });
  }, []);
  return (
    <Container>
      <Header>
        <strong>Dashboard</strong>
        <ActiveClients total_clients={dashboard.totalActiveClients} />
      </Header>
      <Content>
        {dashboard.clientsDueRenewal &&
          dashboard.clientsDueRenewal.length > 0 && (
            <DueRenew users={dashboard.clientsDueRenewal} />
          )}
      </Content>
    </Container>
  );
};

export default Dashboard;
