import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import NavigationHeader from '../../../components/NavigationHeader';
import { Section, Dictionary } from '../../../entities/SharedResources';
import api from '../../../services/api';
import getItemsForListFromDictionary from '../../../utils/getItemsForListFromDictionary';
import Loading from '../../../components/Loading';
import MainRow from './MainRow';

const AllResources: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState<Section[] | undefined>();
  const history = useHistory();

  const handleSortForList = useCallback((data: Dictionary) => {
    const titleList: string[] = Object.entries(data)
      .map(([key]) => {
        return key;
      })
      .sort();

    const list: Section[] = [];
    titleList.forEach((title) => {
      list.push({
        title,
        resources: getItemsForListFromDictionary(title, data),
      });
    });

    setSections(list);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    api.get('shared-resources/').then((response) => {
      handleSortForList(response.data);
    });
  }, [handleSortForList]);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.goBack()}>
            Dashboard /
          </button>
          <span>All resources</span>
        </strong>
      </NavigationHeader>
      {isLoading ? (
        <Loading />
      ) : (
        sections?.map((item) => <MainRow key={item.title} section={item} />)
      )}
    </Container>
  );
};

export default AllResources;
