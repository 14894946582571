import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  width: 100%;
  min-width: 310px;
`;

export const Content = styled.div`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
`;
export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  color: var(--gray);
  background: var(--light-background);
  width: 100%;
  h3 {
    font-size: 14px;
    font-weight: bold;
    padding: 8px 0;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--list-background);
`;
