import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiCheck, FiX } from 'react-icons/fi';

import {
  Container,
  Row,
  Content,
  Info,
  Actions,
  Approve,
  Reject,
} from './styles';
import { Resource } from '../../../../entities/SharedResources';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import Loading from '../../../../components/Loading';
import NavigationHeader from '../../../../components/NavigationHeader';

interface CurationProps {
  resources: Resource[];
  onUpdate: () => void;
}
const Curation: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [resources, setResources] = useState([] as Resource[]);
  const [isLoading, setIsLoading] = useState(false);
  const handleUpdate = useCallback(
    (id: string, status: string) => {
      api
        .patch(`shared-resources/curation/${id}`, { status })
        .then((response) => {
          if (response.status === 200) {
            addToast({
              type: 'success',
              title: 'Resource Updated',
            });
            setResources(resources.filter((resource) => resource.id !== id));
          } else {
            addToast({
              type: 'error',
              title: 'Could not Update Resource',
            });
          }
        });
    },
    [addToast, resources],
  );

  useEffect(() => {
    setIsLoading(true);
    api.get<Resource[]>('shared-resources/curation').then((response) => {
      setResources(response.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard
          </button>
          <button
            type="button"
            onClick={() => history.push('/admin-resources')}
          >
            Resources
          </button>
          <span>Curation</span>
        </strong>
      </NavigationHeader>
      {isLoading ? (
        <Loading />
      ) : (
        resources.map((item) => (
          <Row key={item.id}>
            <img src={item.thumbnail_url} alt="" />
            <Content>
              <a
                key={item.id}
                href={item.type === 'html' ? item.link : item.document_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Info>
                  <strong>{item.title}</strong>
                  <span>
                    {item.group} - {item.type}
                  </span>
                </Info>
              </a>
              <Actions>
                <Approve onClick={() => handleUpdate(item.id, 'approved')}>
                  <FiCheck />
                </Approve>
                <Reject onClick={() => handleUpdate(item.id, 'rejected')}>
                  <FiX />
                </Reject>
              </Actions>
            </Content>
          </Row>
        ))
      )}
    </Container>
  );
};

export default Curation;
