import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import sideImage from '../../assets/images/login-side.png';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;

  @media (max-width: 1000px) {
    padding: 0 20px;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const LoginCard = styled.div`
  height: 598px;
  width: 100%;
  max-width: 952px;
  background: #ffffff;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;

  @media (max-width: 600px) {
    width: 100%;
    height: 100vh;
    margin: 0px;
    border-radius: 0;
  }

  @media (max-width: 971px) {
    width: 100%;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  animation: ${appearFromRight} 1s;

  @media (max-width: 890px) {
    flex: 1;
    max-width: 100%;
  }

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;
    @media (max-width: 400px) {
      padding: 30px;
    }
    h1 {
      color: #4fc5c9;
      margin-bottom: 24px;
      font-size: 27px;
    }

    > a {
      color: #707070;
      text-decoration: none;
      display: block;
      margin-top: 15px;
      transition: color 0.2s;
      font-size: 12px;
      &:hover {
        color: ${shade(0.2, '#707070')};
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  width: 100%;
  max-width: 660px;
  background: #ffffff;
  align-items: center;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${sideImage}) no-repeat, center;
  background-size: cover;

  @media (max-width: 890px) {
    background: none;
    width: 0px;
    flex: 0;
  }
`;
