import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Photo from '../../entities/Photo';
import { useAuth } from '../../hooks/Auth';
import api from '../../services/api';
import PhotoSection from '../PhotoSection';
import AddPhotoModal from './AddPhotoModal';

import { Container, Header, Footer, LoadMore } from './styles';

interface PhotoResponse {
  photos: Photo[];
  current: number;
  total_pages: number;
}

interface ListPhotosParams {
  client_id: string;
}

const ClientPhotoList: React.FC = () => {
  const { type } = useAuth();
  const { client_id } = useParams<ListPhotosParams>();
  const [pastPhotos, setPastPhotos] = useState<Photo[]>([]);

  const [photoResponse, setPhotoResponse] = useState<PhotoResponse | undefined>(
    undefined,
  );

  const morePages = useMemo(() => {
    return photoResponse && photoResponse.current < photoResponse.total_pages;
  }, [photoResponse]);

  const handlePhotoAdded = useCallback(
    (photo: Photo) => {
      setPastPhotos(
        [...pastPhotos, photo].sort((a, b) => (b.date < a.date ? -1 : 1)),
      );
    },
    [pastPhotos],
  );

  const handleOnDelete = useCallback(
    async (id: string) => {
      await api.delete(`photos/${id}`);
      setPastPhotos(pastPhotos.filter((photo) => photo.id !== id));
    },
    [pastPhotos],
  );

  const fetchPhotos = useCallback(async () => {
    const response = await api.get<PhotoResponse>(
      `/client-photos/${client_id}`,
      {
        params: { page: 1 },
      },
    );
    setPastPhotos(response.data.photos);
    setPhotoResponse(response.data);
  }, [client_id]);

  const handleLoadMore = useCallback(async () => {
    if (photoResponse) {
      const response = await api.get<PhotoResponse>(
        `/client-photos/${client_id}`,
        {
          params: { page: photoResponse.current + 1 },
        },
      );

      setPastPhotos([...pastPhotos, ...response.data.photos]);
      setPhotoResponse(response.data);
    }
  }, [photoResponse, client_id, pastPhotos]);

  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      {type === 'client' && (
        <Header>
          <AddPhotoModal onCreate={handlePhotoAdded} />
        </Header>
      )}

      {pastPhotos.map((photo) => (
        <PhotoSection
          key={photo.id}
          id={photo.id}
          client_id={client_id}
          title={format(new Date(photo.date), 'PPPP')}
          show_past_photos={false}
          show_delete_photo={type === 'client'}
          photo={photo}
          onDelete={handleOnDelete}
        />
      ))}
      <Footer>
        <LoadMore disabled={!morePages} onClick={handleLoadMore}>
          Load more
        </LoadMore>
      </Footer>
    </Container>
  );
};

export default ClientPhotoList;
