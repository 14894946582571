import React, { useRef, useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiLock } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import {
  Container,
  LoginCard,
  AnimationContainer,
  Content,
  Background,
} from './styles';

import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().min(4),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Password does not match ',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const { password, password_confirmation } = data;
        const token = location.search.replace('?token=', '');
        if (!token) {
          throw new Error();
        }
        await api.post('/passwords/reset', {
          password,
          password_confirmation,
          token,
        });

        addToast({
          type: 'success',
          title: 'Password Reset',
          description: 'Your password has been reset',
        });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Password Reset failed',
          description: 'Could not reset your password, try again later.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, location.search],
  );
  return (
    <Container>
      <LoginCard>
        <Background />
        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Forgot Password</h1>
              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder="New Password"
              />
              <Input
                name="password_confirmation"
                icon={FiLock}
                type="password"
                placeholder="Confirm Password"
              />

              <Button type="submit" loading={loading}>
                Reset Password
              </Button>

              <Link to="/">
                Remember you password? <strong>Click here for Sign in.</strong>
              </Link>
            </Form>
          </AnimationContainer>
        </Content>
      </LoginCard>
    </Container>
  );
};

export default ResetPassword;
