import { format } from 'date-fns';
import React, { useState, useCallback } from 'react';
import { RiCalendarLine } from 'react-icons/ri';
import Calendar from '../../../../../components/Calendar';
import {
  Container,
  StyledModal,
  UpdateCalendar,
  Content,
  Remind,
  RemindOptions,
  Actions,
  SaveButton,
  Cancel,
} from './styles';

interface CalendarPopupProps {
  selected_date: Date;
  onSave: (date: Date) => void;
}

const CalendarPopup: React.FC<CalendarPopupProps> = ({
  selected_date,
  onSave,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(selected_date);
  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleUpdate = useCallback(() => {
    onSave(selectedDate);
    handleToggle();
  }, [handleToggle, onSave, selectedDate]);

  const handleDateChanged = useCallback((day: Date) => {
    setSelectedDate(day);
  }, []);

  return (
    <Container>
      <UpdateCalendar onClick={handleToggle}>
        Update next reminder
        <RiCalendarLine size={16} />
      </UpdateCalendar>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <h1>When will be the next Reminder?</h1>
          <Remind>
            <RemindOptions>
              <Calendar
                onDayClick={handleDateChanged}
                startDate={selectedDate}
              />
              <span>
                Your next reminder will be on:{' '}
                <strong>{format(selectedDate, 'dd/MM/yyyy')}</strong>
              </span>
            </RemindOptions>
          </Remind>
          <Actions>
            <Cancel onClick={handleToggle}>Cancel</Cancel>
            <SaveButton onClick={handleUpdate}>Save</SaveButton>
          </Actions>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default CalendarPopup;
