import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import NavigationHeader from '../../../components/NavigationHeader';
import Nutrition from '../../../entities/Nutrition';
import { useAuth } from '../../../hooks/Auth';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';

import {
  Container,
  Content,
  Header,
  Body,
  List,
  Card,
  CardBody,
  CardFooter,
} from './styles';

const ClientPastNutritions: React.FC = () => {
  const history = useHistory();
  const { client } = useAuth();
  const { addToast } = useToast();
  const [nutritions, setNutritions] = useState<Nutrition[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentNutrition = useMemo(() => {
    return nutritions.find((nutrition) => nutrition.active === true);
  }, [nutritions]);
  const fetchPastNutritions = useCallback(async () => {
    try {
      const response = await api.get<Nutrition[]>(
        `nutritions-client/${client.id}`,
      );
      setNutritions(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not fetch Nutrition Plan',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast, client.id]);

  useEffect(() => {
    fetchPastNutritions();
  }, [fetchPastNutritions]);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button
            type="button"
            onClick={() => history.push('/client-nutrition')}
          >
            Nutrition /
          </button>
          <span>Past Nutrition</span>
        </strong>
      </NavigationHeader>
      {isLoading ? (
        <Loading />
      ) : (
        <Content>
          <Header>
            <h3>Nutrition</h3>
          </Header>
          <Body>
            {currentNutrition && (
              <>
                <h3>Current</h3>
                <Card key={currentNutrition.id}>
                  <CardBody to={`client-nutrition/${currentNutrition.id}`}>
                    <span>
                      Available:
                      <strong>
                        {format(
                          new Date(currentNutrition.start_date),
                          'dd/MM/yyyy',
                        )}
                      </strong>
                    </span>
                  </CardBody>
                  <CardFooter $isActive>
                    <strong>{currentNutrition.title}</strong>
                  </CardFooter>
                </Card>
              </>
            )}
            <>
              <h3>Past</h3>
              <List>
                {nutritions.map((nutrition) => (
                  <Card key={nutrition.id}>
                    <CardBody to={`client-nutrition/${nutrition.id}`}>
                      <span>
                        {format(new Date(nutrition.start_date), 'dd/MM/yyyy')}
                      </span>
                    </CardBody>
                    <CardFooter $isActive={false}>
                      <strong>{nutrition.title}</strong>
                    </CardFooter>
                  </Card>
                ))}
              </List>
            </>
          </Body>
        </Content>
      )}
    </Container>
  );
};

export default ClientPastNutritions;
