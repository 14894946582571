import styled from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
`;

export const ReadCheckIn = styled(Button)`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  a {
    width: 100%;
    height: 100%;
    display: flex;

    align-items: center;
    span {
      font-size: 14px;
      color: var(--white);
      margin-right: 10px;
    }
  }
  @media (min-width: 415px) {
    margin-top: 0px;
    margin-left: 10px;
  }
`;
export const CaseNotes = styled(Button)`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  a {
    width: 100%;
    height: 100%;
    display: flex;

    align-items: center;
    span {
      font-size: 14px;
      color: var(--white);
      margin-right: 10px;
    }
  }

  @media (min-width: 614px) {
    margin-top: 0px;
    margin-left: 10px;
  }
`;

export const Account = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionButtons = styled.div`
  display: flex;
`;

export const CloseAccountButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--error);
  width: auto;
  margin-top: 10px;

  span {
    font-size: 14px;
    color: var(--white);
    margin-right: 10px;
  }

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(61deg, var(--error), var(--white) 249%);
  }
`;
