import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 8px;
  overflow: hidden;
  background: var(--list-background);
  padding-bottom: 10px;
  margin-top: 10px;
  @media (min-width: 700px) {
    width: 230px;
    margin-top: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: var(--gray);
  background: var(--light-background);
  width: 100%;
  h3 {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const Card = styled.div`
  width: 100%;
  min-height: 100px;
  max-width: 130px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  margin-right: 10px;

  @media (min-width: 1025px) {
    margin-right: 0;
    max-width: 100%;
  }

  @media (max-width: 350px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

export const CardBody = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 70px;
  background: var(--light-background);
  img {
    width: 100%;
    object-fit: cover;
    height: 100px;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 8px 0;
  align-items: center;

  strong {
    font-size: 10px;
    font-weight: bold;
  }
`;

export const Footer = styled.div`
  width: 100%;
  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 12px;
    }
  }
`;
