import styled from 'styled-components';
import Button from '../../../components/Button';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  form {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
`;

export const ClientName = styled.div`
  strong {
    font-size: 14px;
    color: var(--gray);
    span {
      color: var(--primary);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  strong {
    color: var(--primary);
    font-weight: bold;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 0;
  height: 30px;
  max-width: 100px;
  font-size: 14px;
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;

export const Separator = styled.div`
  border: 1px solid var(--light-background);
`;
