import React, { useState, useCallback, useRef } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { format } from 'date-fns';
import * as Yup from 'yup';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import {
  Container,
  CreateNew,
  Header,
  Content,
  Remind,
  RemindOptions,
  CloseButton,
  SubmitButton,
} from './styles';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import Client from '../../../../entities/Client';
import Modal from '../../../../components/Modal';
import Calendar from '../../../../components/Calendar';

interface CreateClientFormData {
  name: string;
  email: string;
  next_reminder: string;
}

interface CreateModalProps {
  onCreate: (client: Client) => void;
}

const CreateModal: React.FC<CreateModalProps> = ({ onCreate }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: CreateClientFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().min(4, 'Name is Required'),
          email: Yup.string()
            .email('Invalid email')
            .required('Email is Required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const clientData = {
          name: data.name,
          email: data.email,
          next_reminder: format(selectedDate, 'yyyy-MM-dd'),
          password: 'ffp2020',
        };

        // CREATE CONTENT
        const response = await api.post<Client>('/clients', clientData);

        addToast({
          type: 'success',
          title: 'Creation Success',
          description: `Client was added to your list`, // 'Your content is waiting for Admin approval.',
        });
        onCreate(response.data);
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, handleToggle, onCreate, selectedDate],
  );

  const handleDateChanged = useCallback((day: Date) => {
    setSelectedDate(day);
  }, []);

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={30} color="#ffffff" />
      </CreateNew>
      <Modal isOpen={isOpen} handleToggle={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Create Client</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <div>
              <Input name="name" placeholder="Name" />
              <Input name="email" placeholder="Email" />
              <Remind>
                <h3>Remind me</h3>
                <RemindOptions>
                  <Calendar onDayClick={handleDateChanged} />
                  <span>
                    Your next reminder will be on:{' '}
                    <strong>{format(selectedDate, 'dd/MM/yyyy')}</strong>
                  </span>
                </RemindOptions>
              </Remind>
            </div>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Creating...' : 'Create'}
            </SubmitButton>
          </Form>
        </Content>
      </Modal>
    </Container>
  );
};

export default CreateModal;
