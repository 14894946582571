import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Container, Content, Profile, ProfileText, Avatar } from './styles';
import { useAuth } from '../../hooks/Auth';
import logo from '../../assets/images/ffp_logo.png';
import { useChat } from '../../hooks/Chat';

const Header: React.FC = () => {
  const { user, client, signOut } = useAuth();
  const { closeSocket } = useChat();
  const isAdmin = useMemo(() => user.role !== undefined && user.role !== '', [
    user.role,
  ]);
  const handleSignOut = useCallback(() => {
    closeSocket();
    signOut();
  }, [closeSocket, signOut]);

  return (
    <Container>
      <Content>
        <Link to="/">
          <img src={logo} alt="Feed.Fuel.Perform" />
        </Link>
        <Profile>
          <ProfileText>
            <strong>Hi, {isAdmin ? user.name : client.name}</strong>
            <button type="button" onClick={handleSignOut}>
              Logout
            </button>
          </ProfileText>
          {isAdmin ? (
            <Link to="/admin-profile">
              <Avatar>
                <img className="avatar" src={user.avatar_url} alt="" />
                <div className="overlay" />
              </Avatar>
            </Link>
          ) : (
            <Link to="/profile">
              <Avatar>
                <img className="avatar" src={client.avatar_url} alt="" />
                <div className="overlay" />
              </Avatar>
            </Link>
          )}
        </Profile>
      </Content>
    </Container>
  );
};

export default Header;
