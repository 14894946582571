import React from 'react';
import { Link } from 'react-router-dom';
import Recipe from '../../../../entities/Recipe';

import {
  Container,
  Header,
  Content,
  Card,
  CardBody,
  CardFooter,
  Footer,
} from './styles';

interface RecipeListProps {
  recipes: Recipe[];
}

const RecipeList: React.FC<RecipeListProps> = ({ recipes }) => {
  return (
    <Container>
      <Header>
        <h3>Recipes & Meal Plans</h3>
      </Header>
      <Content>
        {recipes.map((recipe) => (
          <Card key={recipe.id}>
            <CardBody
              href={recipe.recipe_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={recipe.thumbnail_url} alt={recipe.title} />
            </CardBody>
            <CardFooter>
              <strong>{recipe.title}</strong>
            </CardFooter>
          </Card>
        ))}
      </Content>
      <Footer>
        <Link to="/recipes-mealplan">
          <span>View All</span>
        </Link>
      </Footer>
    </Container>
  );
};

export default RecipeList;
