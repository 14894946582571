import React from 'react';

import { FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  Container,
  Avatar,
  Info,
  UserInfo,
  Icons,
  Separator,
  Owner,
} from './styles';

interface ClientCardProps {
  user: {
    id: string;
    name: string;
    email: string;
    avatar_url: string;
    active: boolean;
  };
  owner?: string;
  redirect_to: string;
}

const ClientCard: React.FC<ClientCardProps> = ({
  user,
  owner,
  redirect_to,
}) => {
  return (
    <Container $isActive={user.active}>
      <Link to={redirect_to}>
        <Avatar>
          <img src={user.avatar_url} alt={user.name} />
        </Avatar>
        <Info>
          <UserInfo>
            <strong>{user.name}</strong>
            <span>{user.email}</span>
            {owner && (
              <Owner>
                <Separator />
                <span>
                  Registered by: <strong>{owner}</strong>
                </span>
              </Owner>
            )}
          </UserInfo>
        </Info>
      </Link>
      <Icons $isActive={user.active}>
        <Link
          to={{
            pathname: `mail/${user.id}`,
            state: {
              mail_type: 'client',
              client: user,
            },
          }}
        >
          <FiMail size={20} />
        </Link>
      </Icons>
    </Container>
  );
};

export default ClientCard;
