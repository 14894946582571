import styled, { css } from 'styled-components';

interface MessageLineProps {
  $userSent: boolean;
}

export const Container = styled.div<MessageLineProps>`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  ${(props) =>
    props.$userSent &&
    css`
      justify-content: flex-end;
    `}
`;

export const MessageItem = styled.div<MessageLineProps>`
  background: var(--gray);
  border-radius: 10px;
  box-shadow: 0 1px 1px var(--super-light-gray);
  display: flex;
  flex-direction: column;
  padding: 3px;
  max-width: 60%;

  ${(props) =>
    props.$userSent &&
    css`
      background: var(--primary);
    `}
`;

export const MessageText = styled.div<MessageLineProps>`
  margin: 5px 40px 5px 5px;
  color: var(--white);
  display: flex;
  flex-direction: column;

  strong {
    font-size: 10px;
    color: var(--primary);
  }

  span {
    font-size: 13px;
  }

  ${(props) =>
    props.$userSent &&
    css`
      strong {
        display: none;
      }
    `}
`;

export const MessageDate = styled.div<MessageLineProps>`
  font-size: 8px;
  color: var(--super-light-gray);
  margin-right: 5px;
  text-align: right;
  height: 15px;
  ${(props) =>
    props.$userSent &&
    css`
      color: var(--white);
    `}
`;
