import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 8px;
  align-items: center;
  background: var(--light-background);
`;

export const Avatar = styled.div`
  img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    object-fit: cover;
  }

  margin-right: 10px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 12px;
    /* font-family: 'SofiaProRegular'; */
    font-weight: bold;
  }

  span {
    font-size: 10px;
  }
  margin-right: 10px;
`;
