import styled, { css } from 'styled-components';

interface ChatRoomProps {
  $isActive: boolean;
}

export const Container = styled.div<ChatRoomProps>`
  display: flex;
  flex-direction: column;
  background: var(--list-background);
  padding: 0 15px;

  align-items: center;
  justify-content: center;
  height: 70px;

  &:hover {
    background: var(--light-background);
  }
  cursor: pointer;

  ${(props) =>
    props.$isActive &&
    css`
      background: var(--light-background);
    `}
`;

export const ChatData = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;
    border: 2px solid transparent;
  }

  margin-right: 10px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  strong {
    font-size: 14px;
    color: var(--gray);
  }
`;

export const NotificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    font-size: 11px;
    color: var(--light-gray);
  }
`;

interface NotificationBadgeProps {
  $hasNotification: boolean;
}

export const NotificationBadge = styled.div<NotificationBadgeProps>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: transparent;
  margin-top: 8px;

  ${(props) =>
    props.$hasNotification &&
    css`
      background: var(--primary);
    `}
`;

export const Separator = styled.div`
  border: 1px solid var(--light-background);
  width: 100%;
`;
