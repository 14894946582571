import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  width: 100%;
`;

export const CheckboxItem = styled.div<ContainerProps>`
  background: rgba(193, 192, 192, 0.2);
  border-radius: 5px;
  height: 48px;

  padding: 16px;
  display: flex;
  align-items: center;

  color: rgba(112, 112, 112, 0.45);

  margin-top: 12px;

  border: 2px solid transparent;
  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border: solid 2px var(--error);
    `}

  strong {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin-right: 0;
  }
  span {
    background: var(--error);
    color: var(--white);
    &::before {
      border-color: var(--error) transparent;
    }
  }
`;
