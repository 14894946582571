import styled from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled.div`
  display: flex;
  width: 100%;

  form {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .form-input {
      width: 100%;
      margin-right: 10px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 0;
  height: 50px;
  width: 100px;
  margin-right: 10px;
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;

export const CancelButton = styled(Button)`
  margin-top: 0;
  height: 50px;
  width: 100px;
  background: var(--light-background);
  color: var(--primary);
  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(
      61deg,
      var(--light-background),
      var(--white) 249%
    );
  }
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;
