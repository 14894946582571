import React, { useState, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import { Container, SubmitButton, CancelButton } from './styles';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';
import Group from '../../../../entities/Groups';
import DeleteConfirmation from '../DeleteConfirmation';

interface EditGroupFormData {
  name: string;
}

interface EditFormProps {
  onEdit: (group: Group) => void;
  onClose: () => void;
  onDelete: (id: string) => void;
  group: Group;
}

const EditForm: React.FC<EditFormProps> = ({
  onEdit,
  onClose,
  onDelete,
  group,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: EditGroupFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().min(4, 'Name must contain at least 4 characters'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const groupData = {
          name: data.name,
        };

        // CREATE CONTENT
        const response = await api.put<Group>(`/groups/${group.id}`, groupData);

        addToast({
          type: 'success',
          title: 'Update Success',
          description: `Group was updated.`,
        });
        onEdit(response.data);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, onEdit, group.id],
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDelete = useCallback(
    (id) => {
      onDelete(id);
    },
    [onDelete],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="form-input">
          <Input name="name" placeholder="Name" defaultValue={group.name} />
        </div>

        <SubmitButton type="submit" disabled={isLoading}>
          {isLoading ? 'Editing...' : 'Save'}
        </SubmitButton>
        <DeleteConfirmation
          id={group.id}
          name={group.name}
          onDelete={(id: string) => handleDelete(id)}
        />
        <CancelButton type="button" disabled={isLoading} onClick={handleClose}>
          Cancel
        </CancelButton>
      </Form>
    </Container>
  );
};

export default EditForm;
