import React, { useState, useCallback, useRef } from 'react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format } from 'date-fns';
import getValidationErrors from '../../../utils/getValidationErrors';
import {
  Container,
  StyledModal,
  CreateNew,
  Header,
  Content,
  Timeline,
  TimelineOptions,
  CloseButton,
  SubmitButton,
} from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/Toast';
import Goal from '../../../entities/Goal';
import Calendar from '../../Calendar';
import Input from '../../Input';
import TextArea from '../../TextArea';

interface EditGoalFormData {
  title: string;
  monitoring: string;
  timeline: string;
}

interface EditModalProps {
  goal: Goal;
  onEdit: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ goal, onEdit }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(goal.timeline));

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: EditGoalFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is Required'),
          monitoring: Yup.string().required('Monitoring is Required'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const body = {
          title: data.title,
          monitoring: data.monitoring,
          timeline: format(selectedDate, 'yyyy-MM-dd'),
        };
        await api.put<Goal>(`goals/${goal.id}`, body);
        onEdit();
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Could not Edit Goal',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, handleToggle, onEdit, selectedDate, goal.id],
  );

  const handleDateChanged = useCallback((day: Date) => {
    setSelectedDate(day);
  }, []);

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiEdit size={14} color="#ffffff" />
      </CreateNew>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Edit Client Goal</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <Input name="title" placeholder="Title" defaultValue={goal.title} />
            <TextArea
              name="monitoring"
              placeholder="Monitoring"
              defaultValue={goal.monitoring}
            />
            <Timeline>
              <h3>Timeline</h3>
              <TimelineOptions>
                <Calendar
                  onDayClick={handleDateChanged}
                  startDate={new Date(goal.timeline)}
                />
                <span>
                  Your Timeline is set to be on:{' '}
                  <strong>{format(selectedDate, 'dd/MM/yyyy')}</strong>
                </span>
              </TimelineOptions>
            </Timeline>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </SubmitButton>
          </Form>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default EditModal;
