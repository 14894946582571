import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1600px;

  margin: 0 100px 0 100px;
  @media (min-height: 800px) {
    margin: 60px 100px 0 100px;
  }
  @media (max-width: 850px) {
    margin: 0;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex: 1 auto;

  background: var(--white);
  margin-left: 40px;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 850px) {
    margin-left: 0;
  }
`;
