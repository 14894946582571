import React from 'react';
import { ModalProvider } from 'styled-react-modal';
import { AuthProvider } from './Auth';
import { ChatProvider } from './Chat';
import { ToastProvider } from './Toast';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ModalProvider>
      <ToastProvider>
        <ChatProvider>{children}</ChatProvider>
      </ToastProvider>
    </ModalProvider>
  </AuthProvider>
);

export { AppProvider };
