import React from 'react';
import { FiUser } from 'react-icons/fi';
import { Container, Info, Icon } from './styles';

interface ActiveClientsProps {
  total_clients: number;
}

const ActiveClients: React.FC<ActiveClientsProps> = ({ total_clients }) => {
  return (
    <Container>
      <Info>
        <strong>{total_clients}</strong>
        <span>Active Users</span>
      </Info>
      <Icon>
        <FiUser size={30} color="#2c9ca0" />
      </Icon>
    </Container>
  );
};

export default ActiveClients;
