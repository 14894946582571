import React, { useState, useCallback, useRef } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format } from 'date-fns';
import getValidationErrors from '../../../utils/getValidationErrors';
import {
  Container,
  StyledModal,
  CreateNew,
  Header,
  Content,
  Timeline,
  TimelineOptions,
  CloseButton,
  SubmitButton,
} from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/Toast';
import Calendar from '../../Calendar';
import Input from '../../Input';
import Metric from '../../../entities/Metric';

interface CreateMetricFormData {
  value: string;
  type: string;
  date: string;
}

interface CreateModalProps {
  client_id: string;
  user_id?: string;
  metric: string;
  title?: string;
  onCreate: (metric: Metric) => void;
}

const CreateModal: React.FC<CreateModalProps> = ({
  client_id,
  user_id,
  metric,
  title,
  onCreate,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: CreateMetricFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          value: Yup.number()
            .typeError('Value must be a number')
            .required('Value is Required'),
          type: Yup.string().required('Measurement type is Required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          client_id,
          value: data.value,
          type: data.type,
          date: format(selectedDate, 'yyyy-MM-dd'),
        };

        if (user_id) {
          Object.assign(body, { user_id });
        }

        if (title) {
          Object.assign(body, { title });
        }

        const response = await api.post<Metric>(`metrics/${metric}`, body);
        onCreate(response.data);
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Metric was not created',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [
      addToast,
      handleToggle,
      onCreate,
      metric,
      selectedDate,
      client_id,
      user_id,
      title,
    ],
  );

  const handleDateChanged = useCallback((day: Date) => {
    setSelectedDate(day);
  }, []);

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={20} color="#ffffff" />
      </CreateNew>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Create Metric</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={20} color="#4fc5c9" />
              </CloseButton>
            </Header>

            <Input name="value" placeholder="Value" className="leftInput" />
            <Input name="type" placeholder="Measurement system" />
            <Timeline>
              <h3>Date</h3>
              <TimelineOptions>
                <Calendar onDayClick={handleDateChanged} toMonth={new Date()} />
              </TimelineOptions>
            </Timeline>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Adding...' : 'Add'}
            </SubmitButton>
          </Form>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default CreateModal;
