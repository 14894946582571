import React, { useMemo, useCallback } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useAuth } from '../../../../hooks/Auth';

import {
  CardList,
  ResourceCard,
  Container,
  ApprovalFlag,
  Actions,
} from './styles';
import { Section } from '../../../../entities/SharedResources';
import DeleteConfirmation from '../DeleteConfirmation';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';

interface ListProps {
  section: Section;
  onUpdate: () => void;
}
const List: React.FC<ListProps> = ({ section, onUpdate }) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const isAdmin = useMemo(() => user.role === 'admin', [user.role]);

  const handleDelete = useCallback(
    (id: string) => {
      api.delete(`shared-resources/${id}`).then((response) => {
        if (response.status === 200) {
          addToast({
            type: 'success',
            title: 'Resource Deleted',
          });
          onUpdate();
        } else {
          addToast({
            type: 'error',
            title: 'Could not Delete Resource',
          });
        }
      });
    },
    [onUpdate, addToast],
  );
  return (
    <Container>
      <header>{section.title}</header>
      <CardList>
        {section.resources.map((item) => (
          <ResourceCard>
            <header>
              {isAdmin && (
                <Actions>
                  <DeleteConfirmation
                    id={item.id}
                    thumbnail_url={item.thumbnail_url}
                    owner={item.user.name}
                    title={item.title}
                    group={item.group}
                    handleDelete={handleDelete}
                  />
                </Actions>
              )}

              {!isAdmin && item.status !== 'approved' && (
                <ApprovalFlag>
                  <FiAlertTriangle />
                  <strong>
                    {item.status === 'pending'
                      ? 'Pending Approval'
                      : 'Rejected'}
                  </strong>
                </ApprovalFlag>
              )}
            </header>
            <a
              key={item.id}
              href={item.document_url ? item.document_url : item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={item.thumbnail_url} alt="" />
              <footer>
                <strong>{item.title}</strong>
              </footer>
            </a>
          </ResourceCard>
        ))}
      </CardList>
    </Container>
  );
};

export default List;
