import styled from 'styled-components';

export const Container = styled.div`
  background: var(--light-background);
  border-radius: 15px;
  padding: 10px 15px;
  border: 1px solid var(--primary-background);

  display: flex;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  @media (min-width: 850px) {
    margin-right: 30px;
  }

  strong {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  span {
    font-size: 12px;
    font-family: SofiaProLight;
  }
`;

export const Icon = styled.div`
  background: var(--primary-background);
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
