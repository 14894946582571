import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  justify-content: space-between;
  height: 40px;

  .button {
    background: var(--primary);
    color: var(--white);
    border-radius: 5px;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 10px;
    }
  }

  strong {
    color: var(--super-light-gray);
    font-family: SofiaProLight;
    font-size: 8px;
    span {
      color: var(--white);
      background: var(--primary);
      padding: 8px 13px 9px 13px;
      border-radius: 5px;
      font-size: 8px;
    }
    button {
      color: var(--super-light-gray);
      font-family: SofiaProLight;
      margin-right: 8px;
      font-size: 8px;
    }

    @media (min-width: 350px) {
      font-size: 10px;
      span {
        font-size: 10px;
      }
      button {
        font-size: 10px;
      }
    }

    @media (min-width: 400px) {
      font-size: 16px;
      span {
        font-size: 16px;
      }
      button {
        font-size: 16px;
      }
    }
  }
`;
