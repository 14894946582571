import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .DayPicker {
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    background: var(--gray);
    border-radius: 10px;
  }
  .DayPicker {
    width: 100%;
  }
  @media (min-width: 400px) {
    .DayPicker {
      width: 50%;
    }
  }

  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-NavButton {
    top: 10px;
    margin-top: 0;
  }

  .DayPicker-NavButton--next {
    color: var(--white) !important;
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 1.5em;
    margin-right: 0;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 0;
    background-color: var(--light-background);
    border-radius: 0 0 10px 10px;
  }
  .DayPicker-Weekday {
    padding: 0;
    abbr {
      font-size: 9px;
    }
  }

  .DayPicker-Caption {
    color: var(--white) !important;
    margin-bottom: 0;
    padding: 10px 0;
    > div {
      text-align: center;
      font-size: 12px;
    }
  }

  .DayPicker-Day {
    width: 20px;
    height: 20px;
    font-size: 8px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: var(--gray);
    border-radius: 5px;
    color: var(--white);
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: var(--dark-primary);
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--selected {
    background: var(--primary) !important;
    border-radius: 10px;
    color: var(--white) !important;
  }
`;
