import React from 'react';
import DeleteStaffConfirmation from './DeleteConfirmation';

import { Container, Avatar, Info, UserInfo } from './styles';

interface UserCardProps {
  user: {
    id: string;
    name: string;
    email: string;
    avatar_url: string;
    role?: string;
  };
  handleOnDelete: (id: string) => void;
}

const UserCard: React.FC<UserCardProps> = ({ user, handleOnDelete }) => {
  return (
    <Container>
      <Avatar>
        <img src={user.avatar_url} alt="user profile" />
      </Avatar>
      <Info>
        <UserInfo>
          <strong>{user.name}</strong>
          <span>{user.email}</span>
          <span>{user.role}</span>
        </UserInfo>
        <DeleteStaffConfirmation
          id={user.id}
          name={user.name}
          onDelete={handleOnDelete}
        />
      </Info>
    </Container>
  );
};

export default UserCard;
