import { format } from 'date-fns/esm';
import React, { useState, useCallback } from 'react';
import { FiTrash } from 'react-icons/fi';
import {
  Container,
  StyledModal,
  Delete,
  Content,
  Actions,
  DeleteButton,
  Cancel,
} from './styles';

interface DeleteConfirmationProps {
  id: string;
  date: Date;
  onDelete?: (id: string) => void;
}

const DeletePhotoConfirmation: React.FC<DeleteConfirmationProps> = ({
  id,
  date,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(id);
    }
    handleToggle();
  }, [id, handleToggle, onDelete]);

  return (
    <Container>
      <Delete onClick={handleToggle}>
        <FiTrash size={14} />
      </Delete>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <h1>Are you sure you want to delete:</h1>
          <strong>Photos from: {format(new Date(date), 'PPPP')}</strong>
          <span>This will remove from your records.</span>
          <Actions>
            <Cancel onClick={handleToggle}>Cancel</Cancel>
            <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
          </Actions>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default DeletePhotoConfirmation;
