import { format } from 'date-fns';
import React, { useMemo } from 'react';
import ChatMessage from '../../../entities/ChatMessage';
import { useAuth } from '../../../hooks/Auth';

import { Container, MessageItem, MessageText, MessageDate } from './styles';

interface MessageProps {
  message: ChatMessage;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  const { user, client, type } = useAuth();
  const loggedInUser = useMemo(() => {
    return type === 'client' ? client : user;
  }, [client, user, type]);
  return (
    <Container $userSent={loggedInUser.id === message.sender_id}>
      <MessageItem $userSent={loggedInUser.id === message.sender_id}>
        <MessageText $userSent={loggedInUser.id === message.sender_id}>
          <strong>{message.sender_name}</strong>
          <span>{message.message}</span>
        </MessageText>
        <MessageDate $userSent={loggedInUser.id === message.sender_id}>
          {format(new Date(message.sent_at), 'dd/MM/yyyy HH:mm ')}
        </MessageDate>
      </MessageItem>
    </Container>
  );
};

export default Message;
