import React from 'react';

import { Container, ClientInfo } from './styles';

interface ChatWindowProps {
  avatar: string;
  name: string;
}
const ChatWindowHeader: React.FC<ChatWindowProps> = ({ avatar, name }) => {
  return (
    <Container>
      <ClientInfo>
        <img src={avatar} alt={name} />
        <strong>{name}</strong>
      </ClientInfo>
    </Container>
  );
};

export default ChatWindowHeader;
