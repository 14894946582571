import React from 'react';
import MealPlan from '../../../../entities/MealPlan';

import {
  Container,
  Header,
  Content,
  Card,
  CardBody,
  CardFooter,
} from './styles';

interface MealPlanListProps {
  meal_plans: MealPlan[];
}

const MealPlanList: React.FC<MealPlanListProps> = ({ meal_plans }) => {
  return (
    <Container>
      <Header>
        <strong>Meal Planner</strong>
      </Header>
      <Content>
        {meal_plans.map((meal_plan) => (
          <Card key={meal_plan.id}>
            <CardBody
              href={meal_plan.meal_planner_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://feed-fuel-perform.s3.eu-west-2.amazonaws.com/shared/e16bd1f13421c3305e7c-header_img.png"
                alt={meal_plan.title}
              />
            </CardBody>
            <CardFooter>
              <strong>{meal_plan.title}</strong>
            </CardFooter>
          </Card>
        ))}
      </Content>
    </Container>
  );
};

export default MealPlanList;
