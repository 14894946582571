import React, { useState, useCallback, useRef } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import {
  Container,
  CreateNew,
  Header,
  Content,
  CloseButton,
  SubmitButton,
} from './styles';
import DailyPlan from '../../../entities/DailyPlan';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import Modal from '../../Modal';
import Input from '../../Input';
import TextArea from '../../TextArea';

interface CreateDailyPlannerFormData {
  meal: string;
  timing: string;
  protein?: string;
  fats?: string;
  calories?: string;
  carbs?: string;
  explanation: string;
}

interface CreateModalProps {
  nutrition_id: string;
  onCreate: (daily_pan: DailyPlan) => void;
}

const CreateDailyPlanner: React.FC<CreateModalProps> = ({
  nutrition_id,
  onCreate,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: CreateDailyPlannerFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          meal: Yup.string().required('Meal is Required'),
          timing: Yup.string().required('Timing is Required'),
          protein: Yup.string().required('Protein is Required'),
          fats: Yup.string().required('Fats is Required'),
          carbs: Yup.string().required('Carbs is Required'),
          calories: Yup.string().required('Calories is Required'),
          explanation: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          nutrition_id,
          meal: data.meal,
          timing: data.timing,
          protein: data.protein,
          fats: data.fats,
          carbs: data.carbs,
          calories: data.calories,
          explanation: data.explanation,
        };

        // CREATE CONTENT
        const response = await api.post<DailyPlan>('/daily-plans', body);

        addToast({
          type: 'success',
          title: 'Creation Success',
        });
        onCreate(response.data);
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, handleToggle, onCreate, nutrition_id],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={30} color="#ffffff" />
      </CreateNew>
      <Modal isOpen={isOpen} handleToggle={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Create Daily Meal Plan</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <Input title="Meal" name="meal" placeholder="Meal" />
            <Input title="Timing" name="timing" placeholder="Timing" />
            <div className="row">
              <Input title="Protein" name="protein" placeholder="Protein (g)" />
              <Input title="Fats" name="fats" placeholder="Fats (g)" />
            </div>
            <div className="row">
              <Input title="Carbs" name="carbs" placeholder="Carbs (g)" />
              <Input
                title="Calories"
                name="calories"
                placeholder="Calories (kcal)"
              />
            </div>
            <strong>Explanation</strong>
            <TextArea name="explanation" placeholder="Explanation" />

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Creating...' : 'Create'}
            </SubmitButton>
          </Form>
        </Content>
      </Modal>
    </Container>
  );
};

export default CreateDailyPlanner;
