import React from 'react';
import AddPhotoModal from '../../../../components/ClientPhotoList/AddPhotoModal';
import Photo from '../../../../entities/Photo';

import { Container, Header } from './styles';

interface AddPhotoHeder {
  title: string;
  onCreate: (photo: Photo) => void;
}
const AddPhotoHeader: React.FC<AddPhotoHeder> = ({ title, onCreate }) => {
  return (
    <Container>
      <Header>
        <h3>{title}</h3>
        <AddPhotoModal onCreate={onCreate} />
      </Header>
    </Container>
  );
};

export default AddPhotoHeader;
