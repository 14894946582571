import React from 'react';
import { Link } from 'react-router-dom';
import Photo from '../../entities/Photo';
import ExpandedPhotoModal from '../ExpandedPhotoModal';
import DeletePhotoConfirmation from './DeletePhotoConfirmation';

import { Container, Header, Content } from './styles';

interface PhotoSectionProps {
  id: string;
  client_id: string;
  title: string;
  show_past_photos: boolean;
  show_delete_photo: boolean;
  photo: Photo;
  onDelete?: (id: string) => void;
}

const PhotoSection: React.FC<PhotoSectionProps> = ({
  id,
  client_id,
  title,
  show_past_photos,
  show_delete_photo,
  photo,
  onDelete,
}) => {
  return (
    <Container>
      <Header>
        <h3>{title}</h3>
        {show_past_photos && (
          <Link
            to={{
              pathname: `/client-past-photos/${client_id}`,
            }}
          >
            Past photos
          </Link>
        )}
        {show_delete_photo && (
          <DeletePhotoConfirmation
            id={id}
            date={photo.date}
            onDelete={onDelete}
          />
        )}
      </Header>
      <Content>
        {photo.front_url && (
          <ExpandedPhotoModal
            title="Front"
            photo_url={photo.front_url}
            from={photo.date}
          />
        )}
        {photo.back_url && (
          <ExpandedPhotoModal
            title="Back"
            photo_url={photo.back_url}
            from={photo.date}
          />
        )}
        {photo.side_url && (
          <ExpandedPhotoModal
            title="Side"
            photo_url={photo.side_url}
            from={photo.date}
          />
        )}
      </Content>
    </Container>
  );
};

export default PhotoSection;
