import React, { useState, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  goalsIcon,
  recipe,
  chat,
  sharedResources,
  white_goalsIcon,
  white_recipe,
  white_chat,
  white_sharedResources,
  white_checkIn,
  checkIn,
} from '../../styles/icons';

import { Container, MenuItem } from './styles';

const SideMenu: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleMenuSelectedAt = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    switch (pathname) {
      case '/client-goals':
        setSelectedIndex(1);
        break;
      case '/client-nutrition':
        setSelectedIndex(2);
        break;
      case '/checkin':
        setSelectedIndex(3);
        break;
      case '/client-chat':
        setSelectedIndex(4);
        break;
      case '/client-resources-all':
        setSelectedIndex(5);
        break;
      default:
        setSelectedIndex(0);
    }
  }, [pathname]);
  return (
    <Container>
      <ul>
        <MenuItem $isSelected={selectedIndex === 1}>
          <Link to="/client-goals" onClick={() => handleMenuSelectedAt(1)}>
            <img
              src={selectedIndex === 1 ? white_goalsIcon : goalsIcon}
              alt=""
            />
            <strong>Goals & Metrics</strong>
          </Link>
        </MenuItem>
        <MenuItem $isSelected={selectedIndex === 2}>
          <Link to="/client-nutrition" onClick={() => handleMenuSelectedAt(2)}>
            <img src={selectedIndex === 2 ? white_recipe : recipe} alt="" />
            <strong>Nutrition & Recipes</strong>
          </Link>
        </MenuItem>
        <MenuItem $isSelected={selectedIndex === 3}>
          <Link to="/checkin" onClick={() => handleMenuSelectedAt(3)}>
            <img src={selectedIndex === 3 ? white_checkIn : checkIn} alt="" />
            <strong>Check in</strong>
          </Link>
        </MenuItem>
        <MenuItem $isSelected={selectedIndex === 4}>
          <Link to="/client-chat" onClick={() => handleMenuSelectedAt(4)}>
            <img src={selectedIndex === 4 ? white_chat : chat} alt="" />
            <strong>Chat</strong>
          </Link>
        </MenuItem>
        <MenuItem $isSelected={selectedIndex === 5}>
          <Link
            to="/client-resources-all"
            onClick={() => handleMenuSelectedAt(5)}
          >
            <img
              src={
                selectedIndex === 5 ? white_sharedResources : sharedResources
              }
              alt=""
            />
            <strong>Resources Library</strong>
          </Link>
        </MenuItem>
      </ul>
    </Container>
  );
};

export default SideMenu;
