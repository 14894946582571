import styled from 'styled-components';
import NavigationHeader from '../../../components/NavigationHeader';

export const NavigationBar = styled(NavigationHeader)`
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 20px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-width: 330px;
  flex-direction: column;
`;

export const Content = styled.div`
  height: 100%;
`;

export const AppWindow = styled.div`
  display: flex;
  height: 100%;
`;

export const ContentArea = styled.div`
  display: flex;
  flex: 1;
`;
export const BackButton = styled.button`
  color: var(--white);
  background: var(--primary);
  padding: 8px 13px 9px 13px;
  border-radius: 5px;
  font-size: 8px;

  @media (min-width: 600px) {
    display: none;
  }
`;
