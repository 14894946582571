import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: rgba(193, 192, 192, 0.2);
  border-radius: 5px;
  height: 48px;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;

  color: rgba(112, 112, 112, 0.45);

  & + div {
    margin-top: 12px;
  }

  border: 2px solid transparent;


  ${(props) =>
    props.isErrored &&
    css`
      border: solid 2px var(--error);
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--dark-primary);
      border: 2px solid var(--dark-primary);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: var(--dark-primary);
    `}

    strong {
      margin-right: 16px;
    }

  input {
    background: transparent;
    border: 0;
    flex: 1;
    color: var(--light-gray);
    font-size: 14px;

    &::placeholder {
      color: rgba(112, 112, 112, 0.45);
    }
  }
  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin-right: 0;
  }
  span {
    background: var(--error);
    color: var(--white);
    &::before {
      border-color: var(--error) transparent;
    }
  }
`;
