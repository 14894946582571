import React, { useState, useCallback, useRef } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import Modal from '../../../../../components/Modal';
import {
  Container,
  CreateNew,
  Header,
  Content,
  CloseButton,
  SubmitButton,
} from './styles';
import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/Toast';
import Group from '../../../../../entities/Groups';
import Dropdown from '../../../../../components/Dropdown';
import Client from '../../../../../entities/Client';

interface CreateResourceFormData {
  group: string;
}

interface CreateModalProps {
  client_id: string;
  onCreate: (groups: Group[]) => void;
}

const CreateModal: React.FC<CreateModalProps> = ({ client_id, onCreate }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<Group[]>([] as Group[]);

  const fetchGroups = useCallback(() => {
    api.get('groups').then((response) => {
      setGroups(response.data);
    });
  }, []);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      fetchGroups();
    }
  }, [isOpen, fetchGroups]);

  const handleSubmit = useCallback(
    async (data: CreateResourceFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          group: Yup.string().required('Group is Required'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const body = {
          client_id,
          group_id: data.group,
        };
        const response = await api.post<Client>(`clients-groups`, body);
        onCreate(response.data.groups);
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'User not added',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, handleToggle, onCreate, client_id],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={30} color="#ffffff" />
      </CreateNew>
      <Modal isOpen={isOpen} handleToggle={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Add Client to Group</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <div>
              <Dropdown title="Group" name="group" placeholder="Group">
                <option value="">Select</option>
                {groups.map((localGroup) => (
                  <option key={localGroup.id} value={localGroup.id}>
                    {localGroup.name}
                  </option>
                ))}
              </Dropdown>
            </div>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Adding...' : 'Add'}
            </SubmitButton>
          </Form>
        </Content>
      </Modal>
    </Container>
  );
};

export default CreateModal;
