import { format, isBefore } from 'date-fns';
import React, { useCallback } from 'react';
import Recipe from '../../../../entities/Recipe';
import api from '../../../../services/api';
import CreateModal from './CreateModal';
import DeleteConfirmation from './DeleteConfirmation';
import EditModal from './EditModal';
import {
  Container,
  Header,
  ActionButtons,
  Content,
  RecipeCard,
  RecipeHeader,
  RecipeContent,
  RecipeFooter,
  Separator,
} from './styles';

interface RecipeSectionProps {
  client_id: string;
  recipes: Recipe[];
  handleRefreshRecipes: (recipe?: Recipe) => void;
}

const RecipesSection: React.FC<RecipeSectionProps> = ({
  client_id,
  recipes,
  handleRefreshRecipes,
}) => {
  const handleOnCreate = useCallback(
    (recipe) => {
      handleRefreshRecipes(recipe);
    },
    [handleRefreshRecipes],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await api.delete(`recipes/${id}`);
      handleRefreshRecipes();
    },
    [handleRefreshRecipes],
  );

  function isAvailable(available_date: string): boolean {
    if (isBefore(new Date(available_date), new Date())) {
      return true;
    }
    return false;
  }

  return (
    <Container>
      <Header>
        <h3>Recipes</h3>
        <ActionButtons>
          <CreateModal client_id={client_id} onCreate={handleOnCreate} />
        </ActionButtons>
      </Header>
      <Content>
        {recipes.map((recipe) => (
          <RecipeCard key={recipe.id}>
            <RecipeHeader>
              <EditModal recipe={recipe} onEdit={handleRefreshRecipes} />
              <DeleteConfirmation
                id={recipe.id}
                title={recipe.title}
                onDelete={handleDelete}
              />
            </RecipeHeader>
            <RecipeContent
              href={recipe.recipe_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={recipe.thumbnail_url} alt={recipe.title} />
            </RecipeContent>
            <RecipeFooter $available={isAvailable(recipe.available_date)}>
              <strong>{recipe.title}</strong>
              <Separator />
              <span>Available on:</span>
              <span>
                {format(new Date(recipe.available_date), 'dd/MM/yyyy')}
              </span>
            </RecipeFooter>
          </RecipeCard>
        ))}
      </Content>
    </Container>
  );
};

export default RecipesSection;
