import React, { useCallback, useRef } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import {
  Container,
  LoginCard,
  AnimationContainer,
  Content,
  Background,
} from './styles';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useChat } from '../../hooks/Chat';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const { connectSocket } = useChat();
  const { addToast } = useToast();
  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email is required')
            .email('Invalid email'),
          password: Yup.string().min(4),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const result = await signIn({
          email: data.email.toLowerCase(),
          password: data.password,
        });
        await connectSocket(result.client.id, result.user.id, result.type);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Authentication Failed',
          description: 'Check credentials and try again.',
        });
      }
    },
    [signIn, addToast, connectSocket],
  );

  return (
    <Container>
      <LoginCard>
        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Client Login</h1>
              <Input name="email" icon={FiMail} placeholder="Email" />
              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder="Password"
              />
              <Button type="submit">Login</Button>

              <Link to="/forgot-password">
                Forgot password? <strong>Click here.</strong>
              </Link>
            </Form>
          </AnimationContainer>
        </Content>
        <Background />
      </LoginCard>
    </Container>
  );
};

export default SignIn;
