import React, { useCallback, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import DailyPlan from '../../../../entities/DailyPlan';
import Recommendation from './Recommendation';

import { Container, Header, Content, Row, RowHeader, RowBody } from './styles';

interface MealPlannerProps {
  dailyInfos: DailyPlan[];
}
const MealPlannerComponent: React.FC<MealPlannerProps> = ({ dailyInfos }) => {
  const [showDetails, setShowDetails] = useState<string[]>([]);
  const handleToggleDescription = useCallback(
    (forId: string) => {
      if (showDetails.indexOf(forId) !== -1) {
        setShowDetails(showDetails.filter((mealPlan) => mealPlan !== forId));
      } else {
        setShowDetails([...showDetails, forId]);
      }
    },
    [showDetails],
  );

  function shouldDisplayDetails(for_id: string): boolean {
    return showDetails.find((mailPlan) => mailPlan === for_id) !== undefined;
  }

  return (
    <Container>
      <Header>
        <h3>Daily Meal Planner</h3>
      </Header>

      <Content>
        {dailyInfos.map((mealPlan) => (
          <Row
            key={mealPlan.id}
            onClick={() => handleToggleDescription(mealPlan.id)}
          >
            <RowHeader $shouldShowDetails={shouldDisplayDetails(mealPlan.id)}>
              <div className="row-header-info">
                <span>Meal</span>
                <strong>{mealPlan.meal}</strong>
              </div>
              <div />
              <IoIosArrowForward size={26} />
            </RowHeader>
            <RowBody $shouldShowDetails={shouldDisplayDetails(mealPlan.id)}>
              <div className="column top">
                <strong className="content-title">Timing</strong>
                <span>{mealPlan.timing}</span>
              </div>
              <div className="column">
                <strong className="content-title">Recommendation</strong>
                <span>
                  <Recommendation
                    protein={mealPlan.protein}
                    carbs={mealPlan.carbs}
                    fats={mealPlan.fats}
                    calories={mealPlan.calories}
                  />
                </span>
              </div>
              <div className="column">
                <strong className="content-title">Explanation</strong>
                <span>{mealPlan.explanation}</span>
              </div>
            </RowBody>
          </Row>
        ))}
      </Content>
    </Container>
  );
};

export default MealPlannerComponent;
