import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { FiAlertCircle, FiCamera } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface CameraInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const CameraInput: React.FC<CameraInputProps> = ({
  name,
  className,
  placeholder,
  id,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <Container className={className}>
      <strong>{placeholder}</strong>
      <label htmlFor={id}>
        <input
          id={id}
          defaultValue={defaultValue}
          ref={inputRef}
          type="file"
          {...rest}
        />
        <FiCamera />
      </label>
      {error && (
        <Error title={error}>
          <FiAlertCircle size={20} color="#FF778E" />
        </Error>
      )}
    </Container>
  );
};

export default CameraInput;
