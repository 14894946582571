import styled, { css } from 'styled-components';

interface SideMenuProps {
  $isSelected: boolean;
}
export const Container = styled.div`
  display: flex;

  background: var(--white);
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  max-height: 100px;

  position: sticky;
  top: 170px;
  left: 0;

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  ul {
    list-style: none;
    flex: 1;
  }
  min-height: 400px;
  @media (min-width: 1300px) {
    min-width: 262px;
  }
  @media (max-width: 850px) {
    display: none;
  }

  @media (min-height: 600px) {
    position: sticky;
  }
`;

export const MenuItem = styled.li<SideMenuProps>`
  background-image: none;
  color: var(--gray);
  ${(props) =>
    props.$isSelected &&
    css`
      background-image: linear-gradient(
        71deg,
        var(--primary),
        var(--white) 249%
      );
      color: var(--white);
    `}
  padding: 0 20px;

  height: 70px;
  display: flex;
  align-items: center;

  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      width: 37px;
      height: 37px;
      margin-right: 20px;
    }

    svg {
      width: 37px;
      height: 37px;
      margin-right: 20px;
    }

    strong {
      font-size: 18px;
      font-family: 'SofiaProRegular';
      font-weight: bold;
    }
  }

  @media (max-width: 1300px) {
    a strong {
      display: none;
    }

    a img {
      margin-right: 0;
    }

    a svg {
      margin-right: 0;
    }
  }
`;
