import React, { useState, useCallback, useRef, ChangeEvent } from 'react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import {
  Container,
  StyledModal,
  CreateNew,
  Header,
  Content,
  Files,
  CloseButton,
  SubmitButton,
} from './styles';
import DetailedNutrition from '../../../../../entities/DetailedNutrition';
import { useToast } from '../../../../../hooks/Toast';
import api from '../../../../../services/api';
import getTypeFromExtension from '../../../../../utils/getTypeFromExtension';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import FileInput from '../../../../../components/FileInput';

interface DocumentUploadProps {
  name: string;
  folder: string;
  type: string;
}

interface EditModalProps {
  detailed_nutrition: DetailedNutrition;
  onEdit: () => void;
}

const EditModal: React.FC<EditModalProps> = ({
  detailed_nutrition,
  onEdit,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentData, setDocumentData] = useState<FormData | null>(null);
  const [documentTitle, setDocumentTitle] = useState('Detailed Nutrition');

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDocumentUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();
        const file = e.target.files[0];
        data.append('file', file);
        setDocumentData(data);
        setDocumentTitle(`Detailed Nutrition: ${file.name}`);
      }
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      formRef.current?.setErrors({});

      // UPLOAD CONTENT
      let documentName = '';
      if (documentData) {
        const response = await api.post<DocumentUploadProps>(
          '/documents/detailed-nutritions',
          documentData,
        );
        documentName = response.data.name;
        const ext = documentName.substr(documentName.lastIndexOf('.') + 1);
        const body = {
          document: documentName,
          type: getTypeFromExtension(ext),
        };
        await api.put<DetailedNutrition>(
          `detailed-nutritions/${detailed_nutrition.id}`,
          body,
        );
      }

      onEdit();
      handleToggle();
      setIsLoading(false);
      setDocumentData(null);
      setDocumentTitle('Detailed Nutrition');
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        setIsLoading(false);
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Could not edit Meal plan',
        description: err.response
          ? err.response.data.message
          : 'Could not connect to server, please try again later',
      });
    }
  }, [addToast, handleToggle, onEdit, detailed_nutrition.id, documentData]);

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiEdit size={14} color="#ffffff" />
      </CreateNew>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Edit Case note</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <div>
              <Files>
                <FileInput
                  id="document"
                  name="document"
                  placeholder={documentTitle}
                  onChange={(e) => handleDocumentUpload(e)}
                />
              </Files>
            </div>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </SubmitButton>
          </Form>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default EditModal;
