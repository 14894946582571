import React from 'react';

import { Container, Main, Wrapper, MainWrapper } from './styles';
import Header from '../../../components/Header';
import AdminSideMenu from '../../../components/AdminSideMenu';
import AdminBottomMenu from '../../../components/AdminBottomMenu';

const DashboardLayout: React.FC = ({ children }) => {
  return (
    <Container>
      <Wrapper>
        <Header />
        <Main>
          <AdminSideMenu />
          <MainWrapper>{children}</MainWrapper>
        </Main>
        <AdminBottomMenu />
      </Wrapper>
    </Container>
  );
};

export default DashboardLayout;
