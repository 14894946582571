import React from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
} from 'recharts';

import { uuid } from 'uuidv4';
import { Container, Header, Info, Content } from './styles';
import ChartEntity from '../../entities/ChartEntity';
import CreateModal from '../Metrics/CreateModal';
import { useAuth } from '../../hooks/Auth';
import Metric from '../../entities/Metric';

interface ChartProps {
  data: ChartEntity[];
  primary_color: string;
  metric: string;
  title: string;
  isOptionalMetric?: boolean;
  handleMetricAdded: (metric: Metric) => void;
}

const Chart: React.FC<ChartProps> = ({
  data,
  primary_color,
  metric,
  title,
  isOptionalMetric = false,
  handleMetricAdded,
}) => {
  const { user } = useAuth();

  return (
    <Container>
      <Header>
        <Info>
          <span>{title.toUpperCase()}</span>
          <strong>
            {data.length > 0
              ? `${data[data.length - 1].value}${data[data.length - 1].type}`
              : '0.0'}
          </strong>
        </Info>
        <CreateModal
          client_id={user.id}
          title={isOptionalMetric ? title : undefined}
          metric={metric}
          onCreate={handleMetricAdded}
        />
      </Header>
      <Content>
        <ResponsiveContainer width="100%" height={200}>
          <AreaChart
            data={data}
            syncId={uuid()}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient
                id={`colorUv${metric}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="5%" stopColor={primary_color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={primary_color} stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="5 5" vertical={false} />
            <XAxis dataKey="date_formatted" />
            <YAxis type="number" domain={['dataMin - 20', 'dataMax + 10']} />
            <Tooltip />
            <Area
              dot={{ fill: primary_color }}
              type="monotone"
              dataKey="value"
              stroke={primary_color}
              strokeWidth={3}
              fillOpacity={1}
              fill={`url(#colorUv${metric})`}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Content>
    </Container>
  );
};

export default Chart;
