import styled from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  width: 100%;
  min-width: 310px;
`;

export const Row = styled.div`
  display: flex;
  background: var(--light-background);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px;

  img {
    display: none;
    width: 150px;
    height: 90px;
    object-fit: cover;
  }

  @media (min-width: 600px) {
    img {
      display: flex;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  a {
    flex: 1;
  }
`;

export const Info = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;
  margin-left: 20px;
  max-width: 150px;
  strong {
    word-wrap: break-word;
    margin-bottom: 8px;
  }

  span {
    font-size: 12px;
    color: var(--gray);
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Approve = styled(Button)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding: 0;
  margin-right: 16px;
`;

export const Reject = styled(Button)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding: 0;

  background: var(--error);

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(61deg, var(--error), var(--white) 249%);
  }
`;
