import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
`;

export const Card = styled.div`
  background: var(--light-background);
  border-radius: 5px;
  overflow: hidden;
  width: 74px;
  height: 70px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-right: 12px;
`;

export const Header = styled.div`
  background: var(--dark-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: var(--white);
    font-weight: bold;
    font-size: 12px;
    padding-top: 5px !important;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 14px;
    color: var(--blue);
    font-weight: bold;
  }
`;
