import React from 'react';
import DetailedNutrition from '../../../../entities/DetailedNutrition';
import CreateModal from './CreateModal';
import DeleteConfirmation from './DeleteConfirmation';
import EditModal from './EditModal';

import { Container, Header, Card, CardHeader, CardContent } from './styles';

interface DetailedNutritionProps {
  detailed_nutrition?: DetailedNutrition;
  nutrition_id: string;
  client_id: string;
  handleRefreshDetailedNutrition: () => void;
  handleDelete: (id: string) => void;
}

const DetailedNutritionComponent: React.FC<DetailedNutritionProps> = ({
  detailed_nutrition,
  nutrition_id,
  client_id,
  handleRefreshDetailedNutrition,
  handleDelete,
}) => {
  return (
    <Container>
      <Header>
        <strong>Detailed Nutrition</strong>
        <CreateModal
          nutrition_id={nutrition_id}
          client_id={client_id}
          onCreate={handleRefreshDetailedNutrition}
        />
      </Header>
      {detailed_nutrition && (
        <Card>
          <CardHeader>
            <EditModal
              detailed_nutrition={detailed_nutrition}
              onEdit={handleRefreshDetailedNutrition}
            />
            <DeleteConfirmation
              id={detailed_nutrition.id}
              title="Detailed nutrition"
              onDelete={handleDelete}
            />
          </CardHeader>
          <CardContent
            href={detailed_nutrition.document_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={detailed_nutrition.thumbnail_url}
              alt="Detailed Nutrition"
            />
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default DetailedNutritionComponent;
