import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiBell } from 'react-icons/fi';
import { useHistory, Link } from 'react-router-dom';

import { Container, Pending, Buttons } from './styles';
import { useAuth } from '../../../hooks/Auth';
import NavigationHeader from '../../../components/NavigationHeader';
import Loading from '../../../components/Loading';
import api from '../../../services/api';

import { Dictionary, Section } from '../../../entities/SharedResources';
import getItemsForListFromDictionary from '../../../utils/getItemsForListFromDictionary';
import List from './List';
import CreateModal from './CreateModal';

interface ResponseData {
  sections: Dictionary;
  hasPending: boolean;
}

const AdminSharedResources: React.FC = () => {
  const { user } = useAuth();
  const isAdmin = useMemo(() => user.role === 'admin', [user.role]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [hasPending, setHasPending] = useState(false);
  const [sections, setSections] = useState<Section[] | undefined>();

  const handleSortForList = useCallback((data: Dictionary) => {
    const titleList: string[] = Object.entries(data)
      .map(([key]) => {
        return key;
      })
      .sort();

    const list: Section[] = [];
    titleList.forEach((title) => {
      list.push({
        title,
        resources: getItemsForListFromDictionary(title, data),
      });
    });

    setSections(list);

    setIsLoading(false);
  }, []);

  const getData = useCallback(() => {
    api.get<ResponseData>('shared-resources/admin').then((response) => {
      setHasPending(response.data.hasPending);
      handleSortForList(response.data.sections);
    });
  }, [handleSortForList]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Resources</span>
        </strong>
        <Buttons>
          {isAdmin && (
            <Link to={hasPending ? 'resources-curation' : '#'}>
              <Pending disabled={!hasPending}>
                <FiBell size={30} />
              </Pending>
            </Link>
          )}
          <CreateModal onCreate={getData} />
        </Buttons>
      </NavigationHeader>

      {isLoading ? (
        <Loading />
      ) : (
        sections?.map((item) => (
          <List key={item.title} section={item} onUpdate={getData} />
        ))
      )}
    </Container>
  );
};

export default AdminSharedResources;
