import { format } from 'date-fns';
import React from 'react';
import Metric from '../../../../entities/Metric';
import DeleteConfirmation from '../../../../components/Metrics/DeleteConfirmation';

import { Container } from './styles';

interface MetricCardProps {
  metric: Metric;
  handleOnDelete: (id: string) => void;
}

const MetricCard: React.FC<MetricCardProps> = ({ metric, handleOnDelete }) => {
  return (
    <Container>
      <span>{format(new Date(metric.date), 'dd/MM/yyyy')}</span>
      <strong>
        {metric.value} {metric.type}
      </strong>
      <DeleteConfirmation
        title={`${metric.value} ${metric.type}`}
        id={metric.id}
        onDelete={handleOnDelete}
      />
    </Container>
  );
};

export default MetricCard;
