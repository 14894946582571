import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import Dashboard from '../pages/Admin/Dashboard';
import Home from '../pages/Client/Home';
import ResetPassword from '../pages/ResetPassword';
import Goals from '../pages/Client/Goals';
import NutritionSection from '../pages/Client/NutritionSection';
import Chat from '../pages/Client/Chat';
import SharedResource from '../pages/Client/SharedResources';
import Clients from '../pages/Admin/Clients';
import Groups from '../pages/Admin/Groups';
import AdminClientProfile from '../pages/Admin/ClientProfile';
import Chats from '../pages/Admin/Chats';
import AdminSharedResources from '../pages/Admin/SharedResources';
import Curation from '../pages/Admin/SharedResources/Curation';
import Checkin from '../pages/Admin/Checkin';
import CaseNotes from '../pages/Admin/CaseNotes';
import Metrics from '../pages/Admin/Metrics';
import Recipes from '../pages/Admin/Recipes';
import MealPlans from '../pages/Admin/MealPlans';
import PastNutritions from '../components/PastNutritions';
import NutritionDetail from '../pages/Admin/Nutrition/NutritionDetail';
import CreateNutrition from '../pages/Admin/Nutrition/CreateNutrition';
import AdminProfile from '../pages/Admin/AdminProfile';
import Staff from '../pages/Admin/Staff';
import Profile from '../pages/Client/Profile';
import Mail from '../pages/Admin/Mail';
import MetricsList from '../pages/Client/MetricsList';
import ClientCheckin from '../pages/Client/ClientCheckin';
import RecipesMealPlan from '../pages/Client/RecipesMealPlan';
import ClientPastNutritions from '../pages/Client/ClientPastNutritions';
import PastDetailedNutritions from '../pages/Client/PastDetailedNutritions';
import Photos from '../pages/Client/Photos';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/forgot-password" exact component={ForgotPassword} />
    <Route path="/reset-password" exact component={ResetPassword} />

    <Route
      path="/dashboard"
      exact
      component={Dashboard}
      requiresAdmin
      isPrivate
    />
    <Route
      path="/admin-profile"
      exact
      component={AdminProfile}
      requiresAdmin
      isPrivate
    />
    <Route
      path="/admin-clients"
      exact
      component={Clients}
      requiresAdmin
      isPrivate
    />
    <Route path="/staff" exact component={Staff} requiresAdmin isPrivate />
    <Route
      path="/admin-client-profile/:id"
      exact
      component={AdminClientProfile}
      requiresAdmin
      isPrivate
    />
    <Route
      path="/admin-groups"
      exact
      component={Groups}
      requiresAdmin
      isPrivate
    />
    <Route
      path="/admin-chats"
      exact
      component={Chats}
      requiresAdmin
      isPrivate
    />
    <Route
      path="/admin-resources"
      exact
      component={AdminSharedResources}
      requiresAdmin
      isPrivate
    />
    <Route
      path="/resources-curation"
      exact
      component={Curation}
      requiresAdmin
      isPrivate
    />

    <Route
      path="/admin-client/:id/checkin"
      exact
      component={Checkin}
      requiresAdmin
      isPrivate
    />

    <Route
      path="/admin-client/:id/metrics"
      exact
      component={Metrics}
      requiresAdmin
      isPrivate
    />

    <Route
      path="/admin-client/:id/case-notes"
      exact
      component={CaseNotes}
      requiresAdmin
      isPrivate
    />

    <Route
      path="/admin-client/:client_id/new-nutrition"
      exact
      component={CreateNutrition}
      requiresAdmin
      isPrivate
    />

    <Route
      path="/admin-client/:id/past-nutrition"
      exact
      component={PastNutritions}
      requiresAdmin
      isPrivate
    />

    <Route
      path="/admin-client/nutrition/:id"
      exact
      component={NutritionDetail}
      requiresAdmin
      isPrivate
    />

    <Route
      path="/admin-client/:id/recipes"
      exact
      component={Recipes}
      requiresAdmin
      isPrivate
    />

    <Route path="/mail/:id" exact component={Mail} requiresAdmin isPrivate />

    <Route
      path="/admin-client/:id/meal-plans"
      exact
      component={MealPlans}
      requiresAdmin
      isPrivate
    />

    <Route path="/home" exact component={Home} isPrivate />
    <Route path="/profile" exact component={Profile} isPrivate />
    <Route path="/client-goals" exact component={Goals} isPrivate />
    <Route path="/client-metrics" exact component={MetricsList} isPrivate />
    <Route path="/checkin" exact component={ClientCheckin} isPrivate />
    <Route
      path="/client-nutrition/:id?"
      exact
      component={NutritionSection}
      isPrivate
    />
    <Route
      path="/past-nutritions"
      exact
      component={ClientPastNutritions}
      isPrivate
    />
    <Route
      path="/past-detailed-nutritions"
      exact
      component={PastDetailedNutritions}
      isPrivate
    />
    <Route
      path="/recipes-mealplan"
      exact
      component={RecipesMealPlan}
      isPrivate
    />
    <Route path="/client-chat" exact component={Chat} isPrivate />

    <Route
      path="/client-resources-all"
      exact
      component={SharedResource}
      isPrivate
    />

    <Route
      path="/client-past-photos/:client_id"
      exact
      component={Photos}
      isPrivate
    />
  </Switch>
);

export default Routes;
