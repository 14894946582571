import styled from 'styled-components';
import Modal from 'styled-react-modal';
import Button from '../../../../../components/Button';

export const Container = styled.div`
  margin-right: 10px;
`;

export const StyledModal = Modal.styled`
  width: 90%;
  max-width: 800px;
  height: 40%;
  max-height: 656px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 15px;
  overflow: hidden;
`;

export const CreateNew = styled(Button)`
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 13px;
  }

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(
      61deg,
      var(--dark-primary),
      var(--white) 249%
    );
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  strong {
    color: var(--primary);
    font-weight: bold;
  }
`;

export const CloseButton = styled(Button)`
  margin-top: 0;
  width: 40px;
  height: 40px;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 8px;

  &:hover {
    background-image: none;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 0;
  height: 40px;
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;

export const Files = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
