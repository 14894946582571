import React, { useCallback } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { Container, Header, Content, Card } from './styles';
import Group from '../../../../entities/Groups';
import api from '../../../../services/api';
import Client from '../../../../entities/Client';
import CreateModal from './CreateModal';

interface GroupsSectionProps {
  groups: Group[];
  client: Client;
  handleRefreshGroups: (groups?: Group[]) => void;
}
const GroupsSection: React.FC<GroupsSectionProps> = ({
  groups,
  client,
  handleRefreshGroups,
}) => {
  const handleRemoveFromGroup = useCallback(
    (id) => {
      api.delete(`clients-groups/${client.id}/${id}`).then(() => {
        handleRefreshGroups();
      });
    },
    [client.id, handleRefreshGroups],
  );

  const handleOnCreate = useCallback(
    (addedGroups: Group[]) => {
      handleRefreshGroups(addedGroups);
    },
    [handleRefreshGroups],
  );
  return (
    <Container>
      <Header>
        <h3>Groups</h3>
        <CreateModal
          client_id={client.id}
          onCreate={(addedGroups: Group[]) => handleOnCreate(addedGroups)}
        />
      </Header>

      <Content>
        {groups.map((group) => (
          <Card key={group.id}>
            <strong>{group.name}</strong>
            <button
              type="button"
              onClick={() => handleRemoveFromGroup(group.id)}
            >
              <FiXCircle />
            </button>
          </Card>
        ))}
      </Content>
    </Container>
  );
};

export default GroupsSection;
