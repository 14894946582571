import styled from 'styled-components';
import Button from '../../../components/Button';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 10px 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: 16px;
    color: var(--gray);
    font-weight: bold;
  }
`;

export const Week = styled.div`
  display: flex;
  align-items: center;
  background: var(--light-background);
  padding: 8px 0;
  border-radius: 5px;
  svg {
    width: 30px;
    color: var(--gray);
    display: flex;
    align-items: center;
  }

  strong {
    font-size: 14px;
    color: var(--gray);
  }
`;

export const BackButton = styled.button`
  svg {
    color: var(--primary);
  }
`;

export const ForwardButton = styled.button`
  svg {
    color: var(--primary);
  }
`;

export const Questions = styled.div``;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--light-background);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;

  &:first-child {
    margin-top: 20px;
  }

  strong {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

export const Answer = styled.div`
  border-radius: 5px;
  min-height: 50px;

  p {
    font-size: 12px;
    color: var(--gray);
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 0;
  height: 30px;
  max-width: 100px;
  font-size: 14px;
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;
