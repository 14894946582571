import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: rgba(193, 192, 192, 0.2);
  border-radius: 5px;
  height: 48px;
  width: 100%;
  padding: 16px 3px 16px 16px;
  display: flex;
  align-items: center;

  color: rgba(112, 112, 112, 0.45);

  & + div {
    margin-left: 12px;
  }

  border: 2px solid transparent;
  justify-content: space-between;

  ${(props) =>
    props.isErrored &&
    css`
      border: solid 2px var(--error);
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--dark-primary);
      border: 2px solid var(--dark-primary);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: var(--dark-primary);
    `}

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: var(--primary);
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      background-image: linear-gradient(61deg, var(--primary), var(--white) 249%);
      }

      strong {
        font-family: SofiaProLight;
        font-size: 14px;
        color: var(--light-gray);
      }
      svg {
        width: 20px;
        height: 20px;
        color: var(--white);
      }
      input {
        display: none;
      }
    }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin-right: 0;
  }
  span {
    background: var(--error);
    color: var(--white);
    &::before {
      border-color: var(--error) transparent;
    }
  }
`;
