import React, { useState, useCallback, useRef, ChangeEvent } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import {
  Container,
  StyledModal,
  CreateNew,
  Header,
  Content,
  Files,
  CustomFileInput,
  PhotoDate,
  CloseButton,
  SubmitButton,
} from './styles';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import Calendar from '../../Calendar';
import Photo from '../../../entities/Photo';

interface CreatePhotoFormData {
  from: Date;
  front_photo?: string;
  back_photo?: string;
  side_photo?: string;
}

interface DocumentUploadProps {
  name: string;
  folder: string;
  type: string;
}

interface AddPhotoModalProps {
  onCreate: (photo: Photo) => void;
}

const AddPhotoModal: React.FC<AddPhotoModalProps> = ({ onCreate }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [frontPhotoData, setFrontPhotoData] = useState<FormData | null>(null);
  const [backPhotoData, setBackPhotoData] = useState<FormData | null>(null);
  const [sidePhotoData, setSidePhotoData] = useState<FormData | null>(null);
  const [frontPhotoTitle, setFrontPhotoTitle] = useState('Front');
  const [backPhotoTitle, setBackPhotoTitle] = useState('Back');
  const [sidePhotoTitle, setSidePhotoTitle] = useState('Side');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDocumentUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>, inputName: string) => {
      if (e.target.files) {
        const data = new FormData();
        const file = e.target.files[0];
        data.append('file', file);
        if (inputName === 'front') {
          setFrontPhotoData(data);
          setFrontPhotoTitle(`Front: ${file.name}`);
        } else if (inputName === 'back') {
          setBackPhotoData(data);
          setBackPhotoTitle(`Back: ${file.name}`);
        } else {
          setSidePhotoData(data);
          setSidePhotoTitle(`Side: ${file.name}`);
        }
      }
    },
    [],
  );

  const handleDateChanged = useCallback((day: Date) => {
    setSelectedDate(day);
  }, []);

  const handleSubmit = useCallback(
    async (data: CreatePhotoFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});
        await schema.validate(data, {
          abortEarly: false,
        });

        const photo = {
          date: selectedDate,
        };

        // UPLOAD front
        let frontPhotoName = '';
        if (frontPhotoData) {
          const response = await api.post<DocumentUploadProps>(
            '/documents/photos',
            frontPhotoData,
          );
          frontPhotoName = response.data.name;
          Object.assign(photo, {
            front: frontPhotoName,
          });
        }

        // UPLOAD back
        let backPhotoName = '';
        if (backPhotoData) {
          const response = await api.post<DocumentUploadProps>(
            '/documents/photos',
            backPhotoData,
          );
          backPhotoName = response.data.name;
          Object.assign(photo, {
            back: backPhotoName,
          });
        }

        // UPLOAD side
        let sidePhotoName = '';
        if (sidePhotoData) {
          const response = await api.post<DocumentUploadProps>(
            '/documents/photos',
            sidePhotoData,
          );
          sidePhotoName = response.data.name;
          Object.assign(photo, {
            side: sidePhotoName,
          });
        }

        // CREATE CONTENT
        const response = await api.post<Photo>('/photos', photo);
        onCreate(response.data);
        handleToggle();
        setIsLoading(false);
        setFrontPhotoData(null);
        setBackPhotoData(null);
        setSidePhotoData(null);
        setFrontPhotoTitle('Front: ');
        setBackPhotoTitle('Back: ');
        setSidePhotoTitle('Side: ');
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [
      addToast,
      handleToggle,
      onCreate,
      frontPhotoData,
      backPhotoData,
      sidePhotoData,
      selectedDate,
    ],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={20} color="#ffffff" />
      </CreateNew>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Add Photos</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <div>
              <Files>
                <CustomFileInput
                  id="front"
                  name="front"
                  placeholder={frontPhotoTitle}
                  className="file-input"
                  onChange={(e) => handleDocumentUpload(e, 'front')}
                />
                <CustomFileInput
                  id="back"
                  name="back"
                  placeholder={backPhotoTitle}
                  className="file-input"
                  onChange={(e) => handleDocumentUpload(e, 'back')}
                />
                <CustomFileInput
                  id="side"
                  name="side"
                  placeholder={sidePhotoTitle}
                  className="file-input"
                  onChange={(e) => handleDocumentUpload(e, 'side')}
                />
              </Files>
              <PhotoDate>
                <h3>Photo Date</h3>
                <Calendar onDayClick={handleDateChanged} toMonth={new Date()} />
              </PhotoDate>
            </div>
            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Uploading...' : 'Add'}
            </SubmitButton>
          </Form>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default AddPhotoModal;
