import styled from 'styled-components';
import Modal from 'styled-react-modal';
import Button from '../../../../components/Button';

export const Container = styled.div``;

export const StyledModal = Modal.styled`
  width: 90%;
  max-width: 400px;
  max-height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 8px;
  overflow: hidden;
`;
export const Delete = styled(Button)`
  background: var(--error);
  height: 30px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  padding: 0px 8px 0px 8px;

  strong {
    font-size: 10px;
  }

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(61deg, var(--error), var(--white) 249%);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;
export const Info = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  strong {
    word-wrap: break-word;
    margin-bottom: 4px;
  }
  span {
    font-size: 12px;
  }
`;
export const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  margin: 10px;

  overflow: hidden;
`;
export const DeleteButton = styled(Button)`
  background: var(--error);
  margin-top: 0;
  height: 40px;
  width: 100%;

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(61deg, var(--error), var(--white) 249%);
  }
`;
export const Cancel = styled(Button)`
  margin-top: 0;
  height: 40px;
  width: 100%;
  margin-right: 12px;
  background: var(--light-background);
  color: var(--primary);

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(
      61deg,
      var(--light-background),
      var(--white) 249%
    );
  }
`;
