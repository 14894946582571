import styled from 'styled-components';
import Button from '../../../components/Button';
import { Separator } from '../../../components/SeparatorComponent/styles';

export const Container = styled.div`
  width: 100%;
  padding: 20px 30px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  form {
    width: 100%;
    height: 100%;
    max-width: 500px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 10px;
      color: var(--super-light-gray);
    }
    @media (min-width: 1000px) {
      max-width: none;
    }
  }
`;

export const Avatar = styled.div`
  position: relative;
  .camera {
    position: absolute;
    bottom: 2px;
    right: 2px;
  }
  img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    object-fit: cover;
  }
  @media (min-width: 1000px) {
    img {
      width: 166px;
      height: 166px;
      border-radius: 83px;
    }

    .camera {
      bottom: 10px;
      right: 10px;
    }
  }
`;

export const Info = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;

export const PersonalInformation = styled.div`
  width: 100%;
  @media (min-width: 1000px) {
    padding-right: 5px;
  }
`;

export const CustomSeparator = styled(Separator)`
  @media (min-width: 1000px) {
    width: 2px;
    height: 150px;
    border: 1px solid var(--light-background);
    margin: 0 10px;
  }
`;

export const PasswordManager = styled.div`
  width: 100%;
  @media (min-width: 1000px) {
    padding-left: 5px;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;

  margin-top: 0;
  padding: 8px 13px 9px 13px;
  font-size: 14px;
  font-weight: normal;
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
  @media (min-width: 400px) {
    font-size: 16px;
  }
  @media (min-width: 1000px) {
    width: auto;
  }
`;
