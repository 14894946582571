import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Input from '../../../components/Input';
import NavigationHeader from '../../../components/NavigationHeader';

import { Container, Content, SubmitButton, Avatar, Info } from './styles';
import { useToast } from '../../../hooks/Toast';
import { useAuth } from '../../../hooks/Auth';
import api from '../../../services/api';
import User from '../../../entities/User';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Separator } from '../../../components/SeparatorComponent/styles';
import CameraInput from '../../../components/CameraInput';

interface UpdateUserData {
  name: string;
  email: string;
  role: string;
  old_password?: string;
  password?: string;
  password_confirmation?: string;
}

const AdminProfile: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { user, updateUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const handleDocumentUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();
        const file = e.target.files[0];
        data.append('avatar', file);

        try {
          const response = await api.patch<User>('/users/avatar', data);
          updateUser(response.data);
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Update Failed',
            description: error.response
              ? error.response.data.message
              : 'Could not connect to server, please try again later',
          });
        }
      }
    },
    [addToast, updateUser],
  );

  const handleSubmit = useCallback(
    async (data: UpdateUserData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().min(3, 'Name must be longer than 3 characters'),
          email: Yup.string().email(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          name: data.name,
          email: data.email,
          role: user.role,
        };

        if (data.password && data.password !== '') {
          Object.assign(body, {
            old_password: data.old_password,
            password: data.password,
            password_confirmation: data.password_confirmation,
          });
        }
        // CREATE CONTENT
        const response = await api.put<User>('/users-profile', body);
        updateUser(response.data);

        addToast({
          type: 'success',
          title: 'Update Success',
          description: `Your information was updated`,
        });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Update Failed',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, user.role, updateUser],
  );

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Profile</span>
        </strong>
        <SubmitButton
          type="submit"
          disabled={isLoading}
          form="update-user-form"
        >
          {isLoading ? 'Updating...' : 'Save'}
        </SubmitButton>
      </NavigationHeader>
      <Content>
        <Form id="update-user-form" ref={formRef} onSubmit={handleSubmit}>
          <Avatar>
            <img src={user.avatar_url} alt={user.name} />
            <div className="camera">
              <CameraInput
                name="avatar"
                onChange={(e) => handleDocumentUpload(e)}
              />
            </div>
          </Avatar>

          <Info>
            <Input
              title="Name"
              name="name"
              placeholder="Enter text"
              defaultValue={user.name}
            />
            <Input
              title="Email"
              name="email"
              placeholder="Enter text"
              defaultValue={user.email}
            />
            <Separator />
            <Input
              title="Old Password"
              name="old_password"
              placeholder="Enter text"
              type="password"
            />
            <Input
              title="New Password"
              name="password"
              placeholder="Enter text"
              type="password"
            />
            <Input
              title="Confirm Password"
              name="password_confirmation"
              placeholder="Enter text"
              type="password"
            />
          </Info>
        </Form>
      </Content>
    </Container>
  );
};

export default AdminProfile;
