import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import CheckboxInput from '../../../../components/CheckboxInput';
import Client from '../../../../entities/Client';
import Metrics from '../../../../entities/Metrics';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';

import {
  Container,
  Header,
  Content,
  Checkbox,
  Inputs,
  WildcardInput1,
  WildcardInput2,
  SubmitButton,
} from './styles';
import SeparatorComponent from '../../../../components/SeparatorComponent';

interface CheckboxOption {
  id: string;
  value: string;
  label: string;
}

interface MetricsProps {
  clientId: string;
  metrics: Metrics;
  handleUpdate: () => void;
}
interface MetricsFormData {
  checkbox: string[];
  wildcard1: string;
  wildcard2: string;
}

interface MetricsRequest {
  is_weights_enabled: boolean;
  is_waists_enabled: boolean;
  is_chests_enabled: boolean;
  is_hips_enabled: boolean;
  is_thighs_enabled: boolean;
  is_biceps_enabled: boolean;
  metric_op1_titled?: string;
  metric_op2_titled?: string;
}

const MetricsSection: React.FC<MetricsProps> = ({
  clientId,
  metrics,
  handleUpdate,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const options = ['Weight', 'Waist', 'Chest', 'Hips', 'Thigh', 'Biceps'];
  const checkboxOptions = useMemo(() => {
    return options.map((option) => {
      const checkbox: CheckboxOption = {
        id: option.toLowerCase(),
        value: option.toLowerCase(),
        label: option,
      };
      return checkbox;
    });
  }, [options]);

  const handleSubmit = useCallback(
    async (data: MetricsFormData) => {
      setIsLoading(true);
      const body: MetricsRequest = {
        is_weights_enabled: data.checkbox.includes('weight'),
        is_waists_enabled: data.checkbox.includes('waist'),
        is_chests_enabled: data.checkbox.includes('chest'),
        is_hips_enabled: data.checkbox.includes('hips'),
        is_thighs_enabled: data.checkbox.includes('thigh'),
        is_biceps_enabled: data.checkbox.includes('biceps'),
      };
      if (data.wildcard1) {
        Object.assign(body, { metric_op1_titled: data.wildcard1 });
      }
      if (data.wildcard2) {
        Object.assign(body, { metric_op2_titled: data.wildcard2 });
      }

      try {
        await api.put<Client>(`/clients-metrics/${clientId}`, body);
        handleUpdate();
        addToast({
          type: 'success',
          title: 'Metrics Updated',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Could not update metrics',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [handleUpdate, addToast, clientId],
  );

  return (
    <Container>
      <Header>
        <h3>Metrics</h3>
        <Link
          to={{
            pathname: `/admin-client/${clientId}/metrics`,
            state: {
              extra1_title: metrics.wildcard1,
              extra2_title: metrics.wildcard2,
              enabled_metrics: metrics.options,
            },
          }}
        >
          <strong>List</strong>
        </Link>
      </Header>
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ checkbox: metrics.options }}
        >
          <Checkbox>
            <CheckboxInput name="checkbox" options={checkboxOptions} />
          </Checkbox>
          <SeparatorComponent />
          <Inputs>
            <WildcardInput1
              name="wildcard1"
              placeholder="Wildcard Metric 1"
              defaultValue={metrics.wildcard1}
            />
            <WildcardInput2
              name="wildcard2"
              placeholder="Wildcard Metric 2"
              defaultValue={metrics.wildcard2}
            />
          </Inputs>
          <div className="form-footer">
            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Adding...' : 'Save'}
            </SubmitButton>
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default MetricsSection;
