import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from './Header';

import { useAuth } from '../../../hooks/Auth';
import { useChat } from '../../../hooks/Chat';

import { Container, NavigationBar } from './styles';
import ChatBody from '../../../components/ChatBody';

interface StatusUpdateResponse {
  user_id: string;
  status: boolean;
}
const Chat: React.FC = () => {
  const { client } = useAuth();
  const { socket } = useChat();
  const history = useHistory();
  const [userStatus, setUserStatus] = useState<boolean>(false);

  const connectChat = useCallback(async () => {
    socket?.emit('connect-room', {
      token: client.chat_room,
    });
    socket?.removeListener(client.chat_room);

    socket?.on('status-update', (info: StatusUpdateResponse) => {
      if (info.user_id === client.user.id) {
        setUserStatus(info.status);
      }
    });
  }, [client.chat_room, socket, client.user.id]);

  useEffect(() => {
    connectChat();
    return () => {
      socket?.off('status-update');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <NavigationBar>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Chat</span>
        </strong>
      </NavigationBar>
      <Header
        avatar={client.user.avatar_url}
        name={client.user.name}
        status={userStatus}
      />
      <ChatBody chat_room={client.chat_room} />
    </Container>
  );
};

export default Chat;
