import React from 'react';
import ChatBody from '../../../../components/ChatBody';
import ChatRoom from '../../../../entities/ChatRoom';
import ChatWindowHeader from './Header';

import { Container } from './styles';

interface ChatWindowProps {
  room: ChatRoom;
}
const ChatWindow: React.FC<ChatWindowProps> = ({ room }) => {
  return (
    <Container>
      <ChatWindowHeader avatar={room.client_avatar} name={room.client_name} />
      <ChatBody chat_room={room.token} />
    </Container>
  );
};

export default ChatWindow;
