import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { FiStopCircle, FiFileText } from 'react-icons/fi';
import { BsListCheck } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import Client from '../../../../entities/Client';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import CalendarPopup from './CalendarPopup';
import {
  Container,
  Header,
  Content,
  ReadCheckIn,
  CaseNotes,
  Account,
  CloseAccountButton,
} from './styles';

interface SettingsSectionProps {
  client: Client;
  refreshClientWithInfo: (client: Client) => void;
}
const SettingsSection: React.FC<SettingsSectionProps> = ({
  client,
  refreshClientWithInfo,
}) => {
  const { addToast } = useToast();

  const handleSave = useCallback(
    async (date: Date) => {
      try {
        const response = await api.patch<Client>(
          `/admin-clients/${client.id}`,
          {
            status: true,
            next_reminder: format(date, 'yyyy-MM-dd'),
          },
        );
        refreshClientWithInfo(response.data);
        addToast({
          type: 'success',
          title: 'Client Reminder Renewed',
          description: 'You will be notified when this client is due renewal',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Could not update client',
          description: 'Please, try again later.',
        });
      }
    },
    [client.id, addToast, refreshClientWithInfo],
  );

  const handleDisableAccount = useCallback(async () => {
    try {
      const response = await api.patch<Client>(`/admin-clients/${client.id}`, {
        status: false,
      });

      refreshClientWithInfo(response.data);
      addToast({
        type: 'success',
        title: 'Account was deactivated',
        description: 'This client will no longer have access to this platform',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not update client',
        description: 'Please, try again later.',
      });
    }
  }, [client.id, addToast, refreshClientWithInfo]);
  return (
    <Container>
      <Header>
        <h3>Settings</h3>
      </Header>
      <Content>
        <CalendarPopup
          selected_date={new Date(client.next_reminder)}
          onSave={(date: Date) => handleSave(date)}
        />
        <ReadCheckIn>
          <Link
            to={{
              pathname: `/admin-client/${client.id}/checkin`,
              state: {
                checkin_q1: client.checkin_q1,
                checkin_q2: client.checkin_q2,
                client_name: client.name,
              },
            }}
          >
            <span>Read Checkin</span>
            <BsListCheck size={16} />
          </Link>
        </ReadCheckIn>
        <CaseNotes>
          <Link to={`/admin-client/${client.id}/case-notes`}>
            <span>Read Case notes</span>
            <FiFileText size={16} />
          </Link>
        </CaseNotes>
        {client.active && (
          <Account>
            <CloseAccountButton onClick={handleDisableAccount}>
              <span>Close account:</span>
              <FiStopCircle size={16} />
            </CloseAccountButton>
          </Account>
        )}
      </Content>
    </Container>
  );
};

export default SettingsSection;
