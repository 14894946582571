import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import NavigationHeader from '../../../components/NavigationHeader';
import UserCard from '../../../components/UserCard';
import User from '../../../entities/User';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import CreateModal from './CreateModal';

import { Container, Content } from './styles';

const Staff: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { addToast } = useToast();

  const handleOnCreate = useCallback(
    async (user) => {
      setUsers([...users, user]);
    },
    [users],
  );

  const fetchStaff = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.get<User[]>('staff');
      setUsers(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not fetch staff',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast]);

  const handleDelete = useCallback(
    async (user_id: string) => {
      try {
        await api.delete(`staff/${user_id}`);
        addToast({
          type: 'success',
          title: 'Deletion Success',
          description: `User was Delete from the platform`, // 'Your content is waiting for Admin approval.',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Could not remove staff',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
      setUsers(users.filter((user) => user.id !== user_id));
    },
    [users, addToast],
  );

  useEffect(() => {
    fetchStaff();
  }, [fetchStaff]);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Staff List</span>
        </strong>
        <CreateModal onCreate={handleOnCreate} />
      </NavigationHeader>

      {isLoading ? (
        <Loading />
      ) : (
        <Content>
          {users.map((user) => (
            <UserCard key={user.id} user={user} handleOnDelete={handleDelete} />
          ))}
        </Content>
      )}
    </Container>
  );
};

export default Staff;
