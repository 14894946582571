import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  strong {
    color: var(--primary);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
