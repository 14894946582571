import styled from 'styled-components';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  a {
    background: var(--primary);
    padding: 3px 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    strong {
      color: var(--white);
      font-size: 14px;
    }
    &:hover {
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      background-image: linear-gradient(
        61deg,
        var(--primary),
        var(--white) 249%
      );
    }
  }
`;

export const Content = styled.div`
  form {
    width: 100%;
    display: flex;

    flex-direction: column;
    align-items: center;
    .form-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  width: 100%;
`;

export const Inputs = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const WildcardInput1 = styled(Input)``;
export const WildcardInput2 = styled(Input)``;

export const SubmitButton = styled(Button)`
  height: 30px;
  width: 80px;
  font-size: 16px;

  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;
