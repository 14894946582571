import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-left: 10px;

  strong {
    font-size: 12px;
    font-weight: bold;
  }
  span {
    font-size: 10px;
  }
`;
export const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  strong {
    font-size: 14px;
  }

  span {
    font-size: 12px;
  }
`;
