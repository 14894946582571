import styled, { css } from 'styled-components';

const headerCSS = css`
  header {
    background: var(--light-background);
    color: var(--gray);
    text-align: center;
    padding: 14px 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: SofiaProRegular;
  }
`;
export const Container = styled.div`
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 15px;
  width: 100%;
  background: var(--list-background);
  ${headerCSS}
`;

export const Content = styled.div`
  padding: 0 15px;
  height: 100%;
  width: 100%;
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px 10px;
`;

export const RecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  footer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    padding: 0 8px;
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;

    strong {
      font-size: 12px;
      color: var(--gray);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    svg {
      color: var(--primary);
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      margin-left: 8px;
    }
  }
`;
