import styled from 'styled-components';
import Button from '../../../components/Button';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  width: 100%;
  min-width: 310px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const Pending = styled(Button)`
  width: 50px;
  height: 40px;
  margin-top: 0px;
  font-size: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: var(--white);
  }

  &:disabled {
    background: var(--light-background);
    svg {
      color: var(--primary);
    }
  }
`;

export const Main = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  @media (min-width: 1000px) {
    flex-flow: row wrap;
  }
`;
