import styled from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 16px;
  flex-direction: column;
  form {
    width: 100%;
    height: 100%;
  }
`;

export const User = styled.div`
  span {
    font-size: 14px;
    color: var(--gray);
  }

  strong {
    font-weight: bold;
    color: var(--primary);
  }

  margin-bottom: 20px;
`;

export const StartFrom = styled.div`
  h3 {
    margin-top: 20px;

    margin-bottom: 10px;
    font-size: 14px;
  }
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CalendarDetails = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  span {
    font-size: 12px;
    margin-top: 10px;
  }
  strong {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary);
  }
`;

export const SubmitButton = styled(Button)`
  width: 120px;

  font-size: 14px;
  float: right;

  margin-top: 0;
  height: 40px;
  &:disabled {
    background: var(--light-background);
    color: var(--primary);
  }
`;
