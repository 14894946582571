import React, { createContext, useState, useContext, useCallback } from 'react';
import socketio from 'socket.io-client';

interface ChatState {
  socket: SocketIOClient.Socket | undefined;
}

interface ChatContextState {
  socket?: SocketIOClient.Socket;
  connectSocket(
    client_socket: string,
    user_socket: string,
    type_socket: string,
  ): ChatState;
  closeSocket(): void;
}

const ChatContext = createContext<ChatContextState>({} as ChatContextState);

export const ChatProvider: React.FC = ({ children }) => {
  function connect(
    client_socket: string,
    user_socket: string,
    type_socket: string,
  ): SocketIOClient.Socket {
    const chat_url = process.env.REACT_APP_CHAT_URL ?? '';

    return socketio(chat_url, {
      reconnectionDelay: 1000,
      reconnection: true,
      agent: false,
      path: '/chat',
      upgrade: false,
      rejectUnauthorized: false,
      transports: ['websocket'],
      query: {
        client_id: type_socket === 'client' ? client_socket : '',
        user_id: type_socket === 'user' ? user_socket : '',
        type: type_socket === 'client' ? type_socket : 'staff',
      },
    });
  }

  function connectToSocket(): ChatState {
    const token = localStorage.getItem('@FFP:token');
    if (!token) {
      return { socket: undefined };
    }

    let user_json = localStorage.getItem('@FFP:user');
    let client_id = '';
    let user_id = '';
    let type = 'user';
    if (!user_json) {
      user_json = localStorage.getItem('@FFP:client');
      type = 'client';
    }
    if (user_json && type === 'client') {
      const client = JSON.parse(user_json);
      client_id = client.id;
    } else if (user_json && type === 'user') {
      const user = JSON.parse(user_json);
      user_id = user.id;
    } else {
      return {
        socket: undefined,
      };
    }

    const socket = connect(client_id, user_id, type);
    return { socket };
  }

  const [data, setData] = useState<ChatState>(() => {
    return connectToSocket();
  });

  const connectSocket = useCallback(
    (client_socket: string, user_socket: string, type_socket: string) => {
      if (!data || data.socket === undefined) {
        const socket = connect(client_socket, user_socket, type_socket);
        setData({ socket });
        return { socket };
      }
      return { socket: undefined };
    },
    [data],
  );

  const closeSocket = useCallback(() => {
    if (data?.socket !== undefined) {
      data.socket.close();
      setData({ socket: undefined });
    }

    setData({} as ChatState);
  }, [data]);

  return (
    <ChatContext.Provider
      value={{
        socket: data?.socket,
        closeSocket,
        connectSocket,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export function useChat(): ChatContextState {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }

  return context;
}
