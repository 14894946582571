import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;
export const EditRow = styled.div``;
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  background: var(--primary);
  padding: 5px 10px;
  color: var(--white);
  border-radius: 5px;

  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a svg {
    color: var(--white);
    margin-left: 10px;
  }
`;

export const Info = styled.div`
  strong {
    font-size: 14px;
  }
`;
