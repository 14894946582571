import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 5px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  background: var(--primary);
  padding: 5px 10px;
  color: var(--white);
  border-radius: 5px;
  strong {
    margin-right: 10px;

    font-size: 14px;
  }
  button {
    display: flex;
    align-items: center;
  }
  svg {
    color: var(--white);
  }
  margin-right: 8px;
  margin-bottom: 8px;
`;
