import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 21px;
  background: var(--white);
  padding: 32px 26px;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: sticky;

  top: 0;
  @media (max-width: 850px) {
    border-radius: 0;
    padding: 0 13px;
  }

  @media (max-width: 380px) {
    padding: 15px;
  }

  @media (max-width: 850px) {
    height: 77px;
  }

  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;

  align-items: center;
  flex: 1;
  justify-content: space-between;

  @media (max-width: 850px) {
    a img {
      width: 190px;
      height: 50px;
    }
  }

  @media (max-width: 550px) {
    a img {
      width: 180px;
      height: 50px;
    }
  }

  @media (max-width: 420px) {
    a img {
      width: 130px;
      height: 35px;
    }
  }

  img {
    width: 293px;
    height: 77px;
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    overflow: hidden;
    position: relative;
  }
  a:hover {
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      background: var(--gray);
      opacity: 0.3;
      z-index: 997;
      width: 100%;
      height: 100%;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      @media (max-width: 850px) {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
    }
  }
`;

export const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  strong {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: right;
  }

  button {
    font-size: 16px;
    align-self: flex-end;
    background: none;
    border: 0;
    color: var(--primary);
  }

  @media (max-width: 850px) {
    strong {
      font-size: 14px;
    }

    button {
      font-size: 12px;
    }
  }
`;

export const Avatar = styled.div`
  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    object-fit: cover;
  }

  @media (max-width: 850px) {
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
`;
