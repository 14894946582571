import React, { useState, useCallback, useRef } from 'react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';
import {
  Container,
  StyledModal,
  CreateNew,
  Header,
  Content,
  CloseButton,
  SubmitButton,
} from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/Toast';
import Input from '../../Input';
import TextArea from '../../TextArea';
import KeyPrinciple from '../../../entities/KeyPrinciple';

interface EditKeyPrincipleFormData {
  principle: string;
  why: string;
}

interface EditModalProps {
  principle: KeyPrinciple;
  onEdit: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ principle, onEdit }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: EditKeyPrincipleFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          principle: Yup.string().required('Principle is Required'),
          why: Yup.string().required('Why is Required'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const body = {
          principle: data.principle,
          why: data.why,
        };
        await api.put<KeyPrinciple>(`key-principles/${principle.id}`, body);
        onEdit();
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Could not Edit Key Principle',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, handleToggle, onEdit, principle.id],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiEdit size={14} color="#ffffff" />
      </CreateNew>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Edit Client Key Principle</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <Input
              name="principle"
              placeholder="Principle"
              defaultValue={principle.principle}
            />
            <TextArea
              name="why"
              placeholder="Why"
              defaultValue={principle.why}
            />

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </SubmitButton>
          </Form>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default EditModal;
