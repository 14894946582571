import React, { useCallback, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import NavigationHeader from '../../../../components/NavigationHeader';

import {
  Container,
  Content,
  User,
  StartFrom,
  CalendarDetails,
  SubmitButton,
} from './styles';
import Input from '../../../../components/Input';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import Nutrition from '../../../../entities/Nutrition';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Calendar from '../../../../components/Calendar';

interface CreateNutritionFormData {
  title: string;
}

interface CreateNutritionParams {
  client_id: string;
}

interface CreateNutritionState {
  client_name: string;
}

const CreateNutrition: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { client_id } = useParams<CreateNutritionParams>();
  const { state } = useLocation<CreateNutritionState>();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const handleDateChanged = useCallback((day: Date) => {
    setSelectedDate(day);
  }, []);

  const handleSubmit = useCallback(
    async (data: CreateNutritionFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is Required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          client_id,
          title: data.title,
          start_date: format(selectedDate, 'yyyy-MM-dd'),
        };

        // CREATE CONTENT
        const response = await api.post<Nutrition>('/nutritions', body);
        const nutrition = response.data;
        addToast({
          type: 'success',
          title: 'Creation Success',
          description: `Nutrition created, now you can add more details`,
        });

        history.push({
          pathname: `/admin-client/nutrition/${nutrition.id}`,
          state: {
            returnToProfile: true,
            client_name: state.client_name,
            nutrition,
            available_at: new Date(nutrition.start_date),
          },
        });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, history, selectedDate, client_id, state.client_name],
  );
  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button type="button" onClick={() => history.push('/admin-clients')}>
            Clients /
          </button>
          <button type="button" onClick={() => history.goBack()}>
            Profile /
          </button>
          <span>Nutrition</span>
        </strong>
      </NavigationHeader>
      <Content>
        <User>
          <span>New nutrition for: </span>
          <strong>{state.client_name}</strong>
        </User>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input title="Title" name="title" placeholder="Enter text" />
          <StartFrom>
            <h3>Starts on:</h3>
            <CalendarDetails>
              <Calendar onDayClick={handleDateChanged} />
              <span>
                This nutrition will be available from:{' '}
                <strong>{format(selectedDate, 'dd/MM/yyyy')}</strong>
              </span>
            </CalendarDetails>
          </StartFrom>
          <SubmitButton type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create'}
          </SubmitButton>
        </Form>
      </Content>
    </Container>
  );
};

export default CreateNutrition;
