import React from 'react';

import { Container, Card, Header, Body } from './styles';

interface RecommendationProps {
  protein?: string;
  fats?: string;
  carbs?: string;
  calories?: string;
}
const Recommendation: React.FC<RecommendationProps> = ({
  protein,
  fats,
  carbs,
  calories,
}) => {
  return (
    <Container>
      {protein && (
        <Card>
          <Header>
            <span>Protein</span>
          </Header>
          <Body>
            <span>{protein}g</span>
          </Body>
        </Card>
      )}
      {fats && (
        <Card>
          <Header>
            <span>Fats</span>
          </Header>
          <Body>
            <span>{fats}g</span>
          </Body>
        </Card>
      )}
      {carbs && (
        <Card>
          <Header>
            <span>Carbs</span>
          </Header>
          <Body>
            <span>{carbs}g</span>
          </Body>
        </Card>
      )}
      {calories && (
        <Card>
          <Header>
            <span>Calories</span>
          </Header>
          <Body>
            <span>{calories}kcal</span>
          </Body>
        </Card>
      )}
    </Container>
  );
};

export default Recommendation;
