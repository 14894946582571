export default function getTypeFromExtension(extension: string): string {
  switch (extension) {
    case 'jpeg':
    case 'png':
    case 'jpg':
      return 'jpg';
    case 'doc':
    case 'docx':
      return 'docx';
    case 'pdf':
      return 'pdf';
    case 'ppt':
    case 'pptx':
      return 'pptx';
    case 'xls':
    case 'xlsx':
      return 'xlsx';
    case 'mp4':
    case 'mov':
    case 'mp3':
    case 'avi':
      return 'video';

    default:
      return 'file';
  }
}
