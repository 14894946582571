import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background: var(--list-background);
  margin: 10px 0;

  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: var(--gray);
  background: var(--light-background);
  padding-right: 15px;
  h3 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0;
  }
`;

export const Content = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--gray);
  border-radius: 5px;
  flex: 1;
  background: var(--list-background);
  margin-bottom: 10px;
  overflow: hidden;
`;

interface RowProps {
  $shouldShowDetails: boolean;
}
const rotateArrowUp = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
`;
const rotateArrowDown = keyframes`
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
`;
export const RowHeader = styled.div<RowProps>`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;

  justify-content: space-between;
  border-bottom: none;
  cursor: pointer;
  .row-header-info {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    flex: 1;
    background: var(--super-light-gray);
  }
  span {
    font-size: 14px;
    min-width: 120px;
    max-width: 120px;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--white);
    flex: 1;
    padding-left: 15px;
  }
  strong {
    font-size: 12px;
    font-weight: bold;
    background: var(--list-background);
    height: 100%;
    width: 100%;
    padding: 5px 8px;
  }
  svg {
    width: 20px;
    margin-left: 15px;
    animation: ${rotateArrowDown} 0.5s;
    animation-fill-mode: forwards;
    margin-right: 10px;
    color: var(--gray);
  }
  ${(props) =>
    props.$shouldShowDetails &&
    css`
      border-bottom: 1px solid var(--light-background);
      .row-header-info {
        background: var(--primary);
      }

      svg {
        animation: ${rotateArrowUp} 0.5s;
        animation-fill-mode: forwards;
        color: var(--primary);
      }
    `}
`;

export const RowBody = styled.div<RowProps>`
  display: none;
  margin-bottom: 10px;
  ${(props) =>
    props.$shouldShowDetails &&
    css`
      display: flex;
    `}

  flex-direction: column;
  justify-content: space-between;

  .column {
    display: flex;
    width: 100%;

    strong {
      height: 30px;
      padding-top: 10px;
    }
    span {
      padding-top: 10px;
    }
  }

  span {
    font-size: 12px;

    padding: 5px 8px;
  }
  strong {
    min-width: 120px;
    max-width: 120px;
    font-size: 16px;
    font-weight: bold;

    padding-left: 15px;
    border-right: 1px solid var(--light-background);
  }

  .content-title {
    font-size: 12px;
  }
`;
