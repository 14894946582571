import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
`;

export const ClientName = styled.div`
  strong {
    font-size: 14px;
    color: var(--gray);
    span {
      color: var(--primary);
    }
  }
`;

export const Card = styled.div`
  border-radius: 5px;

  overflow: hidden;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 10px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: var(--light-background);
  padding: 0 8px;
  h5 {
    font-size: 14px;
    font-weight: bold;
    margin: 8px 0;
  }
  strong {
    font-size: 12px;
  }
  span {
    font-weight: bold;
    margin-bottom: 8px;
  }
`;
interface FooterProps {
  $isActive: boolean;
}
export const CardFooter = styled.div<FooterProps>`
  background: var(--primary);
  height: 40px;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    width: 100%;
    height: 100%;
    transition: background-color 0.2s;
    text-decoration: none;
    &:hover {
      color: var(--white);
      background: var(--dark-primary);
    }
    &:visited {
      color: var(--white);
    }

    ${(props) =>
      !props.$isActive &&
      css`
        background-color: var(--error);
        &:hover {
          background: ${darken(0.1, '#ff778e')};
        }
      `}
  }
`;
