import React, { useCallback, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiSend } from 'react-icons/fi';

import { Container, MessageInput, SendButton } from './styles';

interface ChatInputFormData {
  message: string;
}

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  isConnected: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  isConnected,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [hasText, setHasText] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (data: ChatInputFormData, { reset }) => {
      if (data.message.trim() !== '') {
        onSendMessage(data.message);
        reset();
        setHasText(false);
      }
    },
    [onSendMessage],
  );

  const handleTextChanged = useCallback(
    (data) => {
      if (data.target.value !== '' && !hasText) {
        setHasText(true);
      } else if (data.target.value === '') {
        setHasText(false);
      }
    },
    [hasText],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <MessageInput
          type="text"
          name="message"
          placeholder="Enter message"
          disabled={!isConnected}
          onChange={handleTextChanged}
        />
        <SendButton
          $hasText={hasText}
          disabled={!hasText || !isConnected}
          type="submit"
        >
          <FiSend size={26} />
        </SendButton>
      </Form>
    </Container>
  );
};

export default ChatInput;
