import { addDays, format, isAfter, isBefore, subDays } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import NavigationHeader from '../../../components/NavigationHeader';
import { Checkin, CheckinItem } from '../../../entities/Checkin';
import Dictionary from '../../../entities/Dictionary';
import {
  Container,
  Content,
  Header,
  Week,
  BackButton,
  ForwardButton,
  Questions,
  Question,
  Answer,
  SubmitButton,
} from './styles';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import TextArea from '../../../components/TextArea';
import getValidationErrors from '../../../utils/getValidationErrors';

interface AnswerFormData {
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
  question6: string;
  question7: string;
  question8?: string;
  question9?: string;
}

const ClientCheckin: React.FC = () => {
  const history = useHistory();
  const { client } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { addToast } = useToast();
  const [checkins, setCheckins] = useState<Dictionary<CheckinItem>>(
    {} as Dictionary<CheckinItem>,
  );
  const [checkin, setCheckin] = useState<Checkin | undefined>(undefined);
  const [isCurrentWeek, setIsCurrentWeek] = useState(true);

  const handleRefresh = useCallback(
    async (start_date: string) => {
      try {
        setIsLoading(true);
        const response = await api.get<Checkin>(
          `/checkins/client/${client.id}`,
          {
            params: {
              start_date,
            },
          },
        );
        const info = response.data;
        const dictionary: Dictionary<CheckinItem> = {
          question1: {
            id: uuid(),
            question: info.question_1,
            answer: info.answer_1,
          },
          question2: {
            id: uuid(),
            question: info.question_2,
            answer: info.answer_2,
          },
          question3: {
            id: uuid(),
            question: info.question_3,
            answer: info.answer_3,
          },
          question4: {
            id: uuid(),
            question: info.question_4,
            answer: info.answer_4,
          },
          question5: {
            id: uuid(),
            question: info.question_5,
            answer: info.answer_5,
          },
          question6: {
            id: uuid(),
            question: info.question_6,
            answer: info.answer_6,
          },
          question7: {
            id: uuid(),
            question: info.question_7,
            answer: info.answer_7,
          },
        };

        if (info.question_8) {
          Object.assign(dictionary, {
            question8: {
              id: uuid(),
              question: info.question_8,
              answer: info.answer_8 ?? '',
            },
          });
        }

        if (info.question_9) {
          Object.assign(dictionary, {
            question9: {
              id: uuid(),
              question: info.question_9,
              answer: info.answer_9 ?? '',
            },
          });
        }
        setCheckins(dictionary);
        setCheckin(info);

        isAfter(new Date(), new Date(info.start_date)) &&
        isBefore(new Date(), new Date(info.end_date))
          ? setIsCurrentWeek(true)
          : setIsCurrentWeek(false);

        setIsLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Load Failed',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [client.id, addToast],
  );

  useEffect(() => {
    handleRefresh(format(new Date(), 'yyyy-MM-dd'));
  }, [handleRefresh]);

  const getPreviousWeek = useCallback(
    (start_date: string) => {
      handleRefresh(format(subDays(new Date(start_date), 3), 'yyyy-MM-dd'));
    },
    [handleRefresh],
  );

  const getNextWeek = useCallback(
    (end_date: string) => {
      handleRefresh(format(addDays(new Date(end_date), 3), 'yyyy-MM-dd'));
    },
    [handleRefresh],
  );

  const handleSubmit = useCallback(
    async (data: AnswerFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          question1: Yup.string(),
          question2: Yup.string(),
          question3: Yup.string(),
          question4: Yup.string(),
          question5: Yup.string(),
          question6: Yup.string(),
          question7: Yup.string(),
          question8: Yup.string(),
          question9: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          answer_1: data.question1,
          answer_2: data.question2,
          answer_3: data.question3,
          answer_4: data.question4,
          answer_5: data.question5,
          answer_6: data.question6,
          answer_7: data.question7,
        };

        if (checkin?.question_8) {
          Object.assign(body, {
            answer_8: data.question8,
          });
        }

        if (checkin?.question_9) {
          Object.assign(body, {
            answer_9: data.question9,
          });
        }

        const response = await api.post<Checkin>(`/checkins`, body);

        setCheckin(response.data);
        addToast({
          type: 'success',
          title: 'Update Success',
        });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Update Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, checkin],
  );

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Check in</span>
        </strong>
        <SubmitButton
          form="checkin-answer"
          type="submit"
          disabled={isLoading || !isCurrentWeek}
        >
          {isLoading ? 'Updating...' : 'Save'}
        </SubmitButton>
      </NavigationHeader>
      {checkin && (
        <Content>
          <Header>
            <h2>Check in for week</h2>
            <Week>
              <BackButton onClick={() => getPreviousWeek(checkin.start_date)}>
                <FiArrowLeft />
              </BackButton>
              <strong>
                {format(new Date(checkin.start_date), 'dd/MM/yyyy')} -{' '}
                {format(new Date(checkin.end_date), 'dd/MM/yyyy')}
              </strong>
              <ForwardButton onClick={() => getNextWeek(checkin.end_date)}>
                <FiArrowRight />
              </ForwardButton>
            </Week>
          </Header>
          <Questions>
            <Form id="checkin-answer" ref={formRef} onSubmit={handleSubmit}>
              {Object.entries(checkins).map(([index, value]) => (
                <Question key={value.id}>
                  <strong>{value.question}</strong>
                  <Answer>
                    <TextArea
                      name={index}
                      placeholder="Enter your answer"
                      defaultValue={value.answer}
                      readOnly={!isCurrentWeek}
                    />
                  </Answer>
                </Question>
              ))}
            </Form>
          </Questions>
        </Content>
      )}
    </Container>
  );
};

export default ClientCheckin;
