import { format } from 'date-fns';
import React from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import EditNutrition from '../../../../components/Nutrition/EditNutrition';
import DeleteConfirmation from '../../../../components/Nutrition/DeleteConfirmation';
import Nutrition from '../../../../entities/Nutrition';

import {
  Container,
  Header,
  ActionButtons,
  ShowPreviousLink,
  CreateNewLink,
  Content,
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from './styles';

interface NutritionSectionProps {
  client_id: string;
  nutrition?: Nutrition;
  future_nutrition?: Nutrition;
  client_name: string;
  handleDeleteNutrition: (nutrition_id: string, isFuture: boolean) => void;
  handleEditNutrition: (nutrition: Nutrition, isFuture: boolean) => void;
}

const NutritionSection: React.FC<NutritionSectionProps> = ({
  client_id,
  nutrition,
  future_nutrition,
  client_name,
  handleDeleteNutrition,
  handleEditNutrition,
}) => {
  return (
    <Container>
      <Header>
        <h3>Nutrition</h3>
        <ActionButtons>
          <ShowPreviousLink
            to={{
              pathname: `/admin-client/${client_id}/past-nutrition`,
              state: {
                client_name,
              },
            }}
          >
            <strong>Previous</strong>
          </ShowPreviousLink>

          <CreateNewLink
            to={{
              pathname: `/admin-client/${client_id}/new-nutrition`,
              state: {
                client_name,
              },
            }}
          >
            <FiPlusCircle size={19} color="#ffffff" />
          </CreateNewLink>
        </ActionButtons>
      </Header>
      <Content>
        {nutrition && (
          <Card $future={false}>
            <CardHeader>
              <EditNutrition
                nutrition={nutrition}
                onEdit={
                  (new_nutrition: Nutrition) =>
                    handleEditNutrition(new_nutrition, false)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
              <DeleteConfirmation
                id={nutrition.id}
                title={nutrition.title}
                onDelete={
                  () => handleDeleteNutrition(nutrition.id, false)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            </CardHeader>
            <CardContent>
              <h5>{nutrition.title}</h5>
              <strong>Available on:</strong>
              <span>
                {format(new Date(nutrition.start_date), 'dd/MM/yyyy')}
              </span>
            </CardContent>
            <CardFooter $future={false}>
              <Link
                to={{
                  pathname: `/admin-client/nutrition/${nutrition.id}`,
                  state: {
                    returnToProfile: true,
                    client_name,
                    nutrition,
                    available_at: new Date(nutrition.start_date),
                  },
                }}
              >
                Details
              </Link>
            </CardFooter>
          </Card>
        )}

        {future_nutrition && (
          <Card $future>
            <CardHeader>
              <EditNutrition
                nutrition={future_nutrition}
                onEdit={
                  (new_nutrition: Nutrition) =>
                    handleEditNutrition(new_nutrition, true)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
              <strong>Future</strong>
              <DeleteConfirmation
                id={future_nutrition.id}
                title={future_nutrition.title}
                onDelete={
                  () => handleDeleteNutrition(future_nutrition.id, true)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            </CardHeader>
            <CardContent>
              <h5>{future_nutrition.title}</h5>
              <strong>Available on:</strong>
              <span>
                {format(new Date(future_nutrition.start_date), 'dd/MM/yyyy')}
              </span>
            </CardContent>
            <CardFooter $future>
              <Link
                to={{
                  pathname: `/admin-client/nutrition/${future_nutrition.id}`,
                  state: {
                    returnToProfile: true,
                    client_name,
                    nutrition: future_nutrition,
                    available_at: new Date(future_nutrition.start_date),
                  },
                }}
              >
                Details
              </Link>
            </CardFooter>
          </Card>
        )}
      </Content>
    </Container>
  );
};

export default NutritionSection;
