import React, { useState, useCallback } from 'react';
import {
  Container,
  StyledModal,
  Delete,
  Content,
  Actions,
  DeleteButton,
  Cancel,
} from './styles';

interface DeleteStaffConfirmationProps {
  id: string;
  name: string;
  onDelete: (id: string) => void;
}

const DeleteStaffConfirmation: React.FC<DeleteStaffConfirmationProps> = ({
  id,
  name,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDelete = useCallback(() => {
    onDelete(id);
    handleToggle();
  }, [id, handleToggle, onDelete]);

  return (
    <Container>
      <Delete onClick={handleToggle}>Delete</Delete>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <h1>Are you sure you want to delete:</h1>
          <strong>{name}</strong>
          <span>This user will not be able to access the portal anymore.</span>
          <Actions>
            <Cancel onClick={handleToggle}>Cancel</Cancel>
            <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
          </Actions>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default DeleteStaffConfirmation;
