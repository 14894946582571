import { format, isBefore } from 'date-fns';
import React, { useCallback } from 'react';
import MealPlan from '../../../../entities/MealPlan';
import api from '../../../../services/api';
import CreateModal from './CreateModal';
import DeleteConfirmation from './DeleteConfirmation';
import EditModal from './EditModal';
import {
  Container,
  Header,
  ActionButtons,
  Content,
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Separator,
} from './styles';

interface MealPlansSectionProps {
  client_id: string;
  meal_plans: MealPlan[];
  handleRefreshMealPlans: (meal_plan?: MealPlan) => void;
}

const MealPlanSection: React.FC<MealPlansSectionProps> = ({
  client_id,
  meal_plans,
  handleRefreshMealPlans,
}) => {
  const handleOnCreate = useCallback(
    (meal_plan) => {
      handleRefreshMealPlans(meal_plan);
    },
    [handleRefreshMealPlans],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await api.delete(`meal-planners/${id}`);
      handleRefreshMealPlans();
    },
    [handleRefreshMealPlans],
  );

  function isAvailable(available_date: string): boolean {
    if (isBefore(new Date(available_date), new Date())) {
      return true;
    }
    return false;
  }

  return (
    <Container>
      <Header>
        <h3>Meal Plans</h3>
        <ActionButtons>
          <CreateModal client_id={client_id} onCreate={handleOnCreate} />
        </ActionButtons>
      </Header>
      <Content>
        {meal_plans.map((meal_plan) => (
          <Card key={meal_plan.id}>
            <CardHeader>
              <EditModal
                meal_plan={meal_plan}
                onEdit={handleRefreshMealPlans}
              />
              <DeleteConfirmation
                id={meal_plan.id}
                title={meal_plan.title}
                onDelete={handleDelete}
              />
            </CardHeader>
            <CardContent
              href={meal_plan.meal_planner_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={meal_plan.thumbnail_url} alt={meal_plan.title} />
            </CardContent>
            <CardFooter $available={isAvailable(meal_plan.available_date)}>
              <strong>{meal_plan.title}</strong>
              <Separator />
              <span>Available on:</span>
              <span>
                {format(new Date(meal_plan.available_date), 'dd/MM/yyyy')}
              </span>
            </CardFooter>
          </Card>
        ))}
      </Content>
    </Container>
  );
};

export default MealPlanSection;
