import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  UserPath,
  Wrapper,
  Goals,
  Nutrition,
  Chat,
  Resources,
} from './styles';

import {
  white_goalsIcon,
  white_recipe,
  white_chat,
  white_sharedResources,
} from '../../../styles/icons';

const Home: React.FC = () => {
  return (
    <Container>
      <UserPath>
        <strong>
          Dashboard / <span>Home</span>
        </strong>
      </UserPath>
      <div className="wrapper-body">
        <Wrapper>
          <Link to="/client-goals">
            <Goals>
              <img src={white_goalsIcon} alt="Your goals" />
              <strong>Goals</strong>
            </Goals>
          </Link>
          <Link to="/client-nutrition">
            <Nutrition>
              <img src={white_recipe} alt="Your goals" />
              <strong>Nutrition</strong>
            </Nutrition>
          </Link>
          <Link to="/client-chat">
            <Chat>
              <img src={white_chat} alt="Your goals" />
              <strong>Chat</strong>
            </Chat>
          </Link>
          <Link to="/client-resources-all">
            <Resources>
              <img src={white_sharedResources} alt="Your goals" />
              <strong>Resource</strong>
            </Resources>
          </Link>
        </Wrapper>
      </div>
    </Container>
  );
};

export default Home;
