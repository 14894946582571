import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';

export const Container = styled.div``;
export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  a {
    background: var(--primary);
    padding: 3px 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    strong {
      color: var(--white);
      font-size: 14px;
    }
    &:hover {
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      background-image: linear-gradient(
        61deg,
        var(--primary),
        var(--white) 249%
      );
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const ShowPreviousLink = styled(Link)`
  height: 30px;
  padding: 0;
  margin-top: 0px;
  margin-right: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 19px;
  }
`;
export const CreateNewLink = styled(Link)`
  height: 30px;
  width: 40px;
  padding: 0;
  margin-top: 0px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 19px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
`;
interface CardProps {
  $future: boolean;
}
export const Card = styled.div<CardProps>`
  border: 2px solid var(--light-background);
  border-radius: 5px;
  padding: 8px;

  min-width: 200px;

  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) =>
    props.$future &&
    css`
      margin-left: 10px;
    `}
`;

export const CardHeader = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: var(--light-background);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  h5 {
    font-size: 14px;
    font-weight: bold;
    margin: 8px 0;
  }
  strong {
    font-size: 12px;
  }
  span {
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

export const CardFooter = styled.div<CardProps>`
  background: var(--primary);
  height: 40px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  a {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    width: 100%;
    height: 100%;
    transition: background-color 0.2s;
    text-decoration: none;
    &:hover {
      color: var(--white);
      background: var(--dark-primary);
    }
    &:visited {
      color: var(--white);
    }
  }
  ${(props) =>
    props.$future &&
    css`
      background-color: var(--gray);
      a {
        &:hover {
          background: ${darken(0.1, '#717171')};
        }
      }
    `}
`;
