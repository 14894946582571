import React, { useState, useCallback, useRef } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';
import {
  Container,
  StyledModal,
  CreateNew,
  Header,
  Content,
  CloseButton,
  SubmitButton,
} from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/Toast';
import Input from '../../Input';
import TextArea from '../../TextArea';
import KeyPrinciple from '../../../entities/KeyPrinciple';

interface CreateKeyPrincipleFormData {
  principle: string;
  why: string;
  client_id: string;
}

interface CreateModalProps {
  client_id: string;
  onCreate: (key_principle: KeyPrinciple) => void;
}

const CreateModal: React.FC<CreateModalProps> = ({ client_id, onCreate }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: CreateKeyPrincipleFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          principle: Yup.string().required('Principle is Required'),
          why: Yup.string().required('Why is Required'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const body = {
          principles: [
            {
              client_id,
              principle: data.principle,
              why: data.why,
            },
          ],
        };
        const response = await api.post<KeyPrinciple[]>(`key-principles`, body);
        onCreate(response.data[0]);
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Key Principle was not created',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, handleToggle, onCreate, client_id],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={30} color="#ffffff" />
      </CreateNew>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Create Client Key Principle</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <Input name="principle" placeholder="Principle" />
            <TextArea name="why" placeholder="Why" />

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Adding...' : 'Add'}
            </SubmitButton>
          </Form>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default CreateModal;
