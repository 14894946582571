import styled, { css } from 'styled-components';

import Input from '../../Input';

export const Container = styled.div`
  display: flex;
  padding: 10px 0;
  border-top: 1px solid var(--light-background);
  form {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 20px;
  }
`;

export const MessageInput = styled(Input)`
  margin-right: 10px;
`;
interface SendButtonProps {
  $hasText: boolean;
}

export const SendButton = styled.button<SendButtonProps>`
  width: 26px;
  height: 26px;
  color: var(--gray);
  cursor: pointer;

  ${(props) =>
    props.$hasText &&
    css`
      svg {
        color: var(--dark-primary);
      }
    `}
`;
