import React from 'react';
import { Container, Content } from './styles';
import ClientCard from '../../../../components/ClientCard';
import User from '../../../../entities/User';

interface DueRenewProps {
  users: User[];
}
const DueRenew: React.FC<DueRenewProps> = ({ users }) => {
  return (
    <Container>
      <strong>User Due Renewal</strong>
      <Content>
        {users
          .sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
          )
          .map((user) => (
            <ClientCard
              key={user.id}
              user={user}
              redirect_to={`/admin-client-profile/${user.id}`}
            />
          ))}
      </Content>
    </Container>
  );
};

export default DueRenew;
