import React, { useState, useCallback, useRef } from 'react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format } from 'date-fns';
import {
  Container,
  CreateNew,
  Header,
  Content,
  CloseButton,
  StartFrom,
  CalendarDetails,
  SubmitButton,
} from './styles';
import Nutrition from '../../../entities/Nutrition';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import Modal from '../../Modal';
import Input from '../../Input';
import getValidationErrors from '../../../utils/getValidationErrors';
import Calendar from '../../Calendar';

interface EditNutritionFormData {
  title: string;
}

interface EditModalProps {
  nutrition: Nutrition;
  onEdit: (nutrition: Nutrition) => void;
}

const EditNutrition: React.FC<EditModalProps> = ({ nutrition, onEdit }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date(nutrition.start_date),
  );

  const handleDateChanged = useCallback((day: Date) => {
    setSelectedDate(day);
  }, []);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: EditNutritionFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is Required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          title: data.title,
          start_date: format(selectedDate, 'yyyy-MM-dd'),
        };

        // CREATE CONTENT
        const response = await api.put<Nutrition>(
          `/nutritions-admin-client/${nutrition.id}`,
          body,
        );

        addToast({
          type: 'success',
          title: 'Update Success',
        });
        onEdit(response.data);
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Update Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, handleToggle, onEdit, nutrition.id, selectedDate],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiEdit size={14} color="#ffffff" />
      </CreateNew>
      <Modal isOpen={isOpen} handleToggle={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Edit Nutrition</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <Input
              title="Title"
              name="title"
              placeholder="Enter text"
              defaultValue={nutrition.title}
            />
            <StartFrom>
              <h3>Starts on:</h3>
              <CalendarDetails>
                <Calendar
                  onDayClick={handleDateChanged}
                  startDate={selectedDate}
                />
                <span>
                  This nutrition will be available from:{' '}
                  <strong>{format(selectedDate, 'dd/MM/yyyy')}</strong>
                </span>
              </CalendarDetails>
            </StartFrom>
            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </SubmitButton>
          </Form>
        </Content>
      </Modal>
    </Container>
  );
};

export default EditNutrition;
