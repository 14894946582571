import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Chart from '../../../components/Chart';
import PhotoSection from '../../../components/PhotoSection';
import GoalsSection from '../../../components/GoalsSection';
import Loading from '../../../components/Loading';
import NavigationHeader from '../../../components/NavigationHeader';
import Goal from '../../../entities/Goal';
import Metric from '../../../entities/Metric';
import { useAuth } from '../../../hooks/Auth';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import getChartDataFromMetrics from '../../../utils/getChartDataFromMetrics';
import getRandomColor from '../../../utils/getRandomColor';

import { Container, Content, Metrics, MetricsContent } from './styles';
import Photo from '../../../entities/Photo';
import AddPhotoHeader from './AddPhotoHeader';

interface MetricsModel {
  weights: Metric[];
  waists: Metric[];
  chests: Metric[];
  hips: Metric[];
  thighs: Metric[];
  biceps: Metric[];
  metrics_op1: Metric[];
  metrics_op1_title: string;
  metrics_op2: Metric[];
  metrics_op2_title: string;
}

const Goals: React.FC = () => {
  const history = useHistory();
  const { client } = useAuth();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [metrics, setMetrics] = useState<MetricsModel | undefined>(undefined);
  const [weightsList, setWeightsList] = useState<Metric[]>([]);
  const [waistsList, setWaistsList] = useState<Metric[]>([]);
  const [chestsList, setChestsList] = useState<Metric[]>([]);
  const [hipsList, setHipsList] = useState<Metric[]>([]);
  const [thighList, setThighList] = useState<Metric[]>([]);
  const [bicepsList, setBicepsList] = useState<Metric[]>([]);
  const [metricOp1List, setMetricOp1List] = useState<Metric[]>([]);
  const [metricOp2List, setMetricOp2List] = useState<Metric[]>([]);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [photo, setPhoto] = useState<Photo | undefined>(undefined);

  useEffect(() => {
    if (metrics) {
      setWeightsList(metrics.weights);
      setWaistsList(metrics.waists);
      setChestsList(metrics.chests);
      setHipsList(metrics.hips);
      setThighList(metrics.thighs);
      setBicepsList(metrics.biceps);
      setMetricOp1List(metrics.metrics_op1);
      setMetricOp2List(metrics.metrics_op2);
    }
  }, [metrics]);

  const fetchGoals = useCallback(async () => {
    try {
      const response = await api.get<Goal[]>(`goals/`);
      setGoals(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not fetch goals',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast]);

  const fetchPhoto = useCallback(async () => {
    try {
      const response = await api.get<Photo>(
        `client-photos/current/${client.id}`,
      );
      setPhoto(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not fetch Photos',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast, client.id]);

  const fetchMetrics = useCallback(async () => {
    try {
      const response = await api.get<MetricsModel>(`metrics/${client.id}`, {
        params: {
          list_all: false,
        },
      });
      setMetrics(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not fetch metrics',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast, client.id]);

  const weights = useMemo(() => {
    return weightsList
      ? getChartDataFromMetrics(weightsList, 'weights')
      : undefined;
  }, [weightsList]);

  const waists = useMemo(() => {
    return waistsList
      ? getChartDataFromMetrics(waistsList, 'waists')
      : undefined;
  }, [waistsList]);

  const chests = useMemo(() => {
    return chestsList
      ? getChartDataFromMetrics(chestsList, 'chests')
      : undefined;
  }, [chestsList]);

  const hips = useMemo(() => {
    return hipsList ? getChartDataFromMetrics(hipsList, 'hips') : undefined;
  }, [hipsList]);

  const thighs = useMemo(() => {
    return thighList ? getChartDataFromMetrics(thighList, 'thighs') : undefined;
  }, [thighList]);

  const biceps = useMemo(() => {
    return bicepsList
      ? getChartDataFromMetrics(bicepsList, 'biceps')
      : undefined;
  }, [bicepsList]);

  const metrics_op1s = useMemo(() => {
    if (metricOp1List) {
      return {
        metrics: getChartDataFromMetrics(metricOp1List, 'metrics_op1'),
        title: metrics?.metrics_op1_title ?? '',
      };
    }
    return undefined;
  }, [metricOp1List, metrics]);

  const metrics_op2s = useMemo(() => {
    if (metricOp2List) {
      return {
        metrics: getChartDataFromMetrics(metricOp2List, 'metrics_op2'),
        title: metrics?.metrics_op2_title ?? '',
      };
    }
    return undefined;
  }, [metricOp2List, metrics]);

  useEffect(() => {
    fetchGoals();
    fetchPhoto();
    fetchMetrics();
  }, [fetchGoals, fetchPhoto, fetchMetrics]);

  const metricAdded = useCallback(
    (new_metric: Metric, metric: string) => {
      switch (metric) {
        case 'weights':
          if (metrics) {
            setWeightsList(
              [...weightsList, new_metric].sort((a, b) =>
                a.date < b.date ? -1 : 1,
              ),
            );
          }
          break;
        case 'waists':
          if (metrics) {
            setWaistsList(
              [...waistsList, new_metric].sort((a, b) =>
                a.date < b.date ? -1 : 1,
              ),
            );
          }
          break;
        case 'chests':
          if (metrics) {
            setChestsList(
              [...chestsList, new_metric].sort((a, b) =>
                a.date < b.date ? -1 : 1,
              ),
            );
          }
          break;
        case 'hips':
          if (metrics) {
            setHipsList(
              [...hipsList, new_metric].sort((a, b) =>
                a.date < b.date ? -1 : 1,
              ),
            );
          }
          break;
        case 'thighs':
          if (metrics) {
            const new_thighs = metrics;
            new_thighs.thighs = [...metrics.thighs, new_metric].sort((a, b) =>
              a.date < b.date ? -1 : 1,
            );
            setMetrics(new_thighs);
          }
          break;
        case 'biceps':
          if (metrics) {
            setBicepsList(
              [...bicepsList, new_metric].sort((a, b) =>
                a.date < b.date ? -1 : 1,
              ),
            );
          }
          break;
        case 'ops1':
          if (metrics) {
            setMetricOp1List(
              [...metricOp1List, new_metric].sort((a, b) =>
                a.date < b.date ? -1 : 1,
              ),
            );
          }
          break;
        case 'ops2':
          if (metrics) {
            setMetricOp2List(
              [...metricOp2List, new_metric].sort((a, b) =>
                a.date < b.date ? -1 : 1,
              ),
            );
          }
          break;
        default:
          break;
      }
    },
    [
      metrics,
      waistsList,
      weightsList,
      chestsList,
      hipsList,
      metricOp1List,
      metricOp2List,
      bicepsList,
    ],
  );

  const handleOnCreate = useCallback(() => {
    fetchPhoto();
  }, [fetchPhoto]);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Goals & Metrics</span>
        </strong>
      </NavigationHeader>
      {isLoading ? (
        <Loading />
      ) : (
        <Content>
          <GoalsSection
            client={client}
            goals={goals}
            handleRefreshGoals={fetchGoals}
            isAdmin={false}
          />
          {photo ? (
            <PhotoSection
              title="Current Photos"
              show_past_photos
              photo={photo}
              show_delete_photo={false}
              id={photo.id}
              client_id={client.id}
            />
          ) : (
            <AddPhotoHeader title="Current Photos" onCreate={handleOnCreate} />
          )}

          <Metrics>
            <div className="header">
              <strong>Metrics</strong>
              <Link to="/client-metrics">List All</Link>
            </div>
            <MetricsContent>
              {weights && (
                <Chart
                  key={weights.title}
                  data={weights.values}
                  metric={weights.title}
                  title="Weight"
                  primary_color={getRandomColor()}
                  handleMetricAdded={
                    (metric: Metric) => metricAdded(metric, weights.title)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              )}
              {waists && (
                <Chart
                  key={waists.title}
                  data={waists.values}
                  metric={waists.title}
                  title="Waist"
                  primary_color={getRandomColor()}
                  handleMetricAdded={
                    (metric: Metric) => metricAdded(metric, waists.title)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              )}

              {chests && (
                <Chart
                  key={chests.title}
                  data={chests.values}
                  metric={chests.title}
                  title="Chest"
                  primary_color={getRandomColor()}
                  handleMetricAdded={
                    (metric: Metric) => metricAdded(metric, chests.title)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              )}
              {thighs && (
                <Chart
                  key={thighs.title}
                  data={thighs.values}
                  metric={thighs.title}
                  title="Thighs"
                  primary_color={getRandomColor()}
                  handleMetricAdded={
                    (metric: Metric) => metricAdded(metric, thighs.title)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              )}
              {hips && (
                <Chart
                  key={hips.title}
                  data={hips.values}
                  metric={hips.title}
                  title="Hip"
                  primary_color={getRandomColor()}
                  handleMetricAdded={
                    (metric: Metric) => metricAdded(metric, hips.title)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              )}
              {biceps && (
                <Chart
                  key={biceps.title}
                  data={biceps.values}
                  metric={biceps.title}
                  title="Biceps"
                  primary_color={getRandomColor()}
                  handleMetricAdded={
                    (metric: Metric) => metricAdded(metric, biceps.title)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              )}
              {metrics_op1s && (
                <Chart
                  key={metrics_op1s.metrics.title}
                  data={metrics_op1s.metrics.values}
                  metric="op1s"
                  title={metrics_op1s.title}
                  isOptionalMetric
                  primary_color={getRandomColor()}
                  handleMetricAdded={
                    (metric: Metric) => metricAdded(metric, 'ops1')
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              )}
              {metrics_op2s && (
                <Chart
                  key={metrics_op2s.metrics.title}
                  data={metrics_op2s.metrics.values}
                  metric="op2s"
                  title={metrics_op2s.title}
                  isOptionalMetric
                  primary_color={getRandomColor()}
                  handleMetricAdded={
                    (metric: Metric) => metricAdded(metric, 'ops2')
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              )}
            </MetricsContent>
          </Metrics>
        </Content>
      )}
    </Container>
  );
};

export default Goals;
