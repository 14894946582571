import React, { useState, useCallback, useRef, ChangeEvent } from 'react';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import {
  Container,
  CreateNew,
  Header,
  Content,
  Files,
  CloseButton,
  SubmitButton,
} from './styles';
import CaseNote from '../../../../entities/CaseNote';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../services/api';
import getTypeFromExtension from '../../../../utils/getTypeFromExtension';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Modal from '../../../../components/Modal';
import Input from '../../../../components/Input';
import FileInput from '../../../../components/FileInput';

interface CreateCaseNoteFormData {
  client_id: string;
  title: string;
  type: string;
  document: string;
}

interface DocumentUploadProps {
  name: string;
  folder: string;
  type: string;
}

interface CreateModalProps {
  onCreate: (case_note: CaseNote) => void;
  client_id: string;
}

const CreateModal: React.FC<CreateModalProps> = ({ onCreate, client_id }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentData, setDocumentData] = useState<FormData | null>(null);
  const [documentTitle, setDocumentTitle] = useState('Case Note');

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDocumentUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();
        const file = e.target.files[0];
        data.append('file', file);
        setDocumentData(data);
        setDocumentTitle(`Case Note: ${file.name}`);
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    async (data: CreateCaseNoteFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().min(3, 'Title must be longer than 3 characters'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const resource = {
          title: data.title,
          client_id,
        };

        // UPLOAD CONTENT
        let documentName = '';
        if (documentData) {
          const response = await api.post<DocumentUploadProps>(
            '/documents/case-notes',
            documentData,
          );
          documentName = response.data.name;
          const ext = documentName.substr(documentName.lastIndexOf('.') + 1);
          Object.assign(resource, {
            document: documentName,
            type: getTypeFromExtension(ext),
          });
        }

        // CREATE CONTENT
        const response = await api.post<CaseNote>('/case-notes', resource);

        addToast({
          type: 'success',
          title: 'Creation Success',
        });

        onCreate(response.data);
        handleToggle();
        setIsLoading(false);
        setDocumentData(null);
        setDocumentTitle('Case Note');
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, client_id, documentData, handleToggle, onCreate],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiPlusCircle size={30} color="#ffffff" />
      </CreateNew>
      <Modal isOpen={isOpen} handleToggle={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Create Case Note</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <div>
              <Input name="title" placeholder="Title" />
              <Files>
                <FileInput
                  id="document"
                  name="document"
                  placeholder={documentTitle}
                  onChange={(e) => handleDocumentUpload(e)}
                />
              </Files>
            </div>

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Uploading...' : 'Create'}
            </SubmitButton>
          </Form>
        </Content>
      </Modal>
    </Container>
  );
};

export default CreateModal;
