import React, { useCallback, useEffect } from 'react';

import { Container, Main, Wrapper, MainWrapper } from './styles';
import Header from '../../../components/Header';
import SideMenu from '../../../components/SideMenu';
import BottomMenu from '../../../components/BottomMenu';
import { useChat } from '../../../hooks/Chat';
import { useAuth } from '../../../hooks/Auth';

interface AccountCheckResponse {
  client_id: string;
}

const ClientHomeLayout: React.FC = ({ children }) => {
  const { socket, closeSocket } = useChat();
  const { client, signOut } = useAuth();

  const checkAccount = useCallback(async () => {
    socket?.on('force-logout', (accountCheck: AccountCheckResponse) => {
      if (accountCheck.client_id === client.id) {
        closeSocket();
        signOut();
      }
    });
  }, [socket, client.id, signOut, closeSocket]);

  useEffect(() => {
    checkAccount();
    return () => {
      socket?.off('force-logout');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <Wrapper>
        <Header />
        <Main>
          <SideMenu />
          <MainWrapper>{children}</MainWrapper>
        </Main>
        <BottomMenu />
      </Wrapper>
    </Container>
  );
};

export default ClientHomeLayout;
