import styled from 'styled-components';
import NavigationHeader from '../../../components/NavigationHeader';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NavigationBar = styled(NavigationHeader)`
  padding-top: 20px;
  padding-left: 30px;
`;
