import React, { useRef, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMail } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import {
  Container,
  LoginCard,
  AnimationContainer,
  Content,
  Background,
} from './styles';

import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email is required')
            .email('Invalid email'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/passwords/forgot', {
          email: data.email.toLowerCase(),
        });

        addToast({
          type: 'success',
          title: 'Password Reset',
          description: 'Check your email to reset your password',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Password Reset failed',
          description: 'Check details and try again.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );
  return (
    <Container>
      <LoginCard>
        <Background />
        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Forgot Password</h1>
              <Input name="email" icon={FiMail} placeholder="E-mail" />

              <Button type="submit" loading={loading}>
                Reset Password
              </Button>

              <Link to="/">
                Remember you password? <strong>Click here for Sign in.</strong>
              </Link>
            </Form>
          </AnimationContainer>
        </Content>
      </LoginCard>
    </Container>
  );
};

export default ForgotPassword;
