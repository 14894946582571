import React, { useState, useCallback } from 'react';

import {
  Container,
  Goals,
  Recipe,
  Checkin,
  Chat,
  SharedResources,
  CustomLink,
} from './styles';
import {
  goalsIcon,
  white_goalsIcon,
  recipe,
  white_recipe,
  white_chat,
  chat,
  sharedResources,
  white_sharedResources,
  white_checkIn,
  checkIn,
} from '../../styles/icons';

const BottomMenu: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleMenuSelectedAt = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);
  return (
    <Container>
      <CustomLink
        $isSelected={selectedIndex === 1}
        to="/client-goals"
        onClick={() => handleMenuSelectedAt(1)}
      >
        <Goals>
          <img
            src={selectedIndex === 1 ? white_goalsIcon : goalsIcon}
            alt="your goals"
          />
        </Goals>
      </CustomLink>

      <CustomLink
        $isSelected={selectedIndex === 2}
        to="/client-nutrition"
        onClick={() => handleMenuSelectedAt(2)}
      >
        <Recipe>
          <img
            src={selectedIndex === 2 ? white_recipe : recipe}
            alt="your recipes"
          />
        </Recipe>
      </CustomLink>
      <CustomLink
        $isSelected={selectedIndex === 3}
        to="/checkin"
        onClick={() => handleMenuSelectedAt(3)}
      >
        <Checkin>
          <img
            src={selectedIndex === 3 ? white_checkIn : checkIn}
            alt="client chat"
          />
        </Checkin>
      </CustomLink>
      <CustomLink
        $isSelected={selectedIndex === 4}
        to="/client-chat"
        onClick={() => handleMenuSelectedAt(4)}
      >
        <Chat>
          <img src={selectedIndex === 4 ? white_chat : chat} alt="your chat" />
        </Chat>
      </CustomLink>
      <CustomLink
        $isSelected={selectedIndex === 5}
        to="/client-resources-all"
        onClick={() => handleMenuSelectedAt(5)}
      >
        <SharedResources>
          <img
            src={selectedIndex === 5 ? white_sharedResources : sharedResources}
            alt="Shared Resources"
          />
        </SharedResources>
      </CustomLink>
    </Container>
  );
};

export default BottomMenu;
