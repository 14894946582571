import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background: var(--list-background);
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: var(--gray);
  background: var(--light-background);
  padding-right: 15px;
  margin-bottom: 10px;

  h3 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: var(--primary);
    color: var(--white);
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
  }

  a,
  a:visited,
  a:hover,
  a:active {
    color: var(--white);
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0px 5px 0px 5px;
`;
