import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  border-bottom: 1px solid var(--light-background);
  width: 100%;
`;

export const ClientInfo = styled.div`
  padding: 0 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 15px;
    object-fit: cover;
  }
  strong {
    font-size: 14px;
  }
`;
