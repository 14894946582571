import Modal from 'styled-react-modal';

export const StyledModal = Modal.styled`
  width: 90%;
  max-width: 800px;
  height: 80%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 15px;

  overflow-y: auto;

  @media(min-width: 760px){
    height: 60%;
  }
  @media(max-height: 800px){
    height: 98vh;
  }
`;
