import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import User from '../entities/User';
import Client from '../entities/Client';

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthState {
  token: string;
  user: User;
  client: Client;
  type: string;
}

interface AuthContextState {
  user: User;
  client: Client;
  type: string;
  signIn(credentials: SignInCredentials): Promise<AuthState>;
  signOut(): void;
  updateUser(user: User): void;
  updateClient(client: Client): void;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@FFP:token');
    let user = localStorage.getItem('@FFP:user');
    let type = 'user';
    if (!user) {
      user = localStorage.getItem('@FFP:client');
      type = 'client';
    }
    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
        client: JSON.parse(user),
        type,
      };
    }
    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user, client } = response.data;
    localStorage.setItem('@FFP:token', token);
    let type = 'user';
    if (user) {
      localStorage.setItem('@FFP:user', JSON.stringify(user));
    } else if (client) {
      type = 'client';
      localStorage.setItem('@FFP:client', JSON.stringify(client));
    }
    const userData = user || client;
    api.defaults.headers.authorization = `Bearer ${token}`;
    setData({ token, user: userData, client: userData, type });
    return { token, user: userData, client: userData, type };
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@FFP:token');
    localStorage.removeItem('@FFP:user');
    localStorage.removeItem('@FFP:client');
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setData({
        token: data.token,
        user,
        client: data.client,
        type: 'user',
      });
      localStorage.setItem('@FFP:user', JSON.stringify(user));
    },
    [data.token, data.client],
  );

  const updateClient = useCallback(
    (client: Client) => {
      setData({
        token: data.token,
        user: data.user,
        client,
        type: 'client',
      });
      localStorage.setItem('@FFP:client', JSON.stringify(client));
    },
    [data.token, data.user],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        client: data.client,
        type: data.type,
        signIn,
        signOut,
        updateUser,
        updateClient,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextState {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
