import React from 'react';
import {
  Route as ReactRoute,
  RouteProps as ReactRouteProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/Auth';

import ClientHomeLayout from '../pages/_layouts/client';
import AuthLayout from '../pages/_layouts/auth';
import DashboardLayout from '../pages/_layouts/dashboard';

interface RouteProps extends ReactRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  requiresAdmin?: boolean;
}

// isPrivate / isAuthenticate / isAdmin
// true / true = OK
// true / false = redirect to login
// false / true = redirect to dashboard
// false / false = OK

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  requiresAdmin = false,
  // path,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  function getPathName(): string {
    if (user) {
      return user.role === 'admin' || user.role === 'staff'
        ? '/dashboard'
        : '/home';
    }
    return '/';
  }

  if (!user && (isPrivate || requiresAdmin)) {
    return <Redirect to="/" />;
  }

  if (requiresAdmin && user.role !== 'admin' && user.role !== 'staff') {
    return <Redirect to="/" />;
  }

  if (user && !isPrivate && !requiresAdmin) {
    return <Redirect to={getPathName()} />;
  }

  const Layout = user
    ? user.role
      ? DashboardLayout
      : ClientHomeLayout
    : AuthLayout;

  return (
    <ReactRoute
      {...rest}
      render={() => {
        return (
          <Layout>
            <Component />
          </Layout>
        );
      }}
    />
  );
};

export default Route;
