import styled from 'styled-components';
import Button from '../../../components/Button';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
`;

export const Card = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--light-background);
  border-radius: 5px;
  padding: 8px;
  margin-top: 10px;
  margin-right: 10px;

  @media (min-width: 600px) {
    width: 150px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const EditButton = styled(Button)`
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 13px;
  }
`;

export const DeleteButton = styled(Button)`
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  background: var(--error);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 13px;
  }

  &:hover {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(61deg, var(--error), var(--white) 249%);
  }
`;

export const CardContent = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 70px;
  background: var(--light-background);
  img {
    width: 30px;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 12px;
  }

  span {
    font-size: 10px;
    color: var(--primary);
  }
`;
