import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import ChatMessage from '../../entities/ChatMessage';
import { useAuth } from '../../hooks/Auth';
import { useChat } from '../../hooks/Chat';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import ChatInput from './ChatInput';
import Message from './Message';

import { Container, MessagesBody } from './styles';

interface ChatBodyProps {
  className?: string;
  chat_room: string;
}

interface ChatMessageResponse {
  current: number;
  messages: ChatMessage[];
  total_pages: number;
}

const ChatBody: React.FC<ChatBodyProps> = ({ className, chat_room }) => {
  const { socket } = useChat();
  const { user, client, type } = useAuth();
  const { addToast } = useToast();
  const body = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isChatConnected, setIsChatConnected] = useState<boolean>(true);
  const [shouldNotifyUser, setShouldNotifyUser] = useState<boolean>(true);

  const loggedInUser = useMemo(() => {
    return type === 'client' ? client : user;
  }, [client, user, type]);

  const connectToRoom = useCallback(() => {
    socket?.on(chat_room, (message: ChatMessage) => {
      setMessages([...messages, message]);
    });

    socket?.on('connect_error', () => {
      if (shouldNotifyUser) {
        addToast({
          type: 'error',
          title: 'Could not connect to server',
          description: 'Check your network and try again.',
        });
        setShouldNotifyUser(false);
      }
      setIsChatConnected(false);
    });

    socket?.on('connect', () => {
      setIsChatConnected(true);
      setShouldNotifyUser(true);
    });
  }, [socket, chat_room, messages, addToast, shouldNotifyUser]);

  const fetchPreviousMessages = useCallback(async () => {
    const response = await api.get<ChatMessageResponse>(
      `chat-messages/${chat_room}`,
    );
    setMessages(response.data.messages);
  }, [chat_room]);

  const handleSendMessage = useCallback(
    (message: string) => {
      socket?.emit(chat_room, {
        message,
        sender_id: loggedInUser.id,
        sender_name: loggedInUser.name,
        room_id: chat_room,
        sent_at: new Date(),
      });
    },
    [chat_room, socket, loggedInUser],
  );

  useEffect(() => {
    if (body.current) {
      if (body.current?.scrollHeight > body.current?.offsetHeight) {
        body.current.scrollTo({
          top: body.current.scrollHeight - body.current.offsetHeight,
        });
      }
    }
  }, [messages]);

  useEffect(() => {
    fetchPreviousMessages();
  }, [fetchPreviousMessages]);

  useEffect(() => {
    connectToRoom();
    return () => {
      socket?.off(chat_room);
      socket?.off('connect');
      socket?.off('connect_error');
    };
  }, [connectToRoom, socket, chat_room]);

  return (
    <Container className={className}>
      <MessagesBody ref={body}>
        {messages.length > 0 &&
          messages.map((msg) => <Message key={msg.id} message={msg} />)}
      </MessagesBody>
      <ChatInput
        onSendMessage={handleSendMessage}
        isConnected={isChatConnected}
      />
    </Container>
  );
};

export default ChatBody;
