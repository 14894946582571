import { format } from 'date-fns';
import ChartEntity from '../entities/ChartEntity';
import Metric from '../entities/Metric';

export default function getChartDataFromMetrics(
  metrics: Metric[],
  name: string,
): {
  values: ChartEntity[];
  title: string;
} {
  return {
    values: metrics
      .map((metric) => {
        const date = new Date(metric.date);
        return {
          date,
          date_formatted: format(date, 'dd MMM'),
          type: metric.type,
          value: metric.value,
          title: metric.title,
          name,
        } as ChartEntity;
      })
      .sort((a, b) => (a.date < b.date ? -1 : 1)),
    title: name,
  };
}
