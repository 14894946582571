import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Input from '../../../components/Input';
import NavigationHeader from '../../../components/NavigationHeader';

import {
  Container,
  Content,
  SubmitButton,
  Avatar,
  Info,
  PersonalInformation,
  CustomSeparator,
  PasswordManager,
  ActionButton,
} from './styles';
import { useToast } from '../../../hooks/Toast';
import { useAuth } from '../../../hooks/Auth';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import CameraInput from '../../../components/CameraInput';
import Client from '../../../entities/Client';

interface UpdateClientData {
  name: string;
  email: string;
  old_password?: string;
  password?: string;
  password_confirmation?: string;
}

const Profile: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { client, updateClient } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const handleDocumentUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();
        const file = e.target.files[0];
        data.append('avatar', file);

        try {
          const response = await api.patch<Client>('/clients/avatar', data);
          updateClient(response.data);
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Update Failed',
            description: error.response
              ? error.response.data.message
              : 'Could not connect to server, please try again later',
          });
        }
      }
    },
    [addToast, updateClient],
  );

  const handleSubmit = useCallback(
    async (data: UpdateClientData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().min(3, 'Name must be longer than 3 characters'),
          email: Yup.string().email(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          name: data.name,
          email: data.email,
        };

        if (data.password && data.password !== '') {
          Object.assign(body, {
            old_password: data.old_password,
            password: data.password,
            password_confirmation: data.password_confirmation,
          });
        }
        // CREATE CONTENT
        const response = await api.put<Client>('/clients-profile', body);
        updateClient(response.data);

        addToast({
          type: 'success',
          title: 'Update Success',
          description: `Your information was updated`,
        });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Update Failed',
          description: error.response
            ? error.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, updateClient],
  );

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <span>Profile</span>
        </strong>
      </NavigationHeader>
      <Content>
        <Form id="update-user-form" ref={formRef} onSubmit={handleSubmit}>
          <Avatar>
            <img src={client.avatar_url} alt={client.name} />
            <div className="camera">
              <CameraInput
                name="avatar"
                onChange={(e) => handleDocumentUpload(e)}
              />
            </div>
          </Avatar>
          <span>Upload image</span>
          <Info>
            <PersonalInformation>
              <Input
                title="Name"
                name="name"
                placeholder="Enter text"
                defaultValue={client.name}
              />
              <Input
                title="Email"
                name="email"
                placeholder="Enter text"
                defaultValue={client.email}
              />
            </PersonalInformation>

            <CustomSeparator />
            <PasswordManager>
              <Input
                title="Old Password"
                name="old_password"
                placeholder="Enter text"
                type="password"
              />
              <Input
                title="New Password"
                name="password"
                placeholder="Enter text"
                type="password"
              />
              <Input
                title="Confirm Password"
                name="password_confirmation"
                placeholder="Enter text"
                type="password"
              />
            </PasswordManager>
          </Info>
          <ActionButton>
            <SubmitButton
              type="submit"
              disabled={isLoading}
              form="update-user-form"
            >
              {isLoading ? 'Updating...' : 'Save'}
            </SubmitButton>
          </ActionButton>
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
