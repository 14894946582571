import React, { useState, useCallback, useRef } from 'react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import {
  Container,
  CreateNew,
  Header,
  Content,
  CloseButton,
  SubmitButton,
} from './styles';
import DailyPlan from '../../../entities/DailyPlan';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import Modal from '../../Modal';
import Input from '../../Input';
import TextArea from '../../TextArea';

interface EditDailyPlannerFormData {
  meal: string;
  timing: string;
  protein?: string;
  fats?: string;
  calories?: string;
  carbs?: string;
  explanation: string;
}

interface EditModalProps {
  daily_plan: DailyPlan;
  onEdit: (daily_pan: DailyPlan) => void;
}

const EditDailyPlan: React.FC<EditModalProps> = ({ daily_plan, onEdit }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: EditDailyPlannerFormData) => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          meal: Yup.string().required('Meal is Required'),
          timing: Yup.string().required('Timing is Required'),
          protein: Yup.string(),
          fats: Yup.string(),
          carbs: Yup.string(),
          calories: Yup.string(),
          explanation: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          meal: data.meal,
          timing: data.timing,
          protein: data.protein,
          fats: data.fats,
          carbs: data.carbs,
          calories: data.calories,
          explanation: data.explanation,
        };

        // CREATE CONTENT
        const response = await api.put<DailyPlan>(
          `/daily-plans/${daily_plan.id}`,
          body,
        );

        addToast({
          type: 'success',
          title: 'Creation Success',
        });
        onEdit(response.data);
        handleToggle();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          setIsLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Creation Failed',
          description: err.response
            ? err.response.data.message
            : 'Could not connect to server, please try again later',
        });
      }
    },
    [addToast, handleToggle, onEdit, daily_plan.id],
  );

  return (
    <Container>
      <CreateNew onClick={handleToggle}>
        <FiEdit size={14} color="#ffffff" />
      </CreateNew>
      <Modal isOpen={isOpen} handleToggle={handleToggle}>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Header>
              <strong>Edit Daily Meal Plan</strong>
              <CloseButton onClick={handleToggle}>
                <FiXCircle size={30} color="#4fc5c9" />
              </CloseButton>
            </Header>
            <Input
              title="Meal"
              name="meal"
              placeholder="Meal"
              defaultValue={daily_plan.meal}
            />
            <Input
              title="Timing"
              name="timing"
              placeholder="Timing"
              defaultValue={daily_plan.timing}
            />
            <div className="row">
              <Input
                title="Protein"
                name="protein"
                placeholder="Protein (g)"
                defaultValue={daily_plan.protein}
              />
              <Input
                title="Fats"
                name="fats"
                placeholder="Fats (g)"
                defaultValue={daily_plan.fats}
              />
            </div>
            <div className="row">
              <Input
                title="Carbs"
                name="carbs"
                placeholder="Carbs (g)"
                defaultValue={daily_plan.carbs}
              />
              <Input
                title="Calories"
                name="calories"
                placeholder="Calories (kcal)"
                defaultValue={daily_plan.calories}
              />
            </div>
            <strong>Explanation</strong>
            <TextArea
              name="explanation"
              placeholder="Explanation"
              defaultValue={daily_plan.explanation}
            />

            <SubmitButton type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </SubmitButton>
          </Form>
        </Content>
      </Modal>
    </Container>
  );
};

export default EditDailyPlan;
