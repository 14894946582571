import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
  font-family: 'SofiaProRegular';
  src: url('../../assets/fonts/SofiaProRegular.ttf');
  font-weight: normal;
  font-style: normal;
}

  @font-face {
  font-family: 'SofiaProLight';
  src: url('../../assets/fonts/SofiaProLight.otf');
  font-weight: light;
  font-style: light;
}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html, body #root {
    max-width: 100vw;
    max-height: 100vh;
  }

  body {
    background: var(--background);
    color: var(--light-gray);
    -webkit-font-smoothing: antialiased;
  }

  *, input, button, a {
    border: 0;
    background: none;
    font-family:  SofiaProRegular;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
    text-decoration: none;
  }

  :root {
    --primary: #4fc5c9;
    --dark-primary: #2c9ca0;
    --primary-background: #a8dadc;
    --white: #ffffff;
    --gray: #717171;
    --light-gray: #707070;
    --super-light-gray: #bcbcbc;
    --background: #effdfd;
    --error: #ff778e;
    --light-background: #F5F5F5;
    --list-background: #fcfcfc;
    --blue: #1d658b;
    --yellow: #ffc46f;
  }
`;
