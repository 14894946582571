import React from 'react';

import { format } from 'date-fns';
import { Container, ProfileInfo, ClientInfo } from './styles';
import Client from '../../../../entities/Client';

interface ProfileCardProps {
  client: Client;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ client }) => {
  return (
    <Container>
      <img src={client.avatar_url} alt="" />
      <ProfileInfo>
        <ClientInfo>
          <strong>{client.name}</strong>
          <span>{client.email}</span>
        </ClientInfo>
        <span>
          End of contract on:{' '}
          <strong>{format(client.end_of_contract, 'dd/MM/yyyy')}</strong>
        </span>
      </ProfileInfo>
    </Container>
  );
};

export default ProfileCard;
