import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import NavigationHeader from '../../../components/NavigationHeader';
import MealPlan from '../../../entities/MealPlan';
import Recipe from '../../../entities/Recipe';
import { useToast } from '../../../hooks/Toast';
import api from '../../../services/api';
import MealPlanList from './MealPlanList';
import RecipeList from './RecipeList';

import { Container, Content, Header, Body } from './styles';

const RecipesMealPlan: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [mealPlans, setMealPlans] = useState<MealPlan[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMealPlan = useCallback(async () => {
    try {
      const response = await api.get(`client-meal-planners`);
      setMealPlans(response.data.meal_planers);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not fetch Meal plans',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast]);

  const fetchRecipes = useCallback(async () => {
    try {
      const response = await api.get(`client-recipes`);
      setRecipes(response.data.recipes);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Could not fetch Recipes',
        description: error.response
          ? error.response.data.message
          : 'Could not connect to server, please try again later',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    fetchMealPlan();
    fetchRecipes();
  }, [fetchMealPlan, fetchRecipes]);

  return (
    <Container>
      <NavigationHeader>
        <strong>
          <button type="button" onClick={() => history.push('/')}>
            Dashboard /
          </button>
          <button
            type="button"
            onClick={() => history.push('/client-nutrition')}
          >
            Nutrition & Recipes /
          </button>
          <span>Recipes & Meal Plans</span>
        </strong>
      </NavigationHeader>
      {isLoading ? (
        <Loading />
      ) : (
        <Content>
          <Header>
            <h3>Resources</h3>
          </Header>
          <Body>
            {mealPlans.length > 0 && <MealPlanList meal_plans={mealPlans} />}{' '}
            {recipes.length > 0 && <RecipeList recipes={recipes} />}
          </Body>
        </Content>
      )}
    </Container>
  );
};

export default RecipesMealPlan;
