import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;

export const Content = styled.div``;

const commonBoxCss = css`
  border: 2px solid var(--light-background);
  border-radius: 5px;
  padding: 10px 16px;
  margin-bottom: 10px;
  h3 {
    font-size: 12px;
  }
`;
export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${commonBoxCss}

  @media(min-width: 700px) {
    flex-direction: row;
  }
`;

export const Groups = styled.div`
  margin-top: 15px;
  flex: 1;
  ${commonBoxCss}

  @media(min-width: 700px) {
    margin-top: 0;
    margin-left: 15px;
  }
`;
export const Settings = styled.div`
  ${commonBoxCss}
`;
export const MetricsArea = styled.div`
  ${commonBoxCss}
`;
export const Goals = styled.div`
  ${commonBoxCss}
`;
export const KeyPrinciples = styled.div`
  ${commonBoxCss}
`;
export const Recipes = styled.div`
  ${commonBoxCss}
`;
export const MealPlans = styled.div`
  ${commonBoxCss}
`;
export const NutritionArea = styled.div`
  ${commonBoxCss}
`;
