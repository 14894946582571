import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 20px;
  background: var(--list-background);
  border-radius: 15px;
  margin-top: 10px;
  margin-right: 10px;
  width: 100%;
  max-width: 300px;

  @media (min-width: 400px) {
    max-width: 350px;
  }

  @media (min-width: 650px) {
    max-width: 300px;
  }

  @media (min-width: 1300px) {
    max-width: 370px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  * {
    font-size: 12px;
  }
  margin-top: 10px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    font-weight: bold;
    color: var(--gray);
  }

  strong {
    font-size: 26px;
    font-weight: bold;
    color: var(--blue);
  }
`;
