import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
  min-width: 310px;
  flex-direction: column;
`;

export const Content = styled.div``;

export const GoalsList = styled.div`
  strong {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  margin-bottom: 15px;
`;

export const Metrics = styled.div`
  margin-top: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    strong {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    a {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      background: var(--primary);
      padding: 5px 8px;
      color: var(--white);
      border-radius: 5px;
      font-size: 12px;
    }
  }
`;

export const MetricsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
`;
