import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, CheckboxItem, Error } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
}

const CheckboxInput: React.FC<Props> = ({
  name,
  options,
  className,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [], error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value);
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <Container className={className}>
      {options.map((option, index) => (
        <CheckboxItem key={option.id} isErrored={!!error}>
          <label htmlFor={option.id}>
            <strong>{option.label}</strong>
            <input
              defaultChecked={defaultValue.find(
                (dv: string) => dv === option.id,
              )}
              ref={(ref) => {
                inputRefs.current[index] = ref as HTMLInputElement;
              }}
              value={option.value}
              type="checkbox"
              id={option.id}
              {...rest}
            />
          </label>
        </CheckboxItem>
      ))}
      {error && (
        <Error title={error}>
          <FiAlertCircle size={20} color="#FF778E" />
        </Error>
      )}
    </Container>
  );
};

export default CheckboxInput;
