import React from 'react';
import CreateDailyPlanner from '../../../../components/DailyPlanner/CreateDailyPlanner';
import DeleteConfirmation from '../../../../components/DailyPlanner/DeleteConfirmation';
import EditDailyPlan from '../../../../components/DailyPlanner/EditDailyPlan';

import DailyPlan from '../../../../entities/DailyPlan';

import {
  Container,
  Header,
  Plan,
  ActionButtons,
  Meal,
  Timing,
  FoodData,
  Card,
  CardHeader,
  CardContent,
  Explanation,
} from './styles';

interface DailyMealComponentProps {
  daily_meal_planner: DailyPlan[];
  nutrition_id: string;
  handleOnCreate: (daily_plan: DailyPlan) => void;
  handleOnEdit: (daily_plan: DailyPlan) => void;
  handleDelete: (id: string) => void;
}

const DailyMealComponent: React.FC<DailyMealComponentProps> = ({
  daily_meal_planner,
  nutrition_id,
  handleOnCreate,
  handleOnEdit,
  handleDelete,
}) => {
  return (
    <Container>
      <Header>
        <strong>Daily Meal Planner</strong>
        <CreateDailyPlanner
          onCreate={handleOnCreate}
          nutrition_id={nutrition_id}
        />
      </Header>

      {daily_meal_planner.map((plan) => (
        <Plan key={plan.id}>
          <div className="first_row">
            <Meal>
              <span>Meal</span>
              <strong>{plan.meal}</strong>
            </Meal>
            <ActionButtons>
              <EditDailyPlan daily_plan={plan} onEdit={handleOnEdit} />
              <DeleteConfirmation
                id={plan.id}
                title={plan.meal}
                onDelete={handleDelete}
              />
            </ActionButtons>
          </div>

          <Timing>
            <span>Timing</span>
            <strong>{plan.timing}</strong>
          </Timing>
          <FoodData>
            {plan.protein && (
              <Card>
                <CardHeader>
                  <span>Protein</span>
                </CardHeader>
                <CardContent>
                  <strong>{plan.protein}g</strong>
                </CardContent>
              </Card>
            )}
            {plan.fats && (
              <Card>
                <CardHeader>
                  <span>Fats</span>
                </CardHeader>
                <CardContent>
                  <strong>{plan.fats}g</strong>
                </CardContent>
              </Card>
            )}
            {plan.carbs && (
              <Card>
                <CardHeader>
                  <span>Carbs</span>
                </CardHeader>
                <CardContent>
                  <strong>{plan.carbs}g</strong>
                </CardContent>
              </Card>
            )}
            {plan.calories && (
              <Card>
                <CardHeader>
                  <span>Calories</span>
                </CardHeader>
                <CardContent>
                  <strong>{plan.calories}kcal</strong>
                </CardContent>
              </Card>
            )}
          </FoodData>
          <Explanation>
            <span>Explanation</span>
            <strong>{plan.explanation}</strong>
          </Explanation>
        </Plan>
      ))}
    </Container>
  );
};

export default DailyMealComponent;
