import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px 30px;
`;

export const Content = styled.div`
  margin-top: 10px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 20px;

  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Active = styled.div``;
export const InActive = styled.div`
  margin-top: 20px;
`;
