import React, { useState, useCallback } from 'react';
import {
  Container,
  StyledModal,
  Delete,
  Content,
  Wrap,
  Info,
  Actions,
  DeleteButton,
  Cancel,
} from './styles';

interface DeleteConfirmationProps {
  id: string;
  title: string;
  thumbnail_url: string;
  group: string;
  owner: string;
  handleDelete: (id: string) => void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  id,
  title,
  thumbnail_url,
  owner,
  handleDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const testDelete = useCallback(() => {
    handleDelete(id);
    handleToggle();
  }, [id, handleToggle, handleDelete]);
  return (
    <Container>
      <Delete onClick={handleToggle}>
        <strong>Reject</strong>
      </Delete>
      <StyledModal isOpen={isOpen} onEscapeKeydown={handleToggle}>
        <Content>
          <img src={thumbnail_url} alt="" />
          <Wrap>
            <Info>
              <strong>{title}</strong>
              <span>Owner: {owner}</span>
            </Info>
            <Actions>
              <Cancel onClick={handleToggle}>Cancel</Cancel>
              <DeleteButton onClick={testDelete}>Delete</DeleteButton>
            </Actions>
          </Wrap>
        </Content>
      </StyledModal>
    </Container>
  );
};

export default DeleteConfirmation;
