import styled, { css } from 'styled-components';

interface ContainerProps {
  $isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 8px;
  background: var(--light-background);
  justify-content: space-between;
  ${(props) =>
    !props.$isActive &&
    css`
      background: var(--error);

      strong {
        color: var(--white);
      }
      span {
        color: var(--white);
      }
    `}

  a {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

export const Avatar = styled.div`
  img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    object-fit: cover;
  }

  margin-right: 10px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  strong {
    font-size: 12px;
    font-weight: bold;
    color: var(--blue);
  }

  span {
    font-size: 10px;
  }
  margin-right: 10px;
`;

export const Icons = styled.div<ContainerProps>`
  float: right;
  ${(props) =>
    !props.$isActive &&
    css`
      svg {
        color: var(--white);
      }
    `}
`;

export const Separator = styled.div`
  border-top: 1px solid var(--primary-background);
  width: 100%;
  margin-top: 8px;
`;

export const Owner = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 10px;

    strong {
      color: var(--gray);
    }
  }
`;
